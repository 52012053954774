import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Typography } from '@mui/material';
import { Row, SpacedRow } from 'components';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';

interface Props {
	hidden: boolean;
	setHidden: (hidden: boolean) => void;
	closed: boolean;
	setClosed: (closed: boolean) => void;
}

export default ({ hidden, setHidden, setClosed }: Props) => {
	const dispatch = useDispatch();

	const handleHide = () => {
		setHidden(!hidden);
	};

	const handleClose = () => {
		setClosed(true);
		setTimeout(() => dispatch({ type: 'CLEAR_FILE_REQUESTS' }), 150);
	};

	return (
		<SpacedRow
			style={{
				height: 48,
				backgroundColor: '#0000000A',
				padding: '0px 20px',
				borderRadius: '6px 6px 0px 0px',
			}}>
			<Typography variant="subtitle1">Tasks</Typography>
			<Row>
				<IconButton
					icon={hidden ? regular('chevron-up') : regular('chevron-down')}
					tooltip="Hide"
					onClick={handleHide}
				/>
				<IconButton
					icon={regular('times')}
					tooltip="Close"
					onClick={handleClose}
				/>
			</Row>
		</SpacedRow>
	);
};

import {
	Box,
	DialogTitle,
	Step,
	StepLabel,
	Stepper,
	Typography,
} from '@mui/material';
import { useContext } from 'react';

import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Row } from 'components';
import IconButton from 'components/icon-button';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { FlatFileImportContext } from '../../context';

interface Props {
	onClose: () => void;
}

export default ({ onClose }: Props) => {
	const dispatch = useDispatch();
	const context = useContext(FlatFileImportContext);

	const { step, operation, pms } = context;

	const handleClose = () => {
		if (step === 0 || step === 3) {
			onClose();
			return;
		}

		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('exclamation-triangle'),
				title: 'Cancel Import',
				body1: [
					'Are you sure you want to cancel this import? All progress will be lost and cannot be recovered.',
					"If you're experiencing issues with the import process, please visit our help center for more information or contact our customer support team for assistance.",
				],

				buttons: [
					{
						title: 'Go Back',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Cancel Import',
						onClick: () => {
							onClose();
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
				],
			})
		);
	};

	return (
		<DialogTitle sx={{ backgroundColor: step === 0 ? '#F6F5FF' : undefined }}>
			<Row style={{ justifyContent: 'flex-end' }}>
				<IconButton icon={regular('times')} onClick={handleClose} />
			</Row>
			<Box
				sx={{
					paddingTop: 2,
					paddingBottom: 3,
					paddingLeft: { md: 16, sm: 8, xs: 0 },
					paddingRight: { md: 16, sm: 8, xs: 0 },
				}}>
				<Stepper activeStep={step}>
					{['Select Type', 'Import Data', 'Upload', 'Complete'].map(
						(label, index) => (
							<Step key={index}>
								<StepLabel>
									<Typography>{label}</Typography>
								</StepLabel>
							</Step>
						)
					)}
				</Stepper>
			</Box>
		</DialogTitle>
	);
};

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import { useContext } from 'react';
import { FlatFileImportContext } from '../../context';
import { useIntercom } from 'react-use-intercom';

interface Props {
	onClose: () => void;
}

export default ({ onClose }: Props) => {
	const context = useContext(FlatFileImportContext);
	const { showNewMessage } = useIntercom();

	const handleGetHelp = () => {
		showNewMessage();
	};

	/**
	 * In the select type step we need an operation and depending on
	 * the operation we also need a PMS. We don't validate the PMS though as it's
	 * forced to a default value in the context.
	 */
	if (context.step === 0) {
		const disabled = !context.operation;
		const tooltip = disabled ? 'Choose an option to continue' : undefined;

		return (
			<Button
				onClick={() => context.setStep(1)}
				disabled={disabled}
				tooltip={tooltip}>
				Next
			</Button>
		);
	}

	if (context.step === 1 || context.step === 2) {
		return (
			<Button
				variant="outlined"
				onClick={handleGetHelp}
				startIcon={<FontAwesomeIcon icon={regular('message-lines')} />}>
				Get Help
			</Button>
		);
	}

	if (context.step === 3) {
		return <Button onClick={onClose}>Done</Button>;
	}

	return <div />;
};

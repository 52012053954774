import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from 'components';
import { useContext } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { FlatFileImportContext } from '../../context';

interface Props {
	setOpen: (open: boolean) => void;
}

export default ({ setOpen }: Props) => {
	const context = useContext(FlatFileImportContext);
	const dispatch = useDispatch<Dispatch>();

	const handleGoBack = () => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				icon: solid('exclamation-triangle'),
				title: 'Discard Changes?',
				body1: [
					"Going back will remove any changes you've made. Are you sure you want to leave?",
				],
				buttons: [
					{
						title: 'Cancel',
						onClick: () => dispatch(ModalFlowActions.closeConfirmationModal()),
					},
					{
						title: 'Discard Changes',
						onClick: () => {
							context.setStep(context.step - 1);
							dispatch(ModalFlowActions.closeConfirmationModal());
						},
					},
				],
			})
		);
	};

	if (context.step === 0) {
		return (
			<Button variant="text" color="secondary" onClick={() => setOpen(false)}>
				Cancel
			</Button>
		);
	}

	if (context.step === 1) {
		return (
			<Button variant="text" color="secondary" onClick={handleGoBack}>
				Back
			</Button>
		);
	}

	return <div />;
};

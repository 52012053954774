"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.inspectionTemplateAddonIds = exports.inspectionTemplateFreeTrialAddonId = exports.inspectionTemplatePerUnitAddonId = exports.zapierAddonIds = exports.RentManager = exports.jenarkWorkOrderAddonIds = exports.Latchel = exports.AppFolio = void 0;
/**
 * These constants are used to identify the addons that are associated with
 * different features, only use these internally and never export directly.
 * For external use refer to the exports below.
 *
 */
const appfolioSyncIds = [
    'appfolio-integration---two-way-data-sync',
    'atlas-appfolio-integration---two-way-data-sync',
];
const appfolioWorkOrderIds = [
    'appfolio-data-sync-plus-workorders',
    'atlas-appfolio-data-sync-plus-workorders',
];
const rentManagerSyncIds = [
    'rm-integration---two-way-data-sync',
    'rentmanager-integration---two-way-data-sync',
];
const rentManagerWorkOrderIds = [
    'rm-integration---work-orders',
    'rentmanager-integration---work-orders',
];
const latchelWorkOrderIds = [
    'latchel-integration',
    'nocharge-latchel-integration',
];
/**
 * AppFolio Addon Ids
 */
exports.AppFolio = {
    All: [...appfolioSyncIds, ...appfolioWorkOrderIds],
    TwoWayDataSync: appfolioSyncIds,
    WorkOrders: appfolioWorkOrderIds,
};
/**
 * Latchel Addon Ids
 */
exports.Latchel = {
    All: [...latchelWorkOrderIds],
    WorkOrders: latchelWorkOrderIds,
};
/**
 * Jenark (365) Addon Ids
 */
exports.jenarkWorkOrderAddonIds = ['jenark-integration'];
/**
 * Rent Manager Addon Ids
 */
exports.RentManager = {
    All: [...rentManagerSyncIds, ...rentManagerWorkOrderIds],
    TwoWayDataSync: rentManagerSyncIds,
    WorkOrders: rentManagerWorkOrderIds,
};
/**
 * Zapier Addon Ids
 */
exports.zapierAddonIds = ['zapier-integration'];
/**
 * Inspection Template Addon Ids
 */
exports.inspectionTemplatePerUnitAddonId = 'inspection-template-customization-2';
exports.inspectionTemplateFreeTrialAddonId = 'inspection-template-customization-free-trial';
exports.inspectionTemplateAddonIds = [
    'inspection-template-customization',
    exports.inspectionTemplateFreeTrialAddonId,
    exports.inspectionTemplatePerUnitAddonId,
];

import { Row } from 'components';
import { useCallback, useEffect } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Typography } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ImportsActions } from 'store/actions';
import { State as ImportTasksState } from 'store/reducers/imports/tasks';
import { Dispatch } from 'types';
import ImportRow from './row';

export default () => {
	const dispatch: Dispatch = useDispatch();

	const imports = useTypedSelector((state) => state.imports.tasks);

	const updateImportsStatus = useCallback(
		(imports: ImportTasksState, dispatch: Dispatch) => {
			const importsInProgress = imports.filter(Utils.Imports.isInProgress);

			Promise.all(
				importsInProgress.map((fr) =>
					dispatch(ImportsActions.refresh(fr.id)).catch(() => {})
				)
			);
		},
		[]
	);

	useEffect(() => {
		const interval = setInterval(() => {
			updateImportsStatus(imports, dispatch);
		}, 5000);

		return () => clearInterval(interval);
	}, [dispatch, updateImportsStatus, imports]);

	if (!imports.length) return null;

	return (
		<>
			<Row style={{ padding: '8px 20px' }}>
				<Typography variant="overline">Imports</Typography>
			</Row>
			<Divider />

			{imports.map((i) => (
				<ImportRow key={i.id} object={i} />
			))}

			<Row style={{ padding: '12px 20px' }}>
				<FontAwesomeIcon icon={regular('info-circle')} color="#54697E" />
				<Typography variant="body2" ml={1.5}>
					In-process and complete imports are available on the{' '}
					<Link to="/account/imports" style={{ textDecoration: 'underline' }}>
						imports page
					</Link>
					.
				</Typography>
			</Row>
		</>
	);
};

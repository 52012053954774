import {
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	FormControlLabel,
	Typography,
} from '@mui/material';
import { Button } from 'components';
import { useContext, useEffect, useState } from 'react';

import {
	ImportModel,
	ImportOperation,
	ImportSource,
	UserSettings,
} from '@rentcheck/types';
import { useUserSettings } from 'hooks/user-settings';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { FlatFileImportContext } from '../../../context';
import { pmsOptions } from './constants';

const getUserSettingsKey = (
	model: ImportModel,
	pms: ImportSource
): keyof UserSettings | '' => {
	const appFolioKeysMap: Record<ImportModel, keyof UserSettings> = {
		unit: 'import_units_integration_appfolio_dismissed',
		resident: 'import_residents_integration_appfolio_dismissed',
	};

	const rentManagerKeysMap: Record<ImportModel, keyof UserSettings> = {
		unit: 'import_units_integration_rentmanager_dismissed',
		resident: 'import_residents_integration_rentmanager_dismissed',
	};

	switch (pms) {
		case 'appfolio':
			return appFolioKeysMap[model];
		case 'rentmanager':
			return rentManagerKeysMap[model];
		default:
			return '';
	}
};

export default () => {
	const context = useContext(FlatFileImportContext);
	const history = useHistory();

	const { userSettings, updateUserSettings } = useUserSettings();

	const [dismissed, setDismissed] = useState(false);
	const [dontShowAgain, setDontShowAgain] = useState(false);

	useEffect(() => {
		setDismissed(false);
		setDontShowAgain(false);
	}, [context.pms]);

	const handleDismiss = () => {
		updateUserSettings({
			[getUserSettingsKey(context.model, context.pms)]: dontShowAgain,
		});
		setDismissed(true);
	};

	const handleExploreIntegrations = () => {
		handleDismiss();
		history.push('/account/integrations');
	};

	const selectedPMSOption = pmsOptions.find((o) => o.value === context.pms);
	const validOperations: ImportOperation[] = ['create', 'create/update'];

	if (!validOperations.includes(context.operation as ImportOperation)) {
		return null;
	}

	if (!selectedPMSOption?.existsAsIntegration) {
		return null;
	}

	const userDismissedForever: boolean = _.get(
		userSettings,
		getUserSettingsKey(context.model, context.pms)
	) as boolean;

	return (
		<Dialog open={!dismissed && !userDismissedForever}>
			<DialogContent>
				<img
					alt=""
					src={selectedPMSOption.existsAsIntegrationImage}
					style={{ width: '100%' }}
				/>
				<Typography variant="h6" textAlign="center" mt={3}>
					Wait! Did you know about our {selectedPMSOption.name} integration?
				</Typography>
				<Typography mt={3}>
					Before you start a manual import, consider connecting RentCheck
					directly to {selectedPMSOption.name}. It's like having a
					super-efficient assistant working 24/7!
				</Typography>
				<Typography mt={2}>Here's what you'll get:</Typography>
				<Typography mt={1}>
					<span style={{ fontWeight: 600 }}>• Automatic Data Sync:</span> Keep
					your property, unit, and resident information updated with a two-way
					data sync every 24 hours.
				</Typography>
				<Typography mt={1}>
					<span style={{ fontWeight: 600 }}>• Instant Inspection Reports:</span>
					Completed inspection reports are uploaded directly to AppFolio.
				</Typography>
				<Typography mt={1}>
					<span style={{ fontWeight: 600 }}>• Quick Work Orders:</span> Create
					{selectedPMSOption.name} work orders straight from RentCheck
					inspections.
				</Typography>
				<Typography mt={3}>
					Save time, reduce errors, and keep everything in sync.
				</Typography>
				<FormControlLabel
					label="Don't show me this message again."
					sx={{ mt: 2 }}
					control={
						<Checkbox
							checked={dontShowAgain}
							onChange={() => setDontShowAgain(!dontShowAgain)}
						/>
					}
				/>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleDismiss}>
					Stick with manual imports
				</Button>
				<Button onClick={handleExploreIntegrations}>
					Explore Integrations
				</Button>
			</DialogActions>
		</Dialog>
	);
};

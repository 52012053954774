import { Grid } from '@mui/material';
import assets from 'assets';
import { Screens, Spacer } from 'components';

import { useState } from 'react';
import List from './list';
import MainCta from './main-cta';

import { useLocation } from 'react-router-dom';
import ResidentsDialog from './flows/dialogs/residents';
import UnitsDialog from './flows/dialogs/units';

export default () => {
	const location = useLocation();

	const [unitsDialogOpen, setUnitsDialogOpen] = useState(
		location.pathname.includes('unit')
	);

	const [residentsDialogOpen, setResidentsDialogOpen] = useState(
		location.pathname.includes('resident')
	);

	return (
		<Screens.Container>
			<Screens.Header
				showBreadCrumbs
				title="Imports"
				subtitle="Easily import and manage data for your units and residents—view past imports and keep your RentCheck data up to date."
			/>
			<Grid container columns={{ xs: 1, sm: 2 }} spacing={2}>
				<MainCta
					title="Unit Import"
					subtitle="Upload a file to add or delete units in RentCheck."
					image={assets.imports.unitImport}
					onClick={() => setUnitsDialogOpen(true)}
				/>
				<MainCta
					title="Resident Import"
					subtitle="Upload a file to add or update resident information in RentCheck."
					image={assets.imports.residentImport}
					onClick={() => setResidentsDialogOpen(true)}
				/>
			</Grid>
			<Spacer height={6} />
			<List />

			<UnitsDialog open={unitsDialogOpen} setOpen={setUnitsDialogOpen} />
			<ResidentsDialog
				open={residentsDialogOpen}
				setOpen={setResidentsDialogOpen}
			/>
		</Screens.Container>
	);
};

import {
	ButtonBase,
	CardContent,
	Chip,
	Grid,
	Card as MuiCard,
	Radio,
	Typography,
} from '@mui/material';
import { SpacedRow } from 'components';
import styled from 'styled-components';
import { colors } from 'theme';

interface Props {
	title: string;
	subtitle?: string;
	image: string;
	selected?: boolean;
	comingSoon?: boolean;

	onClick: () => void;
}

export default ({
	title,
	subtitle,
	image,
	onClick,
	selected,
	comingSoon,
}: Props) => {
	return (
		<Grid item xs={1}>
			<ButtonBase
				sx={{
					height: '100%',
					width: '100%',
					textAlign: 'left',
					opacity: comingSoon ? 0.6 : 1,
				}}
				disabled={comingSoon}
				onClick={onClick}>
				<Card elevation={0} selected={selected}>
					<CardContent
						sx={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							padding: '24px',
						}}>
						<SpacedRow style={{ width: '100%', marginBottom: -24 }}>
							{comingSoon ? (
								<Chip
									label="Coming Soon"
									size="small"
									sx={{ backgroundColor: '#86FFAC', marginTop: -1 }}
								/>
							) : (
								<div />
							)}
							<Radio
								size="small"
								checked={selected}
								style={{
									marginTop: -8,
									marginRight: -8,
								}}
							/>
						</SpacedRow>
						<img src={image} alt={title} style={{ maxWidth: 105 }} />
						<Typography variant="h6" mt={3}>
							{title}
						</Typography>
						{!!subtitle && (
							<Typography mt={1} textAlign="center">
								{subtitle}
							</Typography>
						)}
					</CardContent>
				</Card>
			</ButtonBase>
		</Grid>
	);
};

const Card = styled(MuiCard)<{ selected?: boolean }>`
	border: 1px solid
		${({ selected }) => (selected ? colors.primary : '#0000001f')};
	flex-grow: 1;
	height: 100%;
	transition: background-color 0.2s ease-in-out;

	:hover {
		background-color: #f6f5ff;
	}
`;

import { Redirect, useLocation } from 'react-router-dom';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Screens, Spacer } from 'components';

import CustomizeMaintenanceSkills from './customize-maintenance-skills';
import DeleteUser from './delete-user';
import EnableUser from './enable-user';
import ImpersonateUser from './impersonate';
import InspectionTools from './inspection-tools';
import PropertyTools from './property-tools';
import UpdateBillingPlans from './update-billing-plans';

export default function AdminPanel() {
	const location = useLocation();

	const authenticatedUser = useTypedSelector(
		(state) => state.authenticatedUser
	);

	if (!authenticatedUser) {
		return null;
	}

	if (!location.pathname.startsWith('/account/admin')) {
		return null;
	}

	if (!authenticatedUser.internal_admin) {
		return <Redirect to={'/account/profile'} />;
	}

	return (
		<Screens.Container>
			<Screens.Header title="Admin Panel" />

			<ImpersonateUser />
			<Spacer height={4} />

			<EnableUser />
			<Spacer height={4} />

			<DeleteUser />
			<Spacer height={4} />

			<CustomizeMaintenanceSkills />
			<Spacer height={4} />

			<PropertyTools />
			<Spacer height={4} />

			<InspectionTools />
			<Spacer height={4} />

			<UpdateBillingPlans />
			<Spacer height={4} />
		</Screens.Container>
	);
}

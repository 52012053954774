import crypto from 'crypto';

export const encrypt = (plainText: string, secretKey: string): string => {
	const key = secretKey.repeat(32).substring(0, 32);
	const iv = secretKey.repeat(16).substring(0, 16);

	const cipher = crypto.createCipheriv('aes-256-ctr', key, iv);
	let encrypted = cipher.update(plainText, 'utf8', 'hex');
	encrypted += cipher.final('hex');

	return encrypted;
};

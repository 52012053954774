import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Button } from '@mui/material';
import { Column, Row, Spacer } from 'components';

import { useState } from 'react';
import { Calendar, DayRange } from 'react-modern-calendar-datepicker';
import { useDispatch } from 'react-redux';
import { DateRange, dateRanges } from 'store/reducers/dashboard/filters';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import {
	dayRangeFromDateRange,
	formattedDateRange,
	timestampFromDayValue,
} from 'utils/helpers';

import FilterChip from './common/filter-chip';
import FilterPopover from './common/filter-popover';
import FilterPopoverContent from './common/filter-popover-content';
import Skeleton from './common/skeleton';

const DateFilter = () => {
	const dispatch = useDispatch();

	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);

	const [showingCalendar, setShowingCalendar] = useState(false);

	if (!dashboardData) {
		return <Skeleton />;
	}

	const handleLast3Months = () => {
		dispatch({
			type: 'SET_DASHBOARD_FILTER_DATE_RANGE',
			value: dateRanges.last3Months,
		});
	};

	const handleLast6Months = () => {
		dispatch({
			type: 'SET_DASHBOARD_FILTER_DATE_RANGE',
			value: dateRanges.last6Months,
		});
	};

	const handleCustomDateRange = () => {
		setShowingCalendar(true);
	};

	const handleConfirmCustomDateRange = (value: DateRange) => {
		setShowingCalendar(false);
		dispatch({
			type: 'SET_DASHBOARD_FILTER_DATE_RANGE',
			value,
		});
	};

	const last3Selected = dashboardFilters.dateRange === dateRanges.last3Months;
	const last6Selected = dashboardFilters.dateRange === dateRanges.last6Months;
	const customRangeSelected = !last3Selected && !last6Selected;

	const title = last3Selected
		? 'Last 3 months'
		: last6Selected
			? 'Last 6 months'
			: formattedDateRange(dashboardFilters.dateRange);

	return (
		<FilterPopover
			anchorChild={
				<FilterChip
					title={title}
					icon={light('calendar-week')}
					variant="primary"
					endIcon={solid('chevron-down')}
				/>
			}
			contentChildren={
				<Column>
					{!showingCalendar && (
						<>
							<Spacer height={2} />
							<FilterPopoverContent.SelectableRow
								variant="radio"
								title="Last 3 months"
								onClick={handleLast3Months}
								selected={last3Selected}
							/>
							<FilterPopoverContent.SelectableRow
								variant="radio"
								title="Last 6 months"
								onClick={handleLast6Months}
								selected={last6Selected}
							/>
							<FilterPopoverContent.SelectableRow
								variant="radio"
								title="Custom date range"
								onClick={handleCustomDateRange}
								selected={customRangeSelected}
							/>
						</>
					)}
					{showingCalendar && (
						<CustomDateRange
							onCancel={() => setShowingCalendar(false)}
							onConfirm={handleConfirmCustomDateRange}
						/>
					)}
				</Column>
			}
		/>
	);
};

interface CalendarProps {
	onCancel: () => void;
	onConfirm: (value: DateRange) => void;
}

const CustomDateRange = ({ onCancel, onConfirm }: CalendarProps) => {
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);

	const [value, setValue] = useState(dashboardFilters.dateRange);

	const handleCustomDateRangeChanged = (value: DayRange) => {
		const start = timestampFromDayValue(value.from);
		const end = timestampFromDayValue(value.to);

		setValue({ start, end });
	};

	const handleOk = () => {
		onConfirm(value);
	};

	return (
		<>
			<FilterPopoverContent.Title title="Custom date range" />
			<Calendar
				value={dayRangeFromDateRange(value)}
				onChange={handleCustomDateRangeChanged}
				colorPrimary={colors.primary}
				colorPrimaryLight={colors.primaryLight}
			/>
			<Row style={{ justifyContent: 'flex-end', padding: `0 30px 24px 30px` }}>
				<Button variant="contained" onClick={onCancel}>
					Cancel
				</Button>
				<Spacer width={2} />
				<Button variant="contained" onClick={handleOk}>
					Ok
				</Button>
			</Row>
		</>
	);
};

export default DateFilter;

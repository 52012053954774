import { useDispatch } from 'react-redux';

import { ApiInspection, APIProperty, MaintenanceFlag } from '@rentcheck/types';

import { Rules } from '@rentcheck/biz';
import { MenuButton } from 'components';
import { useLatchelIntegrations } from 'hooks/latchel-integrations';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
	selectedFlags: MaintenanceFlag[];
	allFlags: MaintenanceFlag[];
}

const CreateButton = ({
	inspection,
	property,
	selectedFlags,
	allFlags,
}: Props) => {
	const dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);
	const { latchelIntegrations } = useLatchelIntegrations();

	const latchelIntegrationForTeam = latchelIntegrations.find(
		(li) => li.team.id === inspection.team?.id
	);

	const flags = selectedFlags.length ? selectedFlags : allFlags;

	const menu: Record<string, () => void> = {
		'Create Maintenance Report': () =>
			dispatch(
				ModalFlowActions.showCreateMaintenanceReportModal({
					inspection,
					flags,
				})
			),
	};

	if (latchelIntegrationForTeam) {
		menu['Create Latchel Work Order'] = () =>
			dispatch(
				ModalFlowActions.showCreateWorkOrderModal({
					provider: 'Latchel',
					inspection,
					flags,
				})
			);
	}

	if (Rules.WorkOrders.canCreateAppFolioWorkOrders(property, subscription)) {
		menu['Create AppFolio Work Order'] = () =>
			dispatch(
				ModalFlowActions.showCreateWorkOrderModal({
					provider: 'AppFolio',
					inspection,
					flags,
				})
			);
	}

	if (Rules.WorkOrders.canCreateRentManagerWorkOrders(property, subscription)) {
		menu['Create Rent Manager Service Issue'] = () =>
			dispatch(
				ModalFlowActions.showCreateWorkOrderModal({
					provider: 'Rent Manager',
					inspection,
					flags,
				})
			);
	}

	return <MenuButton title="Create" menu={menu} />;
};

export default CreateButton;

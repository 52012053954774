"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canCreateAutomation = exports.canEditAutomation = void 0;
const subscriptions_1 = require("../../utils/subscriptions");
const canEditAutomation = ({ user, subscription, permissionGroups, }) => {
    if (!user) {
        return false;
    }
    /**
     * Only users with pro plan are allowed to edit automations
     */
    if (!(0, subscriptions_1.isOnProPlan)(subscription)) {
        return false;
    }
    /**
     * Paid users should have a permission group with
     * allow_automation_editing set to true
     * in order to be able to edit the automations
     */
    return permissionGroups.some((p) => p.allow_automation_editing);
};
exports.canEditAutomation = canEditAutomation;
const canCreateAutomation = ({ user, subscription, permissionGroups, }) => {
    if (!user) {
        return false;
    }
    /**
     * Only users with pro plan and inspection automation addons
     * are allowed to edit automations
     */
    if (!(0, subscriptions_1.isOnProPlan)(subscription)) {
        return false;
    }
    if (!(0, subscriptions_1.hasInspectionAutomationAddons)(subscription)) {
        return false;
    }
    /**
     * Paid users should have a permission group with
     * allow_automation_editing set to true
     * in order to be able to create automations
     */
    return permissionGroups.some((p) => p.allow_automation_editing);
};
exports.canCreateAutomation = canCreateAutomation;

import { ImportModel, ImportOperation, ImportSource } from '@rentcheck/types';
import { createContext } from 'react';
import { FlatfileRecord } from './base-dialog/steps/embedded-flatfile/types';

export type FlatFileImportContextType = {
	model: ImportModel;

	operation: ImportOperation | undefined;
	setOperation: (operation: ImportOperation | undefined) => void;

	pms: ImportSource;
	setPms: (pms: ImportSource) => void;

	step: number;
	setStep: (step: number) => void;

	processedRecords: FlatfileRecord[];
	setProcessedRecords: (records: FlatfileRecord[]) => void;
};

export const FlatFileImportContext = createContext<FlatFileImportContextType>({
	model: 'unit',

	operation: undefined,
	setOperation: () => {},

	pms: 'rentcheck',
	setPms: () => {},

	step: 0,
	setStep: () => {},

	processedRecords: [],
	setProcessedRecords: () => {},
});

import _ from 'lodash';
import moment from 'moment';
export const millisToString = (millis) => {
    return millis ? moment(millis).format('YYYY-MM-DD') : undefined;
};
export const arrayOrUndefined = (array) => {
    if ((array === null || array === void 0 ? void 0 : array.length) > 0) {
        return array;
    }
    return undefined;
};
export const buildFileRequestFilters = (documentType, filters) => {
    var _a, _b, _c, _d, _e;
    if (!filters) {
        return {};
    }
    if (documentType === 'INSPECTION_LIST_EXPORT') {
        return Object.assign(Object.assign({}, filters), { detailed: filters.detailed });
    }
    if (documentType === 'MAINTENANCE_REPORTS_LIST_EXPORT') {
        return {
            search: filters.search,
            inspection_templates: (_a = filters.inspectionTemplates) === null || _a === void 0 ? void 0 : _a.join(','),
            created_date: filters.dateRange
                ? {
                    start: millisToString(filters.dateRange.start),
                    end: millisToString(filters.dateRange.end),
                }
                : undefined,
            detailed: filters.detailed,
            sort_by: filters.sortBy,
            sort_type: filters.sortType,
        };
    }
    if (['INSPECTION_REPORT', 'MAINTENANCE_REPORT'].includes(documentType)) {
        return {
            send_to: filters.send_to,
            note: filters.note,
        };
    }
    if (documentType === 'SUBSCRIPTION_REPORT_LIST_EXPORT') {
        return {
            'inspection_type[]': arrayOrUndefined(filters.inspectionType),
            start_due_date: millisToString((_b = filters.dueDateRange) === null || _b === void 0 ? void 0 : _b.start),
            end_due_date: millisToString((_c = filters.dueDateRange) === null || _c === void 0 ? void 0 : _c.end),
        };
    }
    if ([
        'UNITS_LIST_EXPORT',
        'BUILDINGS_LIST_EXPORT',
        'COMMUNITIES_LIST_EXPORT',
    ].includes(documentType)) {
        return {
            search: filters.search,
            'building_id[]': arrayOrUndefined(filters.building),
            'team_id[]': arrayOrUndefined(filters.team),
            last_completed_inspection_start_date: millisToString((_d = filters.dateRange) === null || _d === void 0 ? void 0 : _d.start),
            last_completed_inspection_end_date: millisToString((_e = filters.dateRange) === null || _e === void 0 ? void 0 : _e.end),
            last_completed_inspection_template: filters.inspectionTemplate,
            detailed: filters.detailed,
        };
    }
    if (documentType === 'MAINTENANCE_FLAGS_LIST_EXPORT') {
        return {
            inspection_id: filters.inspection_id,
            'maintenance_flag_ids[]': arrayOrUndefined(filters.maintenance_flag_ids),
        };
    }
    return {};
};
export const prepareFiltersForQueryParams = (filters) => {
    if (!filters) {
        return undefined;
    }
    return _.mapValues(filters, (fv) => {
        if (fv === undefined || fv === null) {
            return undefined;
        }
        /**
         * If this is a MultiValueFilter then we need to join the values
         * with a pipe (|) character
         */
        if (fv.condition) {
            const multiValueFilter = fv;
            return Object.assign(Object.assign({}, multiValueFilter), { value: multiValueFilter.value.join('|') });
        }
        return fv;
    });
};

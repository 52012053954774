import { combineReducers } from 'redux';
import { State as ImportsState, default as importsReducer } from './imports';
import { State as ImportTasksState, default as tasksReducer } from './tasks';

export type State = {
	tasks: ImportTasksState;
	imports: ImportsState;
};

const reducer = combineReducers({
	tasks: tasksReducer,
	imports: importsReducer,
});

export default reducer;

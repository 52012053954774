type EventHandler = (...args: any[]) => void;

const eventNames = [
	'teammates.users.bulkActionSuccess',
	'inspections.editDueDate.bulkActionSuccess',
	'inspections.sendReminder.bulkActionSuccess',
	'inspections.editRecipients.bulkActionSuccess',
	'inspection.featuresModal.currentFeatureChanged',
	'analytics.trackEvent',
] as const;

export type EventType = (typeof eventNames)[number];

export class EventRegister {
	private events: { [key: EventType[number]]: EventHandler[] };

	constructor() {
		this.events = {};
	}

	// Subscribe to an event
	on(eventName: EventType, listener: EventHandler) {
		if (!this.events[eventName]) {
			this.events[eventName] = [];
		}
		this.events[eventName].push(listener);
	}

	// Emit an event
	emit(eventName: EventType, ...args: any[]) {
		if (this.events[eventName]) {
			this.events[eventName].forEach((listener) => listener(...args));
		}
	}

	// Remove a specific listener
	off(eventName: EventType, listenerToRemove: EventHandler) {
		if (!this.events[eventName]) return;

		this.events[eventName] = this.events[eventName].filter(
			(listener) => listener !== listenerToRemove
		);
	}
}

export default new EventRegister();

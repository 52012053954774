import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Menu, MenuItem, PopoverOrigin, Tooltip } from '@mui/material';
import React from 'react';
import { colors } from 'theme';

import PlanGate, { PlanGateProps } from 'components/plan-gate';
import { Column } from '../layout/Column';
import { Row } from '../layout/Row';
import Spacer from '../Spacer';

interface Props {
	triggerNode: React.ReactNode;
	options: PopoverItemProps[];
	disabled?: boolean;
	width?: number;
	id?: string;
	prevItems?: any[];
	topSpace?: number;
	noBorder?: boolean;
	anchorPosition?: 'top-right' | 'bottom-right' | 'default';
}

export interface PopoverItemProps {
	title: string;
	icon?: IconDefinition;
	iconColor?: string;
	iconRight?: IconDefinition;
	iconRightColor?: string;
	danger?: boolean;
	onClick: (event: React.MouseEvent<HTMLDivElement>) => void;
	onClickWithId?: (id: string, prevItems?: any) => void;
	isLast?: boolean;
	selected?: boolean;
	tooltip?: string;
	planGateProps?: Omit<PlanGateProps, 'children'>;
}

const PopoverMenu = ({
	triggerNode,
	options,
	disabled,
	id,
	prevItems,
	anchorPosition = 'default',
}: Props) => {
	const [anchorEl, setAnchorEl] = React.useState<HTMLDivElement | null>(null);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
		event.stopPropagation();
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const anchorOrigins: { [key: string]: PopoverOrigin } = {
		'top-right': {
			vertical: 'top',
			horizontal: 'right',
		},
		'bottom-right': {
			vertical: 'bottom',
			horizontal: 'right',
		},
		default: {
			vertical: 'top',
			horizontal: 'center',
		},
	};

	const transformOrigins: { [key: string]: PopoverOrigin } = {
		'top-right': {
			vertical: 'top',
			horizontal: 'right',
		},
		'bottom-right': {
			vertical: 'top',
			horizontal: 'right',
		},
		default: {
			vertical: 'top',
			horizontal: 'center',
		},
	};

	return (
		<>
			<div onClick={disabled ? undefined : handleClick}>{triggerNode}</div>
			<Menu
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={anchorOrigins[anchorPosition]}
				transformOrigin={transformOrigins[anchorPosition]}>
				<Column>
					{options.map((option, index) => (
						<PopoverItem
							{...option}
							key={option.title}
							isLast={index === options.length - 1}
							onClick={(e) => {
								e.stopPropagation();
								handleClose();
								option.onClick(e);
								if (id !== undefined) {
									option.onClickWithId?.(id, prevItems);
								}
							}}
						/>
					))}
				</Column>
			</Menu>
		</>
	);
};

export const PopoverItem = ({
	title,
	onClick,
	danger,
	icon,
	iconColor,
	iconRight,
	iconRightColor,
	tooltip,
	planGateProps,
}: PopoverItemProps) => {
	const color = danger ? colors.danger : undefined;

	const InnerContent = () => (
		<MenuItem sx={{ width: '100%' }}>
			<Row id={`popover-item-${title}`}>
				{!!icon && (
					<FontAwesomeIcon
						icon={icon}
						color={iconColor ?? color}
						style={{ marginRight: 8 }}
					/>
				)}
				{title}
				{!!iconRight && (
					<>
						<Spacer width={2} />
						<FontAwesomeIcon icon={iconRight} color={iconRightColor ?? color} />
					</>
				)}
			</Row>
		</MenuItem>
	);

	const GatedContent = () => {
		if (planGateProps) {
			return (
				<PlanGate {...planGateProps}>
					<InnerContent />
				</PlanGate>
			);
		}

		return <InnerContent />;
	};

	return (
		<>
			{!!tooltip && (
				<Tooltip title={tooltip} placement="right" arrow>
					<Column onClick={onClick}>
						<GatedContent />
					</Column>
				</Tooltip>
			)}
			{!tooltip && (
				<Column onClick={onClick}>
					<GatedContent />
				</Column>
			)}
		</>
	);
};

export default PopoverMenu;

import { IntegrationsApi } from '@rentcheck/api-frontend';
import { CreateWorkOrderParams } from '@rentcheck/api-frontend/dist/maintenance/work-orders/create';
import { MaintenanceFlag } from '@rentcheck/types';
import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import { featureDisplayName } from 'utils/helpers';
import { WorkOrderInfo } from '../content/common';

export interface ModalConfig {
	dialogTitle: string;
	vendor: string;

	/**
	 * Name mappings for the work order.
	 */
	mappedSkillName: string;
	mappedWorkOrderName: string;
	mappedDescriptionName: string;
	mappedOkToEnterName: string;
	mappedPriorityName: string;

	getSkills: (propertyId: string, userId: string) => Promise<string[]>;
	getPriorities: (propertyId: string, userId: string) => Promise<string[]>;
	getCreateParams: (
		flags: MaintenanceFlag[],
		workOrderInfo: WorkOrderInfo
	) => CreateWorkOrderParams;
}

export const getModalConfig = (
	provider: CreateWorkOrderMetadata['provider']
): ModalConfig => {
	if (provider === 'Rent Manager') return rentManagerModalConfig;
	throw new Error(`No modal config for provider ${provider}`);
};

/**
 * Rent Manager modal config.
 */

const rentManagerModalConfig: ModalConfig = {
	dialogTitle: 'Create Rent Manager Service Issue',
	vendor: 'Rent Manager',

	mappedSkillName: 'Category',
	mappedWorkOrderName: 'Service Issue',
	mappedDescriptionName: 'Service Issue Details Description',
	mappedOkToEnterName: 'Access is Authorized',
	mappedPriorityName: 'Service Issue Priority',

	getSkills: (propertyId) =>
		IntegrationsApi.RentManager.getSkills(propertyId).then((res) =>
			res.map((s) => s.name)
		),

	getPriorities: (propertyId) =>
		IntegrationsApi.RentManager.getPriorities(propertyId).then((res) =>
			res.map((p) => p.name)
		),

	getCreateParams: (flags, workOrderInfo) => ({
		type: 'Rent Manager',
		flags,
		name: `${flags[0]?.skill}: ${flags
			.map((f) => featureDisplayName(f))
			.join(', ')}`,
		okToEnter: workOrderInfo.okToEnter,
		priority: workOrderInfo.priority,
		description: workOrderInfo.description,
	}),
};

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApiInspectionTemplateDigest } from '@rentcheck/types';
import { landing } from 'assets';
import _ from 'lodash';
import { ReactNode } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';
import BaseCard from './base-card';

interface Props {
	template: ApiInspectionTemplateDigest;
	onClick: (template: ApiInspectionTemplateDigest) => void;
	actions?: ReactNode;
}

const Icon = ({ template }: Pick<Props, 'template'>) => {
	const icon = {
		unit: solid('home'),
		building: solid('buildings'),
		community: solid('bench-tree'),
	}[template.property_type];

	if (template.is_rc_template) {
		return (
			<img alt="" src={landing.rentCheckLogoNotext} style={{ height: 17 }} />
		);
	}

	return <FontAwesomeIcon icon={icon} color="white" />;
};

export default ({ template, onClick, actions }: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	if (!subscription) {
		return null;
	}

	const handleClick = () => {
		onClick(template);
	};

	const templateType = `${
		template.is_rc_template ? 'RentCheck' : ''
	} ${_.capitalize(template.property_type)} Inspection`;

	return (
		<BaseCard
			title={template.name}
			subtitle={template.internal_label}
			templateType={templateType}
			description={template.description}
			backgroundColor={template.is_rc_template ? '#fff' : '#F6F5FF'}
			hoverBackgroundColor={template.is_rc_template ? '#eee' : '#e5e4ee'}
			opacity={template.is_published ? 1 : 0.6}
			icon={<Icon template={template} />}
			iconBackgroundColor={template.is_rc_template ? '#F6F5FF' : '#2D3CE6'}
			onClick={handleClick}
			actions={actions}
			statusChipText={template.is_published ? 'Live' : undefined}
		/>
	);
};

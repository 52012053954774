import { getFirebase, reactReduxFirebase } from 'react-redux-firebase';
import { applyMiddleware, compose, createStore } from 'redux';
import { getFirestore, reduxFirestore } from 'redux-firestore';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';

import fbConfig from 'config/firebase';
import Reactotron from 'config/reactotron';

import rootReducer from './reducers/rootReducer';
import {
	fileRequestsTransform,
	importsTransform,
	inspectionsTransform,
} from './transforms';

// @ts-expect-error
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const persistConfig = {
	key: 'root',
	storage,
	whitelist: ['inspections', 'fileRequests', 'imports'],
	transforms: [inspectionsTransform, fileRequestsTransform, importsTransform],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(
	persistedReducer,
	composeEnhancers(
		applyMiddleware(thunk.withExtraArgument({ getFirebase, getFirestore })),
		Reactotron.createEnhancer?.(),
		reduxFirestore(fbConfig),
		reactReduxFirebase(fbConfig, {
			useFirestoreForProfile: true,
			userProfile: 'users',
			attachAuthIsReady: true,
		})
	)
);

export const persistor = persistStore(store);

export default store;

import { Dialog, DialogTitle, Typography } from '@mui/material';
import { useDispatch } from 'react-redux';

import { integrations } from 'assets';
import { Row, Spacer } from 'components';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { colors } from 'theme';
import { Dispatch } from 'types';

import { formattedAddress } from 'utils/helpers';
import Content from './content';

interface Props {
	open: boolean;
}

const CreateWorkOrder = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalData = useCreateWorkOrderModalData('Yardi');
	const { inspection, flags } = modalData?.metadata ?? {};

	if (!profile || !inspection || !flags) return null;

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle>
				<Row>
					<img
						alt="Yardi"
						src={integrations.yardiBlue}
						style={{ width: 26, height: 26 }}
					/>
					<Spacer width={3} />
					<Typography variant="h4" noWrap>
						Yardi Work Order
					</Typography>
				</Row>
				<Spacer height={2} />
				<Typography color={colors.gray}>
					{formattedAddress(inspection.property)}
				</Typography>
			</DialogTitle>
			<Content />
		</Dialog>
	);
};

export default CreateWorkOrder;

import { Constants } from '@rentcheck/biz';
import { Button, PlanGate } from 'components';
import { useIntercom } from 'react-use-intercom';

const Latchel = () => {
	const { showNewMessage } = useIntercom();

	const handleChatToInstall = () => {
		showNewMessage();
	};

	return (
		<PlanGate variant="professional" addonIds={Constants.Addons.Latchel.All}>
			<Button onClick={handleChatToInstall}>Install</Button>
		</PlanGate>
	);
};

export default Latchel;

import { AccountSettings, AccountSettingsSkill } from '@rentcheck/types';
import { DialogTitle } from 'components';

import { FlatfileProvider } from '@flatfile/react';
import { Dialog, DialogContent } from '@mui/material';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { DataSubmittedEvent } from 'screens/account/imports/flows/dialogs/common/base-dialog/steps/embedded-flatfile/types';
import { AccountSettingsActions, SnackbarActions } from 'store/actions';
import styled from 'styled-components';
import { Dispatch } from 'types';
import { sheetConfig } from './blueprint';
import FlatfileIframe from './flatfile-iframe';

interface Props {
	open: boolean;
	onClose: () => void;
	setValue: (value?: AccountSettings) => void;
}

export default ({ open, onClose, setValue }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const handleDataSubmitted = async (event: DataSubmittedEvent) => {
		const records = (await event.allData()).records;
		const rawData: AccountSettingsSkill[] = _.map(records, (r) =>
			_.mapValues(r.values, (v) => v.value)
		) as unknown as AccountSettingsSkill[];

		/**
		 * Coerce is_default values to boolean
		 */
		const data = rawData.map((d) => ({
			...d,
			is_default: d.is_default === true,
		}));

		/**
		 * Validate that there's exactly one default skill.
		 */
		if (data.filter((rd) => rd.is_default === true).length !== 1) {
			dispatch(
				SnackbarActions.showError('Please select exactly one default skill.')
			);
			return;
		}

		/**
		 * Clean up data.sub_category if undefined, as firebase
		 * is not configured to ignore undefined values.
		 */
		data.forEach((data) => {
			if (data.sub_category === null || data.sub_category === undefined) {
				delete data.sub_category;
			}
		});

		dispatch(AccountSettingsActions.updateSkills(data))
			.then((ac: AccountSettings) => {
				setValue(ac);
				dispatch(SnackbarActions.showSuccess('Skills uploaded succesfully'));
				onClose();
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)));
	};

	return (
		<>
			<Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth fullScreen>
				<DialogTitle title="Upload Skills" onClose={onClose} />
				<DialogContent>
					<FlatfileImportContainer>
						<FlatfileProvider
							publishableKey={process.env.REACT_APP_FLATFILE_PK}
							config={{
								displayAsModal: false,
								mountElement: 'flatfile-iframe-container',
							}}>
							<FlatfileIframe
								sheetConfig={sheetConfig}
								onDataSubmitted={handleDataSubmitted}
							/>
						</FlatfileProvider>
					</FlatfileImportContainer>
				</DialogContent>
			</Dialog>
		</>
	);
};

const FlatfileImportContainer = styled.div`
	display: flex;
	height: 100%;

	iframe {
		flex: 1;
		border: none;
		box-shadow: none !important;
		height: 100% !important;
	}

	.flatfile-close-button {
		display: none;
	}
`;

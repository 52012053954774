import {
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
} from '@mui/material';
import { useIsMobileBreakpoint } from 'utils/hooks';

import InfiniteScrollV2 from 'components/infinite-scroll-v2';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ImportsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { Dispatch } from 'types';
import Row from './row';
import Skeleton from './skeleton';

export default () => {
	const isMobile = useIsMobileBreakpoint('md');
	const dispatch = useDispatch<Dispatch>();

	const imports = useTypedSelector((state) => state.imports.imports);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setLoading(true);
		dispatch(ImportsActions.getAll(0)).finally(() => setLoading(false));
	}, [dispatch]);

	const handleLoadMore = async () => {
		return dispatch(
			ImportsActions.getAll(
				_.ceil(imports.length / ImportsActions.defaultPageSize)
			)
		);
	};

	if (loading) {
		return <Skeleton />;
	}

	if (!imports.length) {
		return null;
	}

	return (
		<TableContainer component={Paper}>
			<Table>
				{!isMobile && (
					<TableHead>
						<TableRow>
							<TableCell>Type</TableCell>
							<TableCell>New/Updated Records</TableCell>
							<TableCell>Deleted Records</TableCell>
							<TableCell>Error Count</TableCell>
							<TableCell>User</TableCell>
							<TableCell>Status</TableCell>
						</TableRow>
					</TableHead>
				)}
				<TableBody>
					{imports.map((i) => (
						<Row key={i.id} object={i} />
					))}
				</TableBody>
			</Table>
			<InfiniteScrollV2
				onLoadMore={handleLoadMore}
				data={imports}
				pageSize={20}
			/>
		</TableContainer>
	);
};

import { CenteredColumn, DialogTitle, Spacer } from 'components';

import {
	Button,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
	useMediaQuery,
	useTheme,
} from '@mui/material';
import { ProspectData } from '@rentcheck/types';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { success } from 'assets';
import { SessionOptionsActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { mediaSize } from 'theme';

interface Props {
	open: boolean;
}

interface PlatformSuccessProps {
	prospectData: ProspectData;
}

interface PlatformActionsProps {
	prospectData: ProspectData;
	handleClose: () => void;
}

const WebSuccess = ({ prospectData }: PlatformSuccessProps) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!prospectData.payment_info_provided || fullScreen) return null;

	return (
		<>
			<Image src={success.billingWeb} />
			<Spacer height={8} />
			<Typography variant="subtitle2" align="center">
				Success! We can't wait to help you grow!
			</Typography>
			<Spacer height={4} />
			<Typography variant="subtitle2" align="center">
				Check your email for next steps and your onboarding checklist. Easily
				upload units and residents and you'll be saving time on inspections in
				no time.
			</Typography>
		</>
	);
};

const MobileSuccess = ({ prospectData }: PlatformSuccessProps) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!prospectData.payment_info_provided || !fullScreen) return null;

	return (
		<>
			<Typography variant="h5" align="center">
				Yay!
			</Typography>
			<Typography variant="h5" align="center">
				You're on your way to
			</Typography>
			<Typography variant="h5" align="center">
				inspections on autopilot!
			</Typography>

			<Image src={success.billingMobile} />

			<Typography variant="subtitle2" align="center">
				Use a computer to finish setting up your account.
			</Typography>

			<Typography align="center">
				In the meantime, click ‘Learn More’ to check out best practices on
				getting started.
			</Typography>
			<Spacer height={2} />
			<Typography align="center">
				Additionally,{' '}
				<a href="http://onelink.to/mz3azz" target="_blank" rel="noreferrer">
					explore our mobile app
				</a>{' '}
				to see the inspection walkthrough experience.
			</Typography>
		</>
	);
};

const WebActions = ({ handleClose }: PlatformActionsProps) => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (fullScreen) return null;

	return (
		<>
			<div />
			<Button variant="contained" onClick={handleClose}>
				Close
			</Button>
			<div />
		</>
	);
};

const MobileActions = () => {
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	if (!fullScreen) return null;

	return (
		<>
			<div />
			<a
				href="https://help.getrentcheck.com/en/collections/2931531-getting-started"
				target="_blank"
				rel="noopener noreferrer">
				<Button variant="contained">Learn More</Button>
			</a>
		</>
	);
};

const Actions = ({ prospectData, handleClose }: PlatformActionsProps) => {
	if (!prospectData.payment_info_provided) return null;
	return (
		<DialogActions>
			<MobileActions />
			<WebActions handleClose={handleClose} prospectData={prospectData} />
		</DialogActions>
	);
};

const SuccessDialog = ({ open }: Props) => {
	const dispatch = useDispatch();
	const theme = useTheme();
	const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

	const profile = useTypedSelector((state) => state.activeProfile);

	if (!profile?.prospect_data) return null;

	const handleClose = () => {
		dispatch(SessionOptionsActions.setBillingSetupDismissed(true));
	};

	return (
		<Dialog open={open} fullScreen={fullScreen}>
			<DialogTitle title="Thanks for subscribing!" />
			<DialogContent
				sx={{
					alignItems: 'center',
					justifyContent: 'flex-start',
					display: 'flex',
				}}>
				<CenteredColumn>
					{!profile.prospect_data.payment_info_provided && (
						<>
							<Image src={success.billingWeb} />
							<Spacer height={4} />
							<Typography variant="subtitle2" align="center">
								Success! We're rooting for you.
							</Typography>
							<Spacer height={2} />
							<Typography variant="subtitle2" align="center">
								Please note, it may take a few moments for your subscription to
								activate.
							</Typography>

							<Spacer height={10} />
							<CircularProgress />
							<Spacer height={20} />
						</>
					)}

					<WebSuccess prospectData={profile.prospect_data} />

					<MobileSuccess prospectData={profile.prospect_data} />
				</CenteredColumn>
			</DialogContent>

			<Actions handleClose={handleClose} prospectData={profile.prospect_data} />
		</Dialog>
	);
};

const Image = styled.img`
	@media ${mediaSize.mobile} {
		height: 248px;
		width: 80%;
	}
`;

export default SuccessDialog;

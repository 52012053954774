var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import APIConfig from '../../config';
import { request } from '../../request';
export const getLocations = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request({
        method: 'get',
        url: '/propify/rentmanager/locations',
        params: {
            user_id: APIConfig.getUserId(),
        },
    });
    return response.data.results.map((r) => ({
        id: r.id,
        name: r.friendly_name,
    }));
});
export const getSkills = (propertyId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request({
        method: 'get',
        url: '/propify/rentmanager/skills',
        params: {
            user_id: APIConfig.getUserId(),
            propertyId: propertyId,
        },
    });
    return response.data.results.map((r) => ({
        id: r.id,
        name: r.name,
    }));
});
export const getStatuses = () => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request({
        method: 'get',
        url: '/propify/rentmanager/work-order-statuses',
        params: {
            user_id: APIConfig.getUserId(),
        },
    });
    return response.data.results.map((r) => ({
        id: r.id,
        name: r.name,
    }));
});
export const getPriorities = (propertyId) => __awaiter(void 0, void 0, void 0, function* () {
    const response = yield request({
        method: 'get',
        url: '/propify/rentmanager/work-order-priorities',
        params: {
            user_id: APIConfig.getUserId(),
            propertyId: propertyId,
        },
    });
    return response.data.results.map((r) => ({
        id: r.id,
        name: r.name,
    }));
});
export const get = () => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: '/integrations/rent-manager',
    }).then((value) => {
        return value.data.data;
    });
});

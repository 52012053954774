import { DialogContent, Typography } from '@mui/material';
import { CenteredColumn, CenteredRow, LottieWrapper } from 'components';
import { useContext } from 'react';
import styled from 'styled-components';

import { FlatFileImportContext } from '../../../context';

export default () => {
	const context = useContext(FlatFileImportContext);

	if (context.step !== 3) {
		return null;
	}

	return (
		<DialogContent>
			<AnimationContainer>
				<div
					style={{
						position: 'absolute',
						top: '50%',
						transform: 'translateY(-50%)',
					}}>
					<LottieWrapper variant="import-confetti" width={640} height={640} />
				</div>
				<CenteredRow style={{ zIndex: 1 }}>
					<CenteredColumn
						style={{
							backgroundColor: '#F6F5FF',
							padding: '40px 60px',
							borderRadius: 8,
						}}>
						<Typography variant="h4" textAlign="center" mb={3}>
							Upload complete
						</Typography>
						<Typography variant="subtitle1" textAlign="center">
							Your data is being processed in
							<br />
							RentCheck. Check the imports page for
							<br />
							status updates.
						</Typography>
					</CenteredColumn>
				</CenteredRow>
			</AnimationContainer>
		</DialogContent>
	);
};

const AnimationContainer = styled.div`
	display: flex;
	height: 100%;

	align-items: center;
	justify-content: center;
`;

import { Import } from '@rentcheck/types';

export type State = Import[];

type Action = {
	type:
		| 'ADD_IMPORT'
		| 'REMOVE_IMPORT'
		| 'UPDATE_IMPORT'
		| 'CLEAR_IMPORTS'
		| 'IMPERSONATE'
		| 'SIGNOUT_SUCCESS';
	value: Import | (Pick<Import, 'id'> | Partial<Import>);
};

export default (state: State = [], action: Action) => {
	switch (action.type) {
		case 'SIGNOUT_SUCCESS':
		case 'IMPERSONATE':
		case 'CLEAR_IMPORTS':
			return [];
		case 'ADD_IMPORT':
			return [action.value, ...state];
		case 'UPDATE_IMPORT':
			return state.map((i) => {
				if (i.id === action.value.id) {
					return { ...i, ...action.value };
				}

				return i;
			});
		case 'REMOVE_IMPORT':
			return state.filter((i) => i.id !== action.value.id);
		default:
			return state;
	}
};

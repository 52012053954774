import { AccountSettings } from '@rentcheck/types';
import { Button, Spacer } from 'components';

import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { Dispatch } from 'types';
import FlatfileDialog from './flatfile-dialog';
import SkillsList from './skills-list';

interface Props {
	value?: AccountSettings;
	setValue: (value?: AccountSettings) => void;
}

export default ({ value, setValue }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const subscription = useTypedSelector((state) => state.subscription);

	const [dialogOpen, setDialogOpen] = useState(false);

	if (!value) {
		return null;
	}

	const handleShowFlatfile = () => {
		if (!subscription) {
			dispatch(SnackbarActions.showError('Subscription not found'));
			return;
		}

		setDialogOpen(true);
	};

	return (
		<>
			<Spacer height={4} />
			<Button onClick={handleShowFlatfile}>Upload</Button>
			<Spacer height={4} />
			<SkillsList value={value} setValue={setValue} />
			<FlatfileDialog
				open={dialogOpen}
				onClose={() => setDialogOpen(false)}
				setValue={setValue}
			/>
		</>
	);
};

import { DialogContent } from '@mui/material';
import { useContext } from 'react';

import { Flatfile } from '@flatfile/api';
import { FlatfileProvider } from '@flatfile/react';
import { ImportSource } from '@rentcheck/types';
import styled from 'styled-components';
import { FlatFileImportContext } from '../../../context';
import FlatfileIframe from './flatfile-iframe';
import { DataSubmittedEvent } from './types';

export interface EmbeddedFlatfileProps {
	sheetConfig: Record<ImportSource, Flatfile.SheetConfig>;
}

export default ({ sheetConfig }: EmbeddedFlatfileProps) => {
	const context = useContext(FlatFileImportContext);

	const { step, setStep, pms } = context;

	if (step !== 1) {
		return null;
	}

	const handleDataSubmitted = async (event: DataSubmittedEvent) => {
		const allProcessedData = await event.allData();
		context.setProcessedRecords(allProcessedData.records);
		setStep(2);
	};

	return (
		<DialogContent>
			<FlatfileImportContainer>
				<FlatfileProvider
					publishableKey={process.env.REACT_APP_FLATFILE_PK}
					config={{
						displayAsModal: false,
						mountElement: 'flatfile-iframe-container',
					}}>
					<FlatfileIframe
						sheetConfig={sheetConfig[pms]}
						onDataSubmitted={handleDataSubmitted}
					/>
				</FlatfileProvider>
			</FlatfileImportContainer>
		</DialogContent>
	);
};

const FlatfileImportContainer = styled.div`
	display: flex;
	height: 100%;

	iframe {
		flex: 1;
		border: none;
		box-shadow: none !important;
		height: 100% !important;
	}

	.flatfile-close-button {
		display: none;
	}
`;

import { Tooltip, Typography } from '@mui/material';
import { Column, Spacer } from 'components';
import { ReactElement } from 'react';

interface Props {
	title: string;
	body: string;
	cta: string;
	handleCta: () => void;
	children: ReactElement;
}
export default ({ title, body, cta, handleCta, children }: Props) => {
	return (
		<Tooltip
			sx={{ width: 250 }}
			title={
				<Column style={{ padding: 6 }}>
					<Typography fontWeight={600} color="inherit">
						{title}
					</Typography>
					<Spacer height={2} />
					<Typography color="inherit">{body}</Typography>
					<Spacer height={2} />
					<Typography
						sx={{ cursor: 'pointer' }}
						onClick={handleCta}
						color="inherit">
						{cta}
					</Typography>
				</Column>
			}>
			{children}
		</Tooltip>
	);
};

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Chip,
	ChipProps,
	SxProps,
	Theme,
	Tooltip,
	Typography,
} from '@mui/material';

import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import _ from 'lodash';
import { CSSProperties, forwardRef, MouseEventHandler } from 'react';

export interface Props {
	/**
	 * The label is displayed on the right side of the chip.
	 */
	label: ChipProps['label'];

	/**
	 * The tooltip is displayed when the user hovers over the chip.
	 */
	tooltip?: string;

	/**
	 * The icon is displayed on the left side of the chip.
	 */
	icon?: IconDefinition;

	/**
	 * The title takes the place of the icon if one is not provided.
	 */
	title: string;

	color: 'primary' | 'success' | 'warning' | 'error' | 'info';
	variant: 'outlined' | 'filled';
	matchIconColor?: boolean;
	onClick?: MouseEventHandler<HTMLDivElement>;
	onDelete?: ChipProps['onDelete'];
	selected?: boolean;
	style?: CSSProperties;
}

const buildSx = (
	color: Props['color'],
	variant: Props['variant'],
	matchIconColor?: boolean,
	selected?: boolean
): SxProps<Theme> => {
	const getSxColors = _.memoize(
		(color: Props['color'], matchIconColor?: boolean, selected?: boolean) => {
			const textColor = '#101010';

			let accentColor = '#2D3CE6';
			let backgroundColor = '#F6F5FF';
			let hoverColor = '#2D3CE63D';

			switch (color) {
				case 'primary':
					accentColor =
						variant === 'outlined'
							? '#2D3CE6'
							: selected
								? '#3847F3'
								: '#2D3CE6';
					backgroundColor = '#F6F5FF';
					hoverColor =
						variant === 'outlined'
							? '#2D3CE63D'
							: selected
								? '#2d3ce6'
								: '#2D3CE63D';
					break;

				case 'success':
					accentColor = '#009839';
					backgroundColor = '#DFFFE9';
					hoverColor = '#36B45F3D';
					break;

				case 'warning':
					accentColor = '#DB5C09';
					backgroundColor = '#FCF4E1';
					hoverColor = '#EB830D3D';
					break;

				case 'error':
					accentColor = '#B00020';
					backgroundColor = '#FEEAEE';
					hoverColor = '#F04D4C3D';
					break;

				case 'info':
					accentColor = '#54697E';
					backgroundColor = '#EAEEF6';
					hoverColor = '#232E3A3D';
					break;
			}

			if (variant === 'filled') {
				return {
					backgroundColor: selected ? accentColor : backgroundColor,
					textColor: selected
						? '#fff'
						: matchIconColor
							? accentColor
							: textColor,
					iconColor: selected ? '#fff' : accentColor,
					hoverColor: hoverColor,
				};
			}

			return {
				backgroundColor,
				textColor: matchIconColor ? accentColor : textColor,
				iconColor: accentColor,
				hoverColor: hoverColor,
			};
		}
	);

	const { backgroundColor, iconColor, hoverColor, textColor } = getSxColors(
		color,
		matchIconColor,
		selected
	);

	const baseSx: SxProps<Theme> = {
		cursor: 'pointer',
		fontWeight: 400,
		backgroundColor: backgroundColor,
		border: `1px solid ${backgroundColor}`,
		color: textColor,
		boxShadow: selected ? '0px 4px 4px 0px rgba(0, 0, 0, 0.25)' : 'none',

		'.MuiChip-icon': {
			color: iconColor,
		},

		'.MuiChip-deleteIcon': {
			fontSize: '14px',
			marginRight: '12px',
		},

		'&&:hover': {
			backgroundColor: hoverColor,
		},
	};

	if (selected && variant === 'outlined') {
		return {
			...baseSx,
			border: `1px solid ${iconColor}`,
		};
	}

	return baseSx;
};

const SegmentChip = forwardRef<HTMLDivElement, Props>((props, fwdRef) => {
	const {
		label,
		title,
		tooltip,
		icon,
		color,
		variant,
		matchIconColor,
		onClick,
		onDelete,
		selected,
		style,
	} = props;

	const sx = buildSx(color, variant, matchIconColor, selected);

	return (
		<Tooltip title={tooltip ?? ''}>
			<Chip
				id={`segment-chip-${title}`}
				variant="outlined"
				label={label}
				sx={sx}
				style={style}
				onClick={onClick}
				onDelete={onDelete}
				deleteIcon={<FontAwesomeIcon icon={solid('times')} />}
				ref={fwdRef}
				icon={
					icon ? (
						<FontAwesomeIcon icon={icon} />
					) : (
						<Typography fontWeight={600} fontSize={12}>
							{title}
						</Typography>
					)
				}
			/>
		</Tooltip>
	);
});

export default SegmentChip;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.defaultValue = void 0;
exports.defaultValue = {
    inspection_view_mode: 'list',
    import_units_integration_appfolio_dismissed: false,
    import_units_integration_rentmanager_dismissed: false,
    import_residents_integration_appfolio_dismissed: false,
    import_residents_integration_rentmanager_dismissed: false,
};

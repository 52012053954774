import { Box, DialogContent, Grid, Typography } from '@mui/material';
import { Button, CenteredColumn, Spacer } from 'components';
import { useContext } from 'react';

import {
	FlatFileImportContext,
	FlatFileImportContextType,
} from '../../../context';
import ImportTypeCta from '../../../import-type-cta';
import PMSHasIntegrationDialog from './pms-has-integration-dialog';
import SelectPms from './select-pms';

export interface SelectOperationProps {
	image: string;
	title: string;
	subtitle: string;
	operation: FlatFileImportContextType['operation'];
	requiresPMSSelection?: boolean;
	comingSoon?: boolean;
	default?: boolean;
}

interface Props {
	selectOperationProps: SelectOperationProps[];
}

export default ({ selectOperationProps }: Props) => {
	const context = useContext(FlatFileImportContext);

	if (context.step !== 0) {
		return null;
	}

	return (
		<>
			<DialogContent
				sx={{
					backgroundColor: '#F6F5FF',
					display: 'flex',
					flexDirection: 'column',
				}}>
				<CenteredColumn>
					<Typography variant="h4" mb={4}>
						How would you like to start?
					</Typography>
					<Grid container columns={{ xs: 1, sm: 2 }} spacing={2}>
						{selectOperationProps.map((props) => (
							<>
								<ImportTypeCta
									{...props}
									onClick={() => {
										context.setOperation(props.operation);
										context.setPms('rentcheck');
									}}
									selected={context.operation === props.operation}
								/>
								{props.requiresPMSSelection && (
									<Grid
										item
										xs={1}
										sx={{ display: { xs: 'flex', sm: 'none' }, marginTop: -4 }}>
										<SelectPms />
									</Grid>
								)}
							</>
						))}
					</Grid>
					<Box sx={{ display: { xs: 'none', sm: 'flex' }, width: '100%' }}>
						<SelectPms />
					</Box>
					<Spacer height={14} />
				</CenteredColumn>
				<CenteredColumn
					style={{
						backgroundColor: 'white',
						paddingTop: 40,
						paddingBottom: 40,
						marginLeft: -28,
						marginRight: -28,
						marginBottom: -20,
						flex: 1,
					}}>
					<Typography variant="subtitle1" mb={2}>
						Need help getting started?
					</Typography>
					<a
						href="https://help.getrentcheck.com/en/collections/10549928-importing-exporting-data"
						target="_blank"
						rel="noreferrer">
						<Button variant="outlined">View Import Guides</Button>
					</a>
				</CenteredColumn>
			</DialogContent>
			<PMSHasIntegrationDialog />
		</>
	);
};

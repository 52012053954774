"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canShowDeactivate = exports.canBeDeactivated = exports.canDeactivate = exports.canEdit = exports.canAccess = void 0;
const __1 = require("../..");
const utils_1 = require("../../utils");
const common_1 = require("../common");
const canAccess = (user, subscription) => {
    if (!subscription)
        return false;
    if (!user)
        return false;
    return (!utils_1.Subscriptions.isCancelled(subscription) &&
        !utils_1.Subscriptions.isPaused(subscription));
};
exports.canAccess = canAccess;
const canEdit = (user, subscription) => {
    if (!subscription)
        return {
            result: false,
            reason: (0, common_1.buildReason)('edit', 'user', 'you must have a subscription'),
        };
    if (!user)
        return {
            result: false,
            reason: (0, common_1.buildReason)('edit', 'user', 'you must be logged in'),
        };
    if (!__1.Utils.Users.isTeamAdmin(user)) {
        return {
            result: false,
            reason: (0, common_1.buildReason)('edit', 'user', 'you must be an admin'),
        };
    }
    return { result: true, reason: '' };
};
exports.canEdit = canEdit;
const canDeactivate = (user, subscription) => {
    if (!subscription)
        return {
            result: false,
            reason: (0, common_1.buildReason)('deactivate', 'user', 'you must have a subscription'),
        };
    if (!user)
        return {
            result: false,
            reason: (0, common_1.buildReason)('deactivate', 'user', 'you must be logged in'),
        };
    if (!__1.Utils.Users.isTeamAdmin(user)) {
        return {
            result: false,
            reason: (0, common_1.buildReason)('deactivate', 'user', 'you must be an admin'),
        };
    }
    return { result: true, reason: '' };
};
exports.canDeactivate = canDeactivate;
const canBeDeactivated = (target, user) => {
    if (target.id === user.id) {
        return {
            result: false,
            reason: 'You cannot deactivate yourself',
        };
    }
    if (target.status !== 'Active') {
        return {
            result: false,
            reason: 'Inactive users cannot be deactivated',
        };
    }
    return { result: true, reason: '' };
};
exports.canBeDeactivated = canBeDeactivated;
const canShowDeactivate = (target, user) => {
    if (target.id === (user === null || user === void 0 ? void 0 : user.id)) {
        return {
            result: false,
            reason: 'You cannot deactivate yourself',
        };
    }
    return { result: true, reason: '' };
};
exports.canShowDeactivate = canShowDeactivate;

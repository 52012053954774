import { LatchelFirebaseProperty } from '@rentcheck/types';
import firebase from 'config/firebase';
import { objectsFromQuerySnapshot } from './helpers';

export const getAllProperties = (
	organizationId: string
): Promise<LatchelFirebaseProperty[]> =>
	firebase
		.firestore()
		.collection('latchel_properties')
		.where('organization_ids', 'array-contains', organizationId)
		.where('is_active', '==', 1)
		.get()
		.then((querySnapshot) =>
			objectsFromQuerySnapshot<LatchelFirebaseProperty>(querySnapshot)
		)
		.catch(() => []);

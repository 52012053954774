var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../../request';
const getAll = (pageNumber = 0, pageSize = 20, filters = {}, sorting, config) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        sort: sorting !== null && sorting !== void 0 ? sorting : {
            sort_by: 'created_date',
            sort_type: 'DESC',
        },
        pagination: {
            page_size: pageSize,
            page_number: pageNumber,
        },
    };
    return request({
        method: 'get',
        version: 'v2',
        url: '/maintenance_reports',
        params: {
            filters,
            options,
        },
        appConfig: config,
    }).then((value) => {
        return value.data;
    });
});
const getById = (id, config) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/maintenance_reports/${id}`,
        appConfig: config,
    }).then((value) => value.data.data);
});
export default { getAll, getById };

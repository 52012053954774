import assets from 'assets';
import BaseDialog from '../common/base-dialog';
import blueprint from './blueprint';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export default ({ open, setOpen }: Props) => {
	return (
		<BaseDialog
			open={open}
			setOpen={setOpen}
			model="unit"
			selectOperationProps={[
				{
					title: 'Create Units',
					subtitle:
						'Create new units with easy bulk imports using a spreadsheet.',
					image: assets.imports.unitsCreate,
					operation: 'create',
					requiresPMSSelection: true,
					comingSoon: false,
					default: true,
				},
				{
					title: 'Delete Units',
					subtitle:
						'Delete multiple units at once using a simple spreadsheet import.',
					image: assets.imports.unitsDelete,
					operation: 'delete',
					requiresPMSSelection: false,
					comingSoon: true,
				},
			]}
			embeddedFlatfileProps={blueprint}
		/>
	);
};

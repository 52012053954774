import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Column } from 'components';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import FilterChip from './common/filter-chip';
import FilterPopover from './common/filter-popover';
import FilterPopoverContent from './common/filter-popover-content';
import Skeleton from './common/skeleton';

const PropertyTypeFilter = () => {
	const dispatch = useDispatch();

	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);

	if (!dashboardData) {
		return <Skeleton />;
	}

	const handleClick = (value: string) => {
		dispatch({ type: 'SET_DASHBOARD_FILTER_PROPERTY_TYPE', value });

		if (value !== 'Unit') {
			dispatch({
				type: 'SET_DASHBOARD_FILTER_BUILDING',
				value: null,
			});
		}
	};

	const options = ['Unit', 'Building', 'Community'];

	const icons = {
		Unit: light('house'),
		Building: light('building'),
		Community: light('buildings'),
	};

	const currentValue = dashboardFilters.propertyType;

	return (
		<FilterPopover
			anchorChild={
				<FilterChip
					title={`${currentValue} Inspections`}
					icon={icons[currentValue]}
					variant="primary"
					endIcon={solid('chevron-down')}
				/>
			}
			contentChildren={
				<Column>
					<FilterPopoverContent.Title title="Filter by" />

					{options.map((o) => (
						<FilterPopoverContent.SelectableRow
							key={o}
							variant="radio"
							title={`${o} inspections`}
							onClick={() => handleClick(o)}
							selected={currentValue === o}
						/>
					))}
				</Column>
			}
		/>
	);
};

export default PropertyTypeFilter;

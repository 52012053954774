"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.validImportStatuses = exports.validImportSources = exports.validImportOperations = exports.validImportModels = void 0;
exports.validImportModels = ['unit', 'resident'];
exports.validImportOperations = [
    'create',
    'create/update',
    'delete',
];
exports.validImportSources = [
    'rentcheck',
    'appfolio',
    'buildium',
    'rentmanager',
    'rentvine',
    'propertyware',
];
exports.validImportStatuses = [
    'scheduled',
    'processing',
    'completed',
    'failed',
];

import { Chip, Typography } from '@mui/material';
import MenuItem from 'components/menu-item';
import hash from 'object-hash';

import { Column } from 'components/layout/Column';
import { SpacedRow } from 'components/layout/Row';

interface Props<T> {
	/**
	 * Array of options.
	 */
	option: T;

	/**
	 * The props to apply on the li element.
	 */
	autocompleteProps: React.HTMLAttributes<HTMLLIElement>;

	/**
	 * Used to determine the string value for a given option.
	 * It's used to fill the input (and the list box options if `renderOption` is not provided).
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionLabel?: (option: T) => string;

	/**
	 * Used to determine the string subtitle value for a given option.
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionSubLabel?: (option: T) => string;

	/**
	 * Used to determine the chip label value for a given option.
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionChipProps?: (option: T) => {
		label: string;
		color: string;
	};
}

export default <T,>({
	option,
	autocompleteProps,
	getOptionLabel,
	getOptionSubLabel,
	getOptionChipProps,
}: Props<T>) => {
	const chipProps = getOptionChipProps?.(option);

	return (
		<MenuItem
			{...autocompleteProps}
			key={hash(option as any)}
			sx={{ height: getOptionSubLabel ? 50 : 40 }}>
			<SpacedRow style={{ width: '100%' }}>
				<Column
					style={{
						flexGrow: 1,
						minWidth: 0,
						whiteSpace: 'nowrap',
						textOverflow: 'ellipsis',
						overflow: 'hidden',
						marginRight: 8,
					}}>
					<span
						style={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}>
						{getOptionLabel?.(option) ?? (option as string)}
					</span>
					<Typography
						variant="caption"
						style={{
							textOverflow: 'ellipsis',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
						}}>
						{getOptionSubLabel?.(option)}
					</Typography>
				</Column>
				{!!chipProps && (
					<Chip
						label={chipProps.label}
						sx={{ backgroundColor: chipProps.color, fontWeight: 400 }}
					/>
				)}
			</SpacedRow>
		</MenuItem>
	);
};

import { MouseEvent } from 'react';
import styled from 'styled-components';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider, Typography, Chip } from '@mui/material';

import { AssignMethod } from '@rentcheck/types';

import { Column, SpacedRow, Spacer } from 'components';

import { assignMethodData } from './common';

interface Props {
	variant: AssignMethod;
	onClick: (value: AssignMethod, e: MouseEvent<HTMLDivElement>) => void;
	icon?: IconDefinition;
	noTeams?: boolean;
}

export default ({ variant, icon, onClick, noTeams }: Props) => {
	const handleClick = (e: MouseEvent<HTMLDivElement>) => {
		onClick(variant, e);
	};

	if (noTeams) return null;

	const { title, caption, comingSoon, description, isNew, moreInfo } =
		assignMethodData[variant];

	return (
		<>
			<RowContainer id={`select-assign-${variant}`} onClick={handleClick}>
				<Column>
					<Typography variant="caption" color="#2D3CE6">
						{caption}
					</Typography>
					<Typography variant="subtitle1">{title}</Typography>
					<Typography color="#00000099">{description}</Typography>
				</Column>
				<Spacer width={4} />
				<Column>
					{comingSoon ? (
						<Chip
							label="Coming soon"
							sx={{ marginRight: 1, '.MuiChip-deleteIcon': { fontSize: 14 } }}
							deleteIcon={<FontAwesomeIcon icon={solid('info-circle')} />}
						/>
					) : (
						<></>
					)}

					{!comingSoon && isNew && !icon ? (
						<Chip
							variant="filled"
							label="New"
							sx={{
								fontSize: 14,
								marginRight: 1,
								'.MuiChip-deleteIcon': {
									paddingLeft: 0.5,
									fontSize: 18,
									color: '#2D3CE6',
								},
								'.MuiChip-deleteIcon:hover': { color: '#2D3CE6' },
							}}
							deleteIcon={
								<FontAwesomeIcon color="#2D3CE6" icon={solid('info-circle')} />
							}
						/>
					) : (
						<></>
					)}

					{moreInfo && !icon ? (
						<a href={moreInfo} target="_blank" rel="noreferrer">
							<FontAwesomeIcon
								icon={solid('info-circle')}
								color="#2D3CE6"
								style={{ marginRight: 10 }}
							/>
						</a>
					) : (
						<></>
					)}

					{icon ? (
						<FontAwesomeIcon icon={icon} style={{ marginRight: 10 }} />
					) : (
						<></>
					)}
				</Column>
			</RowContainer>
			<Divider />
		</>
	);
};

const RowContainer = styled(SpacedRow)<{ comingSoon?: boolean }>`
	transition: 0.2s background-color ease-in-out;
	cursor: ${({ comingSoon }) => (comingSoon ? '' : 'pointer')};
	border-radius: 4px;
	margin-bottom: 15px;
	margin-top: 10px;

	background-color: ${({ comingSoon }) =>
		comingSoon ? '#0000000A' : 'transparent'};

	&:hover {
		background-color: ${({ comingSoon }) =>
			comingSoon ? '#0000000A' : '#2d3ce60a;'};
	}
`;

import { Constants } from '@rentcheck/biz';
import { UserSettings } from '@rentcheck/types';

export type State = UserSettings;

type Action = {
	type: 'SET_USER_SETTINGS' | 'SIGNOUT_SUCCESS';
	value: Partial<UserSettings>;
};

export default (
	state: State = { ...Constants.UserSettings.defaultValue },
	action: Action
) => {
	switch (action.type) {
		case 'SET_USER_SETTINGS':
			return { ...state, ...action.value };
		case 'SIGNOUT_SUCCESS':
			return { ...Constants.UserSettings.defaultValue };
		default:
			return state;
	}
};

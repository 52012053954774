import { useEffect } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { IntercomApi } from '@rentcheck/api-frontend';
import {
	useInitializeProfile,
	useKeepButtonHidden,
	useLogOut,
	useTrackEvent,
} from 'hooks/analytics';
import { EventRegister } from 'utils';
import { isEmbeddedInMobileApp } from 'utils/helpers';

type OptionsType = Record<
	string,
	string | number | Date | undefined | string[]
>;

const Analytics = () => {
	const trackEvent = useTrackEvent();
	const logOut = useLogOut();
	const keepButtonHidden = useKeepButtonHidden();
	const initializeProfile = useInitializeProfile();

	const authenticatedProfile = useTypedSelector(
		(state) => state.authenticatedUser
	);
	const activeProfile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	useEffect(() => {
		const callHook = (params: { name: string; options?: OptionsType }) => {
			trackEvent(params.name, params.options);
		};

		EventRegister.on('analytics.trackEvent', callHook);

		return () => {
			EventRegister.off('analytics.trackEvent', callHook);
		};
	}, []);

	useEffect(() => {
		if (isEmbeddedInMobileApp()) {
			keepButtonHidden();
		}

		if (!authenticatedProfile) {
			logOut();
			return;
		}

		if (!activeProfile) {
			return;
		}

		/**
		 * If we're impersonating a user then we don't want to initialize the profile
		 * or attach the user to a subscription
		 */
		if (authenticatedProfile.id !== activeProfile.id) {
			return;
		}

		initializeProfile(authenticatedProfile, subscription);

		if (subscription?.rentcheck_contact?.company_id) {
			IntercomApi.users
				.attachCompanyToCustomer({
					companyId: subscription.rentcheck_contact.company_id,
				})
				.catch(() => {});
		}
	}, [activeProfile?.id, authenticatedProfile, subscription]);

	return null;
};

export default Analytics;

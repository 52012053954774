import { LoadingButton } from '@mui/lab';
import {
	Accordion,
	AccordionSummary,
	Card,
	CardContent,
	TextField,
	Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { IntegrationSurveyApi } from 'api';
import { integrations } from 'assets';
import { Column, Row, SpacedRow, Spacer } from 'components';
import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

export const Survey = () => {
	const dispatch = useDispatch();

	const user = useTypedSelector((state) => state.activeProfile);

	const [value, setValue] = useState('');
	const [loading, setLoading] = useState(false);

	const handleSubmit = () => {
		if (!value) return;
		if (!user) return;

		setLoading(true);
		IntegrationSurveyApi.createResponse(value, user)
			.then(() => {
				setValue('');
				dispatch(
					SnackbarActions.showSuccess(
						'Thank you! Your suggestion has been submitted.'
					)
				);
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	return (
		<Accordion expanded>
			<AccordionSummary sx={{ marginRight: 2 }}>
				<CardContent sx={{ width: '100%' }}>
					<Card>
						<CardContent>
							<SpacedRow>
								<Column>
									<Typography variant="h5">
										Didn’t find what you were looking for?
									</Typography>
									<Typography variant="h5">
										What integration(s) would make managing your inspections
										easier?
									</Typography>
									<Spacer height={8} />
									<Typography fontWeight={600} color="#54687E">
										We are always improving RentCheck and will take your
										suggestions seriously.
									</Typography>
									<Spacer height={8} />
									<Row>
										<TextField
											value={value}
											onChange={(e) => setValue(e.target.value)}
											placeholder="Please type here"
											sx={{ width: 300 }}
										/>
										<Spacer width={3} />
										<LoadingButton
											variant="contained"
											loading={loading}
											onClick={handleSubmit}>
											Submit
										</LoadingButton>
									</Row>
								</Column>
								<img
									alt=""
									style={{
										alignSelf: 'flex-end',
										width: 150,
										height: 150,
										objectFit: 'contain',
									}}
									src={integrations.survey}
								/>
							</SpacedRow>
						</CardContent>
					</Card>
				</CardContent>
			</AccordionSummary>
		</Accordion>
	);
};

export default Survey;

import { IntegrationsApi } from '@rentcheck/api-frontend';
import { ApiAppFolioIntegration } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useAppFolioIntegration = () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [appFolioIntegration, setAppfolioIntegration] = useState<
		ApiAppFolioIntegration | undefined
	>(undefined);
	const [loadingAppFolioIntegration, setLoadingAppFolioIntegration] =
		useState(true);

	useEffect(() => {
		if (!subscription) {
			setLoadingAppFolioIntegration(false);
			return;
		}

		setLoadingAppFolioIntegration(true);

		IntegrationsApi.AppFolio.get()
			.then((data) => setAppfolioIntegration(data[0]))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoadingAppFolioIntegration(false));
	}, [subscription, dispatch]);

	return {
		loading: loadingAppFolioIntegration,
		appFolioIntegration,
	};
};

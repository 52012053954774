import { IntegrationsApi } from '@rentcheck/api-frontend';
import { ApiRentManagerIntegration } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

export const useRentManagerIntegrations = () => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const [rentManagerIntegrations, setRentManagerIntegrations] = useState<
		ApiRentManagerIntegration[]
	>([]);
	const [loadingRentManagerIntegration, setLoadingRentManagerIntegration] =
		useState(true);

	useEffect(() => {
		if (!subscription) {
			setLoadingRentManagerIntegration(false);
			return;
		}

		setLoadingRentManagerIntegration(true);

		IntegrationsApi.RentManager.get()
			.then((data) => setRentManagerIntegrations(data))
			.catch(() => dispatch(SnackbarActions.showError()))
			.finally(() => setLoadingRentManagerIntegration(false));
	}, [subscription, dispatch]);

	return {
		loading: loadingRentManagerIntegration,
		rentManagerIntegrations: rentManagerIntegrations || [],
	};
};

import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Sheet Name',
	description: 'Sheet Description',
	slug: 'sheet_slug',

	fields: [
		{
			key: 'category',
			type: 'string',
			label: 'Category',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'sub_category',
			type: 'string',
			label: 'Sub-Category',
		},
		{
			key: 'is_default',
			type: 'boolean',
			label: 'Is Default',
		},
	],

	actions: undefined,
};

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { LoadingButton } from '@mui/lab';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Link,
	TextField,
	Typography,
} from '@mui/material';
import { IntegrationsApi } from '@rentcheck/api-frontend';
import { Column, DialogTitle, Spacer } from 'components';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions, SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';

import Success from '../../../common/install-success-modal';

import HeaderIcons from './header-icons';

interface Props {
	open: boolean;
	handleClose: () => void;
}

const InstallAppFolioModal = ({ open, handleClose }: Props) => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const [companyCode, setCompanyCode] = useState('');

	const [companyCodeError, setCompanyCodeError] = useState('');

	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	useEffect(() => {
		setCompanyCode('');
		setCompanyCodeError('');

		setLoading(false);
	}, [open]);

	useEffect(() => {
		setCompanyCodeError('');
	}, [companyCode]);

	const validateForm = () => {
		let formIsValid = true;

		if (!companyCode) {
			setCompanyCodeError('Company Code is required.');
			formIsValid = false;
		}

		return formIsValid;
	};

	const handleConnect = () => {
		if (!profile || !subscription) {
			return;
		}

		if (!validateForm()) {
			return;
		}

		setLoading(true);

		IntegrationsApi.RentManager.create({ corp_id: companyCode })
			.then(() => {
				setSuccess(true);
				dispatch(SubscriptionActions.getSubscription());
			})
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	};

	const handleCloseSuccess = () => {
		handleClose();
		setSuccess(false);
	};

	if (success) {
		return (
			<Success
				vendor="Rent Manager"
				parentUrl="/account/integrations/rentmanager"
				handleClose={handleCloseSuccess}
				waitForConnection={true}
			/>
		);
	}

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Connect Rent Manager to RentCheck"
				icon={solid('plug')}
				onClose={handleClose}
			/>

			<DialogContent>
				<Column>
					<HeaderIcons />
					<Spacer height={16} />
					<Typography variant="subtitle2">
						Enter the Rent Manager company code to connect Rent Manager to
						RentCheck.{' '}
						<Link
							target="_blank"
							href="https://help.getrentcheck.com/en/articles/5666058-rent-manager-integration">
							Learn More
						</Link>
					</Typography>
					<Spacer height={4} />
					<TextField
						variant="filled"
						label="Company Code"
						placeholder="Enter your company code here"
						value={companyCode}
						onChange={(e) => setCompanyCode(e.target.value)}
						error={!!companyCodeError}
						helperText={companyCodeError || 'Required'}
					/>
				</Column>
			</DialogContent>

			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleClose}>
					Cancel
				</Button>

				<LoadingButton
					variant="contained"
					onClick={handleConnect}
					loading={loading}>
					Install
				</LoadingButton>
			</DialogActions>
		</Dialog>
	);
};

export default InstallAppFolioModal;

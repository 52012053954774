"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canViewProfileButtonOption = void 0;
const utils_1 = require("../../utils");
const canViewProfileButtonOption = (profile, subscription) => {
    return (!utils_1.Users.isRenter(profile) &&
        !utils_1.Subscriptions.isCancelled(subscription) &&
        !utils_1.Subscriptions.isPaused(subscription));
};
exports.canViewProfileButtonOption = canViewProfileButtonOption;

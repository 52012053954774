export const ordinalPrefixes = [
	'First',
	'Second',
	'Third',
	'Fourth',
	'Fifth',
	'Sixth',
	'Seventh',
	'Eighth',
	'Ninth',
];

export const PROFILE_USER_TYPES = {
	renter: 'Renter',
	landlord: 'Landlord',
	propertyManager: 'Property Manager',
};

export const RoomNames = {
	main: [
		'Kitchen',
		'Dining Room',
		'Living Room',
		'Family Room',
		'Den',
		'Office',
		'Laundry Room',
		'Basement',
		'Loft',
		'Attic',
		'Storage Room',
	],
	common: ['Hallway', 'Stairway'],
	outside: ['Garage', 'Carport', 'Patio', 'Balcony', 'Exterior', 'Pool'],
	building: [
		'Courtyard(s)',
		'Patio(s)',
		'Playground Area(s)',
		'Shared Laundry Room(s)',
		'Lobby',
		'Exercise Room(s)',
		'Roof Deck',
		'Pool(s)',
		'Mail / Package / Storage Area',
		'Maintenance Shop',
	],
	community: [
		'Exterior Signage',
		'Exterior Grounds',
		'Roof & Gutters',
		'Building Doors',
		'Fencing & Gates',
		'Exterior Trash Area(s)',
		'Playground Area(s)',
		'Fitness Center',
		'Club Room',
		'Recreational Facilities',
		'Pool(s)',
		'Courtyard(s)',
		'Patio(s)',
		'Porches',
		'Balconies',
		'Roof Deck',
		'Exterior Walkways',
		'Exterior Lighting',
		'Exterior Stairs & Railings',
		'Parking Areas',
		'Building Exterior(s)',
		'Boiler Room',
		'Shared Laundry Room(s)',
		'Mail / Package / Storage Area',
		'Maintenance Shop',
	],
};

export const RENTCHECK_INSPECTION_FILTERS = 'rentcheckInspectionFilters';
export const RENTCHECK_INSPECTION_GLOBAL_FILTERS =
	'rentcheckInspectionGlobalFilters';

export const RENTCHECK_PROPERTY_FILTERS = 'rentcheckPropertyFilters';
export const RENTCHECK_PROPERTY_GLOBAL_FILTERS =
	'rentcheckPropertyGlobalFilters';

export const EMAIL_REGEX =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const paywallLimit = 1;

import { ApiMaintenanceReport } from '@rentcheck/types';
import firebase from 'config/firebase';
import { downloadCORSInhibitedBlob, formatDate } from 'utils/helpers';

export const maintenanceReport = async (
	report: ApiMaintenanceReport
): Promise<void> => {
	const formattedDate = formatDate(report.created_date, 'M-D-YYYY');

	const paths = report.maintenance_flags.reduce(
		(acc: string[], curr) => [
			...acc,
			...[...(curr.image_urls ?? []), ...(curr.video_urls ?? [])],
		],
		[]
	);

	const namePrefixes = report.maintenance_flags.reduce(
		(acc: string[], curr) => [
			...acc,
			...[...(curr.image_urls ?? []), ...(curr.video_urls ?? [])].map(
				(_, index) => `${curr.feature} - ${formattedDate} - ${index + 1}`
			),
		],
		[]
	);

	const region = 'us-central1';
	const projectId = (firebase.app().options as { projectId: string }).projectId;

	return downloadCORSInhibitedBlob(
		`https://${region}-${projectId}.cloudfunctions.net/imagesCreateZipFromUrls`,
		`${report.name} - ${formattedDate}.zip`,
		{ paths, namePrefixes }
	);
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../../request';
import { millisToString } from '../../utils/filters';
const defaultFilters = {
    search: '',
    teamId: [],
    buildingId: [],
    inspectionType: [],
};
const defaultSorting = {
    sortBy: 'address',
    sortType: 'asc',
};
export const getAll = (pageNumber = 0, pageSize = 20, filters = defaultFilters, sorting = defaultSorting, config) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    return request({
        method: 'get',
        version: 'v2',
        url: '/properties/units',
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            // filters
            search: filters.search,
            building_id: filters.buildingId,
            team_id: filters.teamId,
            last_completed_inspection_start_date: millisToString((_a = filters.dateRange) === null || _a === void 0 ? void 0 : _a.start),
            last_completed_inspection_end_date: millisToString((_b = filters.dateRange) === null || _b === void 0 ? void 0 : _b.end),
            // sorting
            sort_by: sorting === null || sorting === void 0 ? void 0 : sorting.sortBy,
            sort_type: sorting === null || sorting === void 0 ? void 0 : sorting.sortType,
        },
        appConfig: config,
    }).then((value) => {
        const data = value.data.data;
        const totalResults = value.data.total_results;
        return { data, totalResults };
    });
});
export const getById = (id, config) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/units/${id}`,
        appConfig: config,
    }).then((value) => value.data.data);
});

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.community = exports.building = exports.unit = exports.ordinalPrefixes = void 0;
exports.ordinalPrefixes = [
    'First',
    'Second',
    'Third',
    'Fourth',
    'Fifth',
    'Sixth',
    'Seventh',
    'Eighth',
    'Ninth',
];
exports.unit = {
    main: [
        'Kitchen',
        'Dining Room',
        'Living Room',
        'Family Room',
        'Den',
        'Office',
        'Laundry Room',
        'Basement',
        'Loft',
        'Attic',
        'Storage Room',
    ],
    common: ['Hallway', 'Stairway'],
    outside: ['Garage', 'Carport', 'Patio', 'Balcony', 'Exterior', 'Pool'],
};
exports.building = {
    default: [
        'Courtyard(s)',
        'Patio(s)',
        'Playground Area(s)',
        'Shared Laundry Room(s)',
        'Lobby',
        'Exercise Room(s)',
        'Roof Deck',
        'Pool(s)',
        'Mail / Package / Storage Area',
        'Maintenance Shop',
    ],
};
exports.community = {
    default: [
        'Exterior Signage',
        'Exterior Grounds',
        'Roof & Gutters',
        'Building Doors',
        'Fencing & Gates',
        'Exterior Trash Area(s)',
        'Playground Area(s)',
        'Fitness Center',
        'Club Room',
        'Recreational Facilities',
        'Pool(s)',
        'Courtyard(s)',
        'Patio(s)',
        'Porches',
        'Balconies',
        'Roof Deck',
        'Exterior Walkways',
        'Exterior Lighting',
        'Exterior Stairs & Railings',
        'Parking Areas',
        'Building Exterior(s)',
        'Boiler Room',
        'Shared Laundry Room(s)',
        'Mail / Package / Storage Area',
        'Maintenance Shop',
    ],
};

import { LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import firebase from 'firebase/app';
import { useEffect, useState } from 'react';
import { clarity } from 'react-microsoft-clarity';
import { BrowserRouter } from 'react-router-dom';
import { createTheme } from 'theme';

import AuthController from 'screens/auth-controller';
import NavBar from 'screens/nav-bar';

import Routes from 'routes';

import ModalFlowsController from 'screens/modal-flows-controller';
import SnackbarController from 'screens/snackbar-controller';

import MaintenanceNotice from 'components/maintenance-notice';
import ImpersonateNotice from 'screens/account/admin/impersonate/notice';

import ModalBlockers from 'screens/modal-blockers';

import { APIConfig } from '@rentcheck/api-frontend';

import { useUserSettings } from 'hooks/user-settings';
import 'react-modern-calendar-datepicker/lib/DatePicker.css';
import { IntercomProvider } from 'react-use-intercom';
import Analytics from 'screens/analytics';
import TasksDrawer from 'screens/tasks-drawer';
import styled from 'styled-components';
import { isEmbeddedInMobileApp } from 'utils/helpers';

/**
 * Initialize Microsoft Clarity only on prod
 */
if (process.env.REACT_APP_FIREBASE_PROJECT_ID === 'imperent-699e4') {
	clarity.init(process.env.REACT_APP_CLARITY_PROJECT_ID);
}

/**
 * API Initialization
 */
APIConfig.setAppId(process.env.REACT_APP_API_X_APP_ID);
APIConfig.setAppSecret(process.env.REACT_APP_API_X_APP_SECRET);
APIConfig.setBaseUrl(process.env.REACT_APP_API_BASE_URL);
// @ts-expect-error
APIConfig.setAuthProvider(firebase);

const App = () => {
	/**
	 * We're not using this hook yet, but we're initializing it here so it's
	 * available for use anywhere in the app with the correct data pre-loaded
	 */
	useUserSettings();

	const [defaultMaterialTheme] = useState(createTheme());

	useEffect(() => {
		const remoteConfig = firebase.remoteConfig();
		remoteConfig.settings.minimumFetchIntervalMillis = 43200000;
		remoteConfig.defaultConfig = {
			chargebee_site: 'getrentcheck',
			maintenance_settings_enabled: false,
			chargebee_url:
				'https://site_api_key:test_7vS9F21yTGjoG1iAekAIgPWsBdnHNB5U@getrentcheck-test.chargebee.com',
		};
		remoteConfig
			.fetch()
			.then(() => remoteConfig.activate())
			.catch((e) => Sentry.captureException(e));
	}, []);

	return (
		<BrowserRouter>
			<StyledEngineProvider injectFirst>
				<LocalizationProvider dateAdapter={AdapterDateFns}>
					<ThemeProvider theme={defaultMaterialTheme}>
						<IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
							<AppContainer>
								<AuthController />
								<Analytics />
								<NavBar />
								<ImpersonateNotice />
								<MaintenanceNotice />
								<ModalFlowsController />
								<SnackbarController />
								<ModalBlockers />
								<TasksDrawer />
								<Routes />
							</AppContainer>
						</IntercomProvider>
					</ThemeProvider>
				</LocalizationProvider>
			</StyledEngineProvider>
		</BrowserRouter>
	);
};

const AppContainer = styled.div`
	margin-top: ${isEmbeddedInMobileApp() ? '0' : '56px'};
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export default App;

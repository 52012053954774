"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canAccess = void 0;
/**
 * Simply verifies if the user is an internal admin or not.
 *
 * @param caller user trying to access.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canAccess = (caller) => {
    // TODO: use utils to validate this (under REN-3955)
    return !!(caller === null || caller === void 0 ? void 0 : caller.internal_admin);
};
exports.canAccess = canAccess;

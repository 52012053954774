import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { regular, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Checkbox,
	Chip,
	InputAdornment,
	Menu,
	MenuItem,
	SxProps,
	TextField,
	Theme,
	Typography,
} from '@mui/material';
import { Column } from 'components/layout/Column';
import _ from 'lodash';
import { useRef, useState } from 'react';
import { colors } from 'theme';

export type FilterPropertyType = 'Units' | 'Buildings';

export type ChipMenuValue<T, Multiple> = Multiple extends undefined | false
	? T
	: Array<T>;

interface Props<T, Multiple extends boolean | undefined = undefined> {
	/**
	 * An optional title to be displayed above the menu.
	 */
	title?: string;

	/**
	 * Array of options.
	 */
	options: ReadonlyArray<T>;

	/**
	 * The value of the autocomplete.
	 *
	 * The value must have reference equality with the option in order to be selected.
	 * You can customize the equality behavior with the `isOptionEqualToValue` prop.
	 */
	value: ChipMenuValue<T, Multiple>;

	/**
	 * Callback fired when the value changes.
	 *
	 * @param {T} value The new value of the component.
	 */
	setValue: (value: ChipMenuValue<T, Multiple>) => void;

	/**
	 * Used to determine the string value for a given option.
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionLabel?: (option: T) => string;

	/**
	 * Used to determine the icon value for the selection option/s.
	 *
	 * @param {ChipMenuValue<T, Multiple>} option
	 * @returns {IconDefinition}
	 */
	getChipIcon?: (option: ChipMenuValue<T, Multiple>) => IconDefinition;

	/**
	 * Used to determine the label for the chip based on selection option/s.
	 *
	 * @param {ChipMenuValue<T, Multiple>} option
	 * @returns {string}
	 */
	getChipLabel?: (option: ChipMenuValue<T, Multiple>) => string;

	/**
	 * Used to determine if the option represents the given value.
	 * Uses strict equality by default.
	 * ⚠️ Both arguments need to be handled, an option can only match with one value.
	 *
	 * @param {T} option The option to test.
	 * @param {T} value The value to test against.
	 * @returns {boolean}
	 */
	isOptionEqualToValue?: (option: T, value: T) => boolean;

	/**
	 * The system prop that allows defining system overrides as well as additional CSS styles.
	 */
	sx?: SxProps<Theme>;

	/**
	 * If `true`, a search bar will be displayed above the menu items and options will be filtered
	 * based on user input, filtering is done by comparing the `option` itself if it's a string or
	 * label if it's not
	 * @default false
	 */
	searchable?: boolean;

	/**
	 * If `true`, `value` must be an array and the menu will support multiple selections.
	 * @default false
	 */
	multiple?: Multiple;
}

function ChipMenu<T, Multiple extends boolean | undefined>({
	title,
	value,
	searchable,
	setValue,
	getOptionLabel,
	getChipIcon,
	getChipLabel,
	isOptionEqualToValue,
	options,
	sx,
	multiple,
}: Props<T, Multiple>) {
	const chipRef = useRef<HTMLDivElement>(null);

	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const [searchFilter, setSearchFilter] = useState('');

	const icon = getChipIcon?.(value);

	const handleClick = () => {
		setAnchorEl(chipRef.current);
	};

	const handleCloseMenu = () => {
		setAnchorEl(null);
		setSearchFilter('');
	};

	const handleOptionClicked = (option: T) => {
		if (multiple) {
			setValue(_.xor(value as T[], [option]) as ChipMenuValue<T, Multiple>);
		} else {
			setValue(option as ChipMenuValue<T, Multiple>);
			setAnchorEl(null);
		}
	};

	const isOptionSelected = (option: T) => {
		if (!multiple) {
			return isOptionEqualToValue
				? isOptionEqualToValue(option, value as T)
				: false;
		}

		return (value as Array<T>).some((v) =>
			isOptionEqualToValue ? isOptionEqualToValue(option, v) : option === v
		);
	};

	const filteredOptions =
		searchable && searchFilter.length
			? options.filter((o) => {
					return (getOptionLabel ? getOptionLabel(o) : (o as string))
						.toLowerCase()
						.includes(searchFilter.toLowerCase());
				})
			: options;

	return (
		<>
			<Chip
				ref={chipRef}
				label={getChipLabel ? getChipLabel(value) : (value as string)}
				icon={icon ? <FontAwesomeIcon icon={icon} /> : undefined}
				sx={{
					marginBottom: 2,
					backgroundColor: '#E9E8FD',

					'.MuiChip-label': { padding: 2 },
					'.MuiChip-icon': {
						color: colors.primary,
					},

					'.MuiChip-deleteIcon': {
						color: '#00000099',
						fontSize: 15,
					},

					':hover': {
						backgroundColor: '#d4d2fb',
					},

					...sx,
				}}
				onDelete={handleClick}
				onClick={handleClick}
				deleteIcon={
					<FontAwesomeIcon
						icon={solid('caret-down')}
						style={{ paddingRight: 4 }}
					/>
				}
			/>
			<Menu
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleCloseMenu}
				PaperProps={{
					style: {
						minWidth: searchable ? 344 : anchorEl?.offsetWidth,
					},
				}}
				anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
				transformOrigin={{ vertical: 'top', horizontal: 'left' }}>
				<Column>
					<Typography variant="overline" ml={2}>
						{title}
					</Typography>
					{searchable && (
						<TextField
							value={searchFilter}
							onChange={(e) => setSearchFilter(e.target.value)}
							color="secondary"
							placeholder="Search"
							sx={{
								ml: 2,
								mr: 2,
								mt: 1,

								input: {
									minHeight: 32,
									pt: 1,
									pb: 1,
								},
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<FontAwesomeIcon icon={regular('search')} />
									</InputAdornment>
								),
							}}
							onKeyDown={(e) => e.stopPropagation()}
						/>
					)}
				</Column>
				<Column style={{ maxHeight: 400, overflow: 'scroll' }}>
					{filteredOptions.map((option) => (
						<MenuItem
							sx={{ paddingLeft: 1 }}
							key={getOptionLabel ? getOptionLabel(option) : (option as string)}
							onClick={() => handleOptionClicked(option)}>
							{multiple && <Checkbox checked={isOptionSelected(option)} />}
							{getOptionLabel ? getOptionLabel(option) : (option as string)}
						</MenuItem>
					))}
				</Column>
			</Menu>
		</>
	);
}

export default ChipMenu;

import { useEffect, useState } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { PopoverMenu } from 'components';
import styled from 'styled-components';

import CreateBuildingModal from 'components/properties/CreateBuilding';
import CreateCommunityModal from 'components/properties/CreateCommunity';
import CreatePropertyModal from 'components/properties/CreateUnit';

import { APIProperty } from '@rentcheck/types';
import { spacingPx } from 'theme';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button } from '@mui/material';
import { PopoverItemProps } from 'components/PopoverMenu';
import { useDispatch } from 'react-redux';
import { ModalFlowActions } from 'store/actions';
import { hasActiveIntegration } from 'utils/integrations';

import { Rules } from '@rentcheck/biz';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import { useHistory } from 'react-router-dom';
import ActiveIntegrationDialog from './active-integration-dialog';

export type ActiveIntegration = 'AppFolio' | 'Rent Manager' | '';

export type IntegrationDialogOption = 'unit' | 'building' | '';

const PropertyUploadButton = () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);

	const loadingUnits = useTypedSelector(
		(state) => state.properties.units.loading
	);

	const loadingBuildings = useTypedSelector(
		(state) => state.properties.buildings.loading
	);

	const loadingCommunities = useTypedSelector(
		(state) => state.properties.communities.loading
	);

	const { loading: afLoading, appFolioIntegration } = useAppFolioIntegration();
	const { loading: rmLoading, rentManagerIntegrations } =
		useRentManagerIntegrations();

	const [createPropertyModalVisible, setCreatePropertyModalVisible] =
		useState(false);
	const [createBuildingModalVisible, setCreateBuildingModalVisible] =
		useState(false);
	const [createCommunityModalVisible, setCreateCommunityModalVisible] =
		useState(false);
	const [activeIntegration, setActiveIntegration] =
		useState<ActiveIntegration>('');
	const [integrationDialogOption, setIntegrationDialogOption] =
		useState<IntegrationDialogOption>('');

	useEffect(() => {
		if (!profile) return;

		hasActiveIntegration(subscription?.id).then((result) =>
			setActiveIntegration(result)
		);
	}, [profile, subscription]);

	const popoverItems = () => {
		const items: PopoverItemProps[] = [
			{
				title: 'Add new unit',
				onClick: () => {
					if (activeIntegration) {
						return setIntegrationDialogOption('unit');
					}

					setCreatePropertyModalVisible(true);
				},
			},
			{
				title: 'Add new building',
				onClick: () => {
					if (activeIntegration) {
						return setIntegrationDialogOption('building');
					}

					setCreateBuildingModalVisible(true);
				},
			},
			{
				title: 'Add new community',
				onClick: () => setCreateCommunityModalVisible(true),
			},
		];

		if (
			Rules.Properties.canImport(
				appFolioIntegration,
				rentManagerIntegrations,
				afLoading || rmLoading
			)
		) {
			items.push({
				title: 'Upload units',
				onClick: () => history.push('/account/imports/unit'),
				planGateProps: {
					variant: 'any',
				},
			});
		}

		return items;
	};

	const onCreateProperty = (property: APIProperty) => {
		setCreatePropertyModalVisible(false);
		setCreateBuildingModalVisible(false);
		setCreateCommunityModalVisible(false);

		dispatch(
			ModalFlowActions.showCreateInspectionModal({
				properties: [property as any],
				property_type: flowTypeFromProperty(property),
			})
		);
	};

	const flowTypeFromProperty = (property?: APIProperty) => {
		if (property?.property_type === 'Building') return 'building';
		if (property?.property_type === 'Community') return 'community';
		return 'unit';
	};

	const renderModals = () => {
		return (
			<>
				{createPropertyModalVisible && (
					<CreatePropertyModal
						setModalVisible={setCreatePropertyModalVisible}
						creationCallback={onCreateProperty}
					/>
				)}
				{createCommunityModalVisible && (
					<CreateCommunityModal
						setModalVisible={setCreateCommunityModalVisible}
						creationCallback={onCreateProperty}
					/>
				)}
				{createBuildingModalVisible && (
					<CreateBuildingModal
						setModalVisible={setCreateBuildingModalVisible}
						creationCallback={onCreateProperty}
					/>
				)}
			</>
		);
	};

	const handleCloseForIntegrationDialog = () => {
		setIntegrationDialogOption('');
	};

	const handleContinueForIntegrationDialog = () => {
		handleCloseForIntegrationDialog();

		if (integrationDialogOption === 'unit') {
			setCreatePropertyModalVisible(true);
		}

		if (integrationDialogOption === 'building') {
			setCreateBuildingModalVisible(true);
		}
	};

	return (
		<>
			<PopoverMenu
				disabled={loadingUnits || loadingBuildings || loadingCommunities}
				triggerNode={
					<Button
						disabled={loadingUnits || loadingBuildings || loadingCommunities}
						endIcon={<RightIcon icon={solid('sort-down')} />}>
						New Property
					</Button>
				}
				options={popoverItems()}
				width={50}
			/>
			{renderModals()}

			<ActiveIntegrationDialog
				dialogOption={integrationDialogOption}
				activeIntegration={activeIntegration}
				handleClose={handleCloseForIntegrationDialog}
				handleContinue={handleContinueForIntegrationDialog}
			/>
		</>
	);
};

const RightIcon = styled(FontAwesomeIcon)`
	margin-bottom: ${spacingPx(2)};
`;

export default PropertyUploadButton;

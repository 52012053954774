import {
	Dialog,
	DialogActions,
	DialogContent,
	Typography,
} from '@mui/material';
import { useDispatch } from 'react-redux';

import { Utils } from '@rentcheck/biz';
import assets from 'assets';
import { CenteredColumn, CenteredRow } from 'components';
import { Button } from 'components/button';
import { useIntercom } from 'react-use-intercom';
import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();
	const { showNewMessage } = useIntercom();

	const subscription = useTypedSelector((state) => state.subscription);

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleGetHelp = () => {
		showNewMessage();
		handleClose();
	};

	if (!subscription) {
		return null;
	}

	const planArchetype = Utils.Subscriptions.isOnProPlan(subscription)
		? 'Professional'
		: Utils.Subscriptions.isOnEssentialsPlan(subscription)
			? 'Essential'
			: '';

	return (
		<Dialog open={open} onClose={handleClose}>
			<CenteredRow style={{ marginTop: 40, marginBottom: 24 }}>
				<img
					src={assets.success.upgradePlan}
					alt="Plan Upgraded"
					style={{ maxWidth: 580 }}
				/>
			</CenteredRow>
			<DialogContent>
				<CenteredColumn>
					<Typography variant="h6" align="center">
						Exciting times ahead!
					</Typography>
					<Typography align="center" color="#4d4d4d" mt={2}>
						Welcome to your RentCheck {planArchetype} plan — now you've unlocked
						all the advanced tools to streamline your inspections and keep
						renting fair and transparent for everyone.
						<br />
						<br />
						Dive into powerful features that make property management a breeze.
					</Typography>
				</CenteredColumn>
			</DialogContent>
			<DialogActions>
				<Button variant="text" color="secondary" onClick={handleGetHelp}>
					Get Help
				</Button>
				<Button onClick={handleClose}>Get Started</Button>
			</DialogActions>
		</Dialog>
	);
};

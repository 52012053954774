"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.userTypes = exports.membershipRoleTypes = void 0;
exports.membershipRoleTypes = ['admin', 'manager', 'none'];
exports.userTypes = [
    'Renter',
    'Property Manager',
    'Landlord',
    'System',
];

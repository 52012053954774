import { ImportsApi } from '@rentcheck/api-frontend';
import { CreateImportParams } from '@rentcheck/types';
import { Dispatch } from 'types';

export const defaultPageSize = 20;

export const getAll =
	(pageNumber: number = 0, pageSize: number = defaultPageSize) =>
	async (dispatch: Dispatch) => {
		const result = await ImportsApi.getAll(pageNumber, pageSize);

		if (pageNumber === 0) {
			dispatch({
				type: 'FETCHED_IMPORTS',
				value: result.data,
			});
		} else {
			dispatch({
				type: 'FETCHED_IMPORTS_PAGE',
				value: result.data,
			});
		}

		return result;
	};

export const create =
	(payload: CreateImportParams['data']) => async (dispatch: Dispatch) => {
		const result = await ImportsApi.create(payload);

		dispatch({
			type: 'ADD_IMPORT',
			value: result,
		});

		return result;
	};

export const refresh = (id: string) => async (dispatch: Dispatch) => {
	const result = await ImportsApi.get(id);

	dispatch({
		type: 'UPDATE_IMPORT',
		value: result,
	});

	return result;
};

import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Status',
			type: 'string',
			label: 'Status',
		},
		{
			key: 'Phone Numbers',
			type: 'string',
			label: 'Phone Numbers',
		},
		{
			key: 'Emails',
			type: 'string',
			label: 'Emails',
		},
		{
			key: 'Move-in',
			type: 'string',
			label: 'Move-in',
		},
		{
			key: 'Lease To',
			type: 'string',
			label: 'Lease To',
		},
		{
			key: 'Rent',
			type: 'number',
			label: 'Rent',
		},
		{
			key: 'Deposit',
			type: 'number',
			label: 'Deposit',
		},
		{
			key: 'Tenant Street Address 1',
			type: 'string',
			label: 'Tenant Street Address 1',
		},
		{
			key: 'Tenant Street Address 2',
			type: 'string',
			label: 'Tenant Street Address 2',
		},
		{
			key: 'Tenant City',
			type: 'string',
			label: 'Tenant City',
		},
		{
			key: 'Tenant Zip',
			type: 'string',
			label: 'Tenant Zip',
		},
		{
			key: 'Lease From',
			type: 'string',
			label: 'Lease From',
		},
		{
			key: 'Move-out',
			type: 'string',
			label: 'Move-out',
		},
		{
			key: 'First Name',
			type: 'string',
			label: 'First Name',
		},
		{
			key: 'Last Name',
			type: 'string',
			label: 'Last Name',
		},
		{
			key: 'Unit ID',
			type: 'string',
			label: 'Unit ID',
		},
	],

	actions: undefined,
};

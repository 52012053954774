"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInProgress = void 0;
/**
 * Determines wether or not a file request is in progress based on the status
 * @param fileRequest the file request object
 * @returns true if the file request is in progress, false otherwise
 */
const isInProgress = (fileRequest) => {
    const completedStatuses = ['DONE', 'ERROR'];
    if (completedStatuses.includes(fileRequest.status)) {
        return false;
    }
    return true;
};
exports.isInProgress = isInProgress;

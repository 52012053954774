"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildMultiValueFilter = void 0;
const QueryParams = __importStar(require("../query-params"));
const buildMultiValueFilter = ({ queryStringParameters, filterKeyValue, filterKeyCondition, multiValueQueryStringParameters, legacyFilterKey, }) => {
    const newFilterValue = queryStringParameters[filterKeyValue];
    const newFilterCondition = queryStringParameters[filterKeyCondition];
    if ((newFilterValue === null || newFilterValue === void 0 ? void 0 : newFilterValue.length) || (newFilterCondition === null || newFilterCondition === void 0 ? void 0 : newFilterCondition.length)) {
        return {
            value: QueryParams.stringToArray(newFilterValue),
            condition: newFilterCondition,
        };
    }
    // legacy filter schema
    if (multiValueQueryStringParameters === null || multiValueQueryStringParameters === void 0 ? void 0 : multiValueQueryStringParameters[legacyFilterKey]) {
        return {
            value: multiValueQueryStringParameters[legacyFilterKey],
            condition: 'any_of',
        };
    }
    return undefined;
};
exports.buildMultiValueFilter = buildMultiValueFilter;

import { Grid } from '@mui/material';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { Utils } from '@rentcheck/biz';
import { ApiInspectionTemplateDigest, ApiTeam } from '@rentcheck/types';

import { Button, PlanGate } from 'components';
import TemplateCard from 'components/template-card';
import BaseTemplateCard from 'components/template-card/base-card';
import { useStoreInspectionTemplates } from 'hooks/templates';
import {
	InspectionTemplatesActions,
	ModalFlowActions,
	SnackbarActions,
} from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { inspectionTemplateAddonIds } from 'utils/integrations';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PropertyTypeOption } from './common/types';
import TemplateCardActions from './template-card-actions';
import TemplatesSkeleton from './templates-skeleton';

interface Props {
	teams: ApiTeam[];
	propertyTypes: PropertyTypeOption[];
}

const propertyTypeToTemplatePropertyType = (
	propertyType: PropertyTypeOption
): ApiInspectionTemplateDigest['property_type'] => {
	switch (propertyType.value) {
		case 'Units':
			return 'unit';
		case 'Buildings':
			return 'building';
		case 'Communities':
			return 'community';
	}
};

export default ({ teams, propertyTypes }: Props) => {
	const history = useHistory();
	const dispatch = useDispatch<Dispatch>();
	const location = useLocation();

	const profile = useTypedSelector((state) => state.activeProfile);

	const modalState = useTypedSelector((state) => state.modalFlows);

	const { templates, loading } = useStoreInspectionTemplates({
		propertyTypes: propertyTypes.map(propertyTypeToTemplatePropertyType),
		teams,
		includeUnavailable: true,
	});

	useEffect(() => {
		if (!profile) {
			return;
		}

		dispatch(InspectionTemplatesActions.getAll(true)).catch((e) =>
			dispatch(SnackbarActions.showError(e))
		);
	}, [profile]);

	useEffect(() => {
		const components = location.pathname.split('/');

		const templateId = components[components.length - 1];
		const template = templates.find((t) => t.id === templateId);

		if (!template) {
			return;
		}

		if (inspectionTemplateModalAlreadyShowing()) {
			return;
		}

		dispatch(ModalFlowActions.showInspectionTemplateModal({ template }));
	}, [location.pathname, templates]);

	const inspectionTemplateModalAlreadyShowing = () =>
		modalState.modals.some(
			(modal) => modal.type === 'View Inspection Template'
		);

	const handleTemplateClicked = (template: ApiInspectionTemplateDigest) => {
		history.push(`/account/inspection-templates/${template.id}`);
	};

	const handleTryForFree = () => {
		dispatch(
			ModalFlowActions.showCreateInspectionTemplateModal({ mode: 'unit' })
		);
	};

	if (loading) {
		return <TemplatesSkeleton />;
	}

	const hasCustomTemplates =
		Utils.InspectionTemplates.hasCustomTemplates(templates);

	return (
		<Grid container columns={{ xs: 1, sm: 2, md: 3 }} spacing={2}>
			{!hasCustomTemplates && (
				<Grid item xs={1} sx={{ display: 'flex' }}>
					<PlanGate
						variant="professional"
						addonIds={inspectionTemplateAddonIds}>
						<BaseTemplateCard
							title="Custom Template"
							templateType="Unit, Building, and Community"
							description="Elevate inspections with custom templates tailored to your business. From photos to ratings, capture precise data for strategic decision-making."
							actions={<Button>Get Started</Button>}
							backgroundColor="#F6F5FF"
							hoverBackgroundColor="#e5e4ee"
							opacity={1}
							iconBackgroundColor="#2D3CE6"
							icon={
								<FontAwesomeIcon icon={solid('hand-sparkles')} color="white" />
							}
							onClick={handleTryForFree}
						/>
					</PlanGate>
				</Grid>
			)}
			{templates.map((t) => (
				<Grid item xs={1} sx={{ display: 'flex' }}>
					<TemplateCard
						template={t}
						onClick={() => handleTemplateClicked(t)}
						actions={<TemplateCardActions template={t} />}
					/>
				</Grid>
			))}
		</Grid>
	);
};

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch } from 'react-redux';

import { PopoverMenu } from 'components';
import { spacingPx } from 'theme';

import { ModalFlowActions } from 'store/actions';

import { Button } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { PopoverItemProps } from 'components/PopoverMenu';
import { useAppFolioIntegration } from 'hooks/appfolio-integration';
import { useRentManagerIntegrations } from 'hooks/rentmanager-integration';
import { useHistory } from 'react-router-dom';

export default () => {
	const dispatch = useDispatch();
	const history = useHistory();

	const { loading: afLoading, appFolioIntegration } = useAppFolioIntegration();
	const { loading: rmLoading, rentManagerIntegrations } =
		useRentManagerIntegrations();

	const canImportResidents = Rules.Residents.canImport(
		appFolioIntegration,
		rentManagerIntegrations,
		afLoading || rmLoading
	);

	if (!canImportResidents) {
		return (
			<Button
				startIcon={<AddIcon />}
				onClick={() => dispatch(ModalFlowActions.showResidentModal({}))}>
				Add Resident
			</Button>
		);
	}

	const popoverItems: PopoverItemProps[] = [
		{
			title: 'Add New Resident',
			onClick: () => dispatch(ModalFlowActions.showResidentModal({})),
		},
	];

	if (canImportResidents) {
		popoverItems.push({
			title: 'Upload Residents',
			onClick: () => history.push('/account/imports/resident'),
			planGateProps: {
				variant: 'any',
			},
		});
	}

	return (
		<>
			<PopoverMenu
				triggerNode={
					<Button
						endIcon={
							<FontAwesomeIcon
								style={{ marginLeft: 8, marginBottom: spacingPx(2) }}
								icon={solid('sort-down')}
							/>
						}
						onClick={() => {}}>
						New Residents
					</Button>
				}
				options={popoverItems}
				width={50}
			/>
		</>
	);
};

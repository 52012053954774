import { request } from '../../request';
const remove = (ids) => {
    return request({
        method: 'delete',
        url: '/maintenance_reports',
        params: {
            id: ids,
        },
    }).then(() => {
        return;
    });
};
export default { remove };

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useRef, useState } from 'react';
import styled from 'styled-components';

import { Chip, Typography } from '@mui/material';
import { APIProperty } from '@rentcheck/types';
import { useSortedFilteredTenants } from 'hooks/residents';
import { colors, spacingPx } from 'theme';
import { isEmailValid } from 'utils/emails';
import { useOnClickOutside } from 'utils/hooks';
import { CenteredRow, Column, Spacer } from '../';

interface Props {
	placeholder?: string;
	property?: APIProperty;
	itemTyped: (email: string) => void;
	itemsTyped: (emails: string[]) => void;
	itemClicked: (email: string) => void;
	selectedEmails: string[];
	setInputIsValid?: (isValid: boolean) => void;
	showAddNewResidentMenu?: boolean;
	openAddNewResidentModal?: () => void;
	openAddResidentModal?: () => void;
}

const EmailSearchWithDropdown = ({
	placeholder = 'Add or Select Recipient',
	property,
	itemTyped,
	itemsTyped,
	itemClicked,
	selectedEmails,
	setInputIsValid,
	showAddNewResidentMenu,
	openAddNewResidentModal,
}: Props) => {
	const [validationMessage, setValidationMessage] = useState('');
	const [showDropdown, setShowDropdown] = useState(false);
	const [searchValue, setSearchValue] = useState('');

	const { tenants } = useSortedFilteredTenants({
		search: searchValue,
		active_only: true,
		propertyId: property?.id,
		email: undefined,
		sortBy: 'last_name',
		sortType: 'ASC',
	});

	const ref = useRef<HTMLDivElement>(null);

	useOnClickOutside(ref, () => {
		verifyEmailAndAddToList(searchValue);
		setShowDropdown(false);
	});

	if (!showDropdown) {
		return (
			<SearchBarContainer
				onClick={() => {
					setSearchValue('');
					setShowDropdown(true);
				}}>
				<Typography color={colors.gray}>{placeholder}</Typography>

				<FontAwesomeIcon icon={solid('search')} color={colors.gray} />
			</SearchBarContainer>
		);
	}

	const verifyEmailAndAddToList = (
		value: string,
		displaysValidationMessage: boolean = true
	) => {
		setValidationMessage('');

		if (!value) {
			return;
		}

		if (isEmailValid(value)) {
			setSearchValue('');

			if (value.includes(',')) {
				const values = value.split(',').map(function (item) {
					return item.trim();
				});
				itemsTyped(values);
			} else {
				itemTyped(value.replace(';', ''));
			}
		} else if (displaysValidationMessage) {
			setValidationMessage(
				'Please enter a valid email address. Separate multiple email addresses with a comma.'
			);
		}
	};

	return (
		<DropdownAnchor ref={ref}>
			{!!validationMessage && (
				<Typography color={colors.error}>{validationMessage}</Typography>
			)}
			<DropdownContent>
				<AutocompleteRow key="text-input">
					<AddressInput
						autoFocus
						className="browser-default"
						type="text"
						placeholder={placeholder}
						value={searchValue}
						onChange={(e) => {
							setSearchValue(e.target.value);
							if (setInputIsValid && isEmailValid(e.target.value)) {
								setInputIsValid(true);
							} else if (setInputIsValid) {
								setInputIsValid(false);
							}
						}}
						onKeyDown={(e) => {
							if (['Enter', ' ', ','].includes(e.key)) {
								verifyEmailAndAddToList(searchValue);
							}
						}}
						onBlur={() => {
							verifyEmailAndAddToList(searchValue, false);
						}}
					/>
					<FontAwesomeIcon icon={solid('search')} color={colors.gray} />
				</AutocompleteRow>
				<Divider />

				<Scroll>
					{showAddNewResidentMenu && (
						<AutocompleteRow
							key="add-resident"
							onClick={() => {
								setSearchValue('');
								openAddNewResidentModal && openAddNewResidentModal();
							}}>
							<AutocompleteContent hasBorder={false}>
								<Typography color={colors.primary}>
									+ Add New Resident Contact
								</Typography>
							</AutocompleteContent>
						</AutocompleteRow>
					)}
					{tenants.data.map((item, index) => {
						const itemIsSelected = selectedEmails.includes(item.emails[0]);
						const itemColor = itemIsSelected ? colors.gray : colors.black;

						const isCurrent =
							property &&
							item.primary_occupancy?.property.id === property.id &&
							item.primary_occupancy.status === 'current';

						const isFuture =
							property &&
							item.primary_occupancy?.property.id === property.id &&
							item.primary_occupancy.status === 'future';

						return (
							<AutocompleteRow
								key={item.id}
								onClick={() => {
									itemClicked(item.emails[0]);
									setSearchValue('');
								}}>
								<AutocompleteContent
									hasBorder={showAddNewResidentMenu || index !== 0}>
									<CenteredRow>
										{itemIsSelected && (
											<>
												<FontAwesomeIcon
													icon={solid('check')}
													color={itemColor}
												/>
												<Spacer width={2} />
											</>
										)}
										<Column>
											<Typography color={itemColor}>
												{item.first_name + ' ' + item.last_name}
											</Typography>
											<Typography color={itemColor}>
												{item.emails.join(', ')}
											</Typography>
										</Column>
									</CenteredRow>
									<Row>
										{isCurrent && <Chip label="Current" />}
										{isCurrent && isFuture && <Spacer width={2} />}
										{isFuture && <Chip label="Future" />}
									</Row>
								</AutocompleteContent>
							</AutocompleteRow>
						);
					})}
				</Scroll>
			</DropdownContent>
		</DropdownAnchor>
	);
};

const Row = styled.div`
	display: flex;
	flex-direction: row;
`;

const SpacedRow = styled(Row)`
	justify-content: space-between;
`;

const SearchBarContainer = styled(SpacedRow)`
	flex: 1;
	height: ${spacingPx(14)};
	padding: 0 ${spacingPx(6)} 0 ${spacingPx(6)};
	align-items: center;
	border: 1px solid #e6eef0;
	cursor: text;
	border-radius: ${spacingPx(2)};
`;

const DropdownAnchor = styled(Column)`
	width: 100%;
	height: ${spacingPx(14)};
	flex: 1;
`;

const AutocompleteRow = styled(Row)`
	height: ${spacingPx(14)};
	padding: 0 ${spacingPx(6)} 0 ${spacingPx(6)};
	align-items: center;
	justify-content: space-between;
	cursor: pointer;
`;

const AutocompleteContent = styled(SpacedRow)<{ hasBorder?: boolean }>`
	flex: 1;
	height: 100%;
	${({ hasBorder }) =>
		hasBorder ? `border-top: 1px solid ${colors.contentBorder}` : ``};
	align-items: center;
`;

const DropdownContent = styled(Column)`
	background-color: white;
	box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
	flex: 1;
	z-index: 1;
`;

const Divider = styled.div`
	width: 100%;
	height: 1px;
	background-color: ${colors.contentBorder};
`;

const Scroll = styled.div`
	overflow: auto;
	max-height: ${spacingPx(56)};
`;

const AddressInput = styled.input`
	height: ${spacingPx(10)};
	outline: none;
	font-weight: 500;
	line-height: 1.45;
	border: 0;
	flex: 1;
`;

export default EmailSearchWithDropdown;

import { Button, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import { Row } from 'components/layout/Row';
import { colors, spacingPx } from 'theme';

interface Option {
	label: string;
	url?: string;
	onClick?: () => void;
	isDefault?: boolean;
	legacyLink?: string;
}

interface Props {
	options: Option[];
	large?: boolean;
	small?: boolean;
	baseRoute?: string;
	type?: string;
}

const TabBar = ({ options, large, small, baseRoute, type }: Props) => {
	const location = useLocation();

	const renderHeading = (option: Option, tabProps: {}) => {
		if (large) {
			return (
				<Typography variant="h5" style={{ ...tabProps }}>
					{option.label}
				</Typography>
			);
		}

		if (small) {
			return (
				<Typography variant="subtitle1" style={{ ...tabProps }}>
					{option.label}
				</Typography>
			);
		}

		return (
			<Typography variant="h6" style={{ ...tabProps }}>
				{option.label}
			</Typography>
		);
	};

	return (
		<Row>
			{options.map((o) => {
				const isSingleTab = options.length === 1;

				const isDefaultHightlighted =
					!!o.isDefault &&
					(location.pathname === baseRoute || location.pathname === '/');
				const highlighted =
					o.url === location.pathname || isDefaultHightlighted;

				const typeSelected = type === o.label;

				const tabProps = (bool: boolean) => {
					return {
						color: bool ? colors.black : colors.gray,
						lineHeight: 1,
					};
				};

				if (!baseRoute || !o.url) {
					return (
						<TabItem
							small={small}
							onClick={o.onClick}
							highlighted={typeSelected}
							isSingleTab={isSingleTab}>
							{renderHeading(o, tabProps(typeSelected))}
						</TabItem>
					);
				}

				return (
					<>
						<TabLink
							key={o.label}
							small={small ? 1 : 0}
							highlighted={highlighted ? 1 : 0}
							to={o.url}
							onClick={o.onClick}
							isSingleTab={isSingleTab}>
							{renderHeading(o, tabProps(highlighted))}
						</TabLink>
						{!!o.legacyLink && (
							<a
								href="https://help.getrentcheck.com/en/articles/7065181-scheduled-inspections"
								target="_blank"
								rel="noreferrer">
								<Button variant="text" sx={{ marginLeft: -3, padding: 1 }}>
									LEGACY
								</Button>
							</a>
						)}
					</>
				);
			})}
		</Row>
	);
};

// Passing numbers instead of booleans because react-router's Link will give you
// an error if you try to pass a boolean for a custom prop
const TabLink = styled(Link)<{
	highlighted: number;
	small?: number;
	isSingleTab: boolean;
}>`
	height: ${({ small }) => spacingPx(small ? 4 : 8)};
	margin-right: ${spacingPx(8)};
	cursor: pointer;
	border-bottom: 4px solid
		${({ highlighted, isSingleTab }) =>
			highlighted && !isSingleTab ? colors.primary : colors.transparent};
`;

const TabItem = styled(Typography)<{
	highlighted: boolean;
	small?: boolean;
	isSingleTab: boolean;
}>`
	height: ${({ small }) => spacingPx(small ? 4 : 8)};
	margin-right: ${spacingPx(8)};
	cursor: pointer;
	border-bottom: 4px solid
		${({ highlighted, isSingleTab }) =>
			highlighted && !isSingleTab ? colors.primary : colors.transparent};
`;

export default TabBar;

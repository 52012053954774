import reactotronApiSauce from 'reactotron-apisauce';
import Reactotron from 'reactotron-react-js';
import { reactotronRedux } from 'reactotron-redux';

const reactotron = Reactotron.configure({ name: 'RentCheck Web App' })
	.use(reactotronRedux())
	.use(reactotronApiSauce());

if (
	process.env.REACT_APP_FIREBASE_PROJECT_ID === 'web-console-nonprod' ||
	window.location.hostname === 'localhost'
) {
	reactotron.connect();
	// @ts-expect-error
	reactotron.clear();

	// @ts-expect-error
	console.tron = reactotron;
	// @ts-expect-error
	console.log = reactotron.log;
} else {
	// @ts-expect-error
	console.tron = console;
}

export default reactotron;

import {
	Accordion as MuiAccordion,
	AccordionDetails as MuiAccordionDetails,
	AccordionSummary as MuiAccordionSummary,
	Typography,
} from '@mui/material';
import { ApiOccupancy, ApiTenant } from '@rentcheck/types';

import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useDispatch } from 'react-redux';

import { Column, Spacer } from 'components';
import {
	ModalFlowActions,
	SnackbarActions,
	TenantsActions,
} from 'store/actions';

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { OccupanciesApi } from '@rentcheck/api-frontend';
import _ from 'lodash';
import styled from 'styled-components';
import { Dispatch } from 'types';
import CreateOccupancy from './create';
import ListItem from './list-item';
import Skeleton from './skeleton';

interface Props {
	resident: ApiTenant;
}

export interface LeasesRef {
	create: () => void;
}

export default forwardRef<LeasesRef, Props>((props, ref) => {
	const { resident } = props;

	const dispatch: Dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [occupancies, setOccupancies] = useState<ApiOccupancy[]>([]);

	const [operation, setOperation] = useState<'create' | 'update'>();
	const [editingOccupancy, setEditingOccupancy] = useState<ApiOccupancy>();

	useEffect(() => {
		!occupancies.length && setLoading(true);

		OccupanciesApi.getForTenant(resident.id)
			.then(setOccupancies)
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => setLoading(false));
	}, [resident]);

	useImperativeHandle(ref, () => ({
		create: handleCreate,
	}));

	const handleCreate = () => {
		setOperation('create');
	};

	const handleEdit = (occupancy: ApiOccupancy) => {
		setEditingOccupancy(occupancy);
		setOperation('update');
	};

	const handleConfirmDelete = async (occupancy: ApiOccupancy) => {
		const progress = await dispatch(
			SnackbarActions.showProgress('Deleting Lease')
		);

		dispatch(TenantsActions.deleteOccupancy(occupancy.tenant_id, occupancy.id))
			.then(() => dispatch(SnackbarActions.showSuccess('Lease Deleted')))
			.catch((e) => dispatch(SnackbarActions.showError(e.message)))
			.finally(() => {
				dispatch(ModalFlowActions.closeConfirmationModal());
				dispatch(SnackbarActions.hideSnackbar(progress.id));
			});
	};

	const handleDelete = (occupancy: ApiOccupancy) => {
		dispatch(
			ModalFlowActions.showConfirmationModal({
				title: 'Are you sure?',
				body1: [
					'Are you sure you want to delete this lease?',
					'This action is not reversible',
				],
				body2: [],
				buttons: [
					ModalFlowActions.cancelButton(dispatch),
					{
						title: 'Delete',
						color: 'error',
						variant: 'contained',
						onClick: () => handleConfirmDelete(occupancy),
					},
				],
			})
		);
	};

	if (loading) {
		return <Skeleton />;
	}

	if (!occupancies.length) {
		return (
			<>
				<Spacer height={51.375} />
				{operation === 'create' && (
					<CreateOccupancy
						resident={resident}
						onClose={() => {
							setOperation(undefined);
							setEditingOccupancy(undefined);
						}}
					/>
				)}
			</>
		);
	}

	const groupedOccupancies = _.groupBy(occupancies, 'status');

	return (
		<>
			<Column>
				{!!groupedOccupancies['future'] && (
					<Accordion>
						<AccordionSummary
							expandIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
							<Typography variant="overline">Future Lease Details</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{groupedOccupancies['future'].map((o) => (
								<ListItem
									occupancy={o}
									onEdit={handleEdit}
									onDelete={handleDelete}
								/>
							))}
						</AccordionDetails>
					</Accordion>
				)}
				{!!groupedOccupancies['current'] && (
					<Accordion defaultExpanded>
						<AccordionSummary
							expandIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
							<Typography variant="overline">Current Lease Details</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{groupedOccupancies['current'].map((o) => (
								<ListItem
									occupancy={o}
									onEdit={handleEdit}
									onDelete={handleDelete}
								/>
							))}
						</AccordionDetails>
					</Accordion>
				)}
				{!!groupedOccupancies['past'] && (
					<Accordion>
						<AccordionSummary
							expandIcon={<FontAwesomeIcon icon={solid('caret-down')} />}>
							<Typography variant="overline">Past Lease Details</Typography>
						</AccordionSummary>
						<AccordionDetails>
							{groupedOccupancies['past'].map((o) => (
								<ListItem
									occupancy={o}
									onEdit={handleEdit}
									onDelete={handleDelete}
								/>
							))}
						</AccordionDetails>
					</Accordion>
				)}
			</Column>

			{operation === 'create' && (
				<CreateOccupancy
					resident={resident}
					onClose={() => {
						setOperation(undefined);
						setEditingOccupancy(undefined);
					}}
				/>
			)}

			{operation === 'update' && (
				<CreateOccupancy
					resident={resident}
					occupancy={editingOccupancy}
					onClose={() => {
						setOperation(undefined);
						setEditingOccupancy(undefined);
					}}
				/>
			)}
		</>
	);
});

const Accordion = styled(MuiAccordion)`
	box-shadow: none;
`;

const AccordionSummary = styled(MuiAccordionSummary)`
	padding: 0;
`;

const AccordionDetails = styled(MuiAccordionDetails)`
	padding: 0;
`;

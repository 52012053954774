var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import moment from 'moment';
import { request } from '../request';
const updateDueDate = (inspectionIds, dueDate, inviteDate) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'put',
        url: '/inspections',
        params: {
            id: inspectionIds,
        },
        data: {
            due_date: moment(dueDate).format('YYYY-MM-DD'),
            invite_date: inviteDate
                ? moment(inviteDate).format('YYYY-MM-DD')
                : undefined,
        },
    }).then((value) => value.data.data);
});
const updateRecipients = (inspectionId, recipients, notifyResidents) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'put',
        url: `/inspections/${inspectionId}`,
        data: { recipients, notify_residents: notifyResidents },
    }).then((value) => value.data.data);
});
const keepInspection = (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'patch',
        url: `/inspections/${inspectionId}/keep`,
    }).then((value) => value.data.data);
});
export default { updateDueDate, updateRecipients, keepInspection };

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from 'axios';
import QueryString from 'qs';
import APIConfig from '../config';
const axiosInstance = axios.create({
    paramsSerializer: (params) => {
        return QueryString.stringify(params, { arrayFormat: 'brackets' });
    },
});
const addUserIdToParams = (params) => {
    if (!params) {
        return { user_id: APIConfig.getUserId() };
    }
    if (params.user_id) {
        return params;
    }
    return Object.assign(Object.assign({}, params), { user_id: APIConfig.getUserId() });
};
export const request = ({ method, url, params, data, appConfig, version = 'v1', omitAuthorization, }) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    const headers = {
        'x-app-id': (_a = appConfig === null || appConfig === void 0 ? void 0 : appConfig.appId) !== null && _a !== void 0 ? _a : APIConfig.getAppId(),
        'x-app-secret': (_b = appConfig === null || appConfig === void 0 ? void 0 : appConfig.appSecret) !== null && _b !== void 0 ? _b : APIConfig.getAppSecret(),
        accept: 'application/json',
    };
    if (!omitAuthorization) {
        const currentUser = APIConfig.getAuthProvider().auth().currentUser;
        if (!currentUser) {
            return;
        }
        const authToken = yield currentUser.getIdToken();
        headers['authorization'] = `Bearer ${authToken}`;
    }
    const response = yield axiosInstance({
        method,
        data,
        params: addUserIdToParams(params),
        url: `${APIConfig.getBaseUrl()}/${version}${url}`,
        headers,
    }).catch((e) => {
        var _a, _b, _c, _d;
        const apiMessage = (_c = (_b = (_a = e.response) === null || _a === void 0 ? void 0 : _a.data) === null || _b === void 0 ? void 0 : _b.error) !== null && _c !== void 0 ? _c : (_d = e.response) === null || _d === void 0 ? void 0 : _d.error;
        if (apiMessage)
            e.message = apiMessage;
        throw e;
    });
    return response;
});

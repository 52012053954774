import { Typography } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { Feature, TemplateQuestion } from '@rentcheck/types';
import { useMemo } from 'react';

import { useEditFeatureModalData } from 'hooks/modals';

import FreeTextQuestion from './question-free-text';
import YesNoQuestion from './question-yes-no';

interface Props {
	feature: Feature;
	featurePayload: Partial<Feature>;
	setFeaturePayload: (payload: Partial<Feature>) => void;
}

type FilteredQuestion = TemplateQuestion & { originalIndex: number };

const Questions = ({ feature, featurePayload, setFeaturePayload }: Props) => {
	const modalData = useEditFeatureModalData();
	const { inspection } = modalData?.metadata ?? {};

	const filteredQuestions: FilteredQuestion[] = useMemo(() => {
		const questionsAttachedToLogic =
			Utils.InspectionTemplates.getQuestionIdsAttachedToLogic(
				inspection.inspection_template
			);

		return feature.questions
			.map((f, i) => ({ ...f, originalIndex: i }))
			.filter((question) => !questionsAttachedToLogic.includes(question.id));
	}, [inspection.inspection_template, feature]);

	if (feature.questions.length === 0) return null;

	if (!filteredQuestions.length) return null;

	return (
		<>
			<Typography variant="overline">Questions</Typography>

			{filteredQuestions.map((question) => {
				if (question.type === 'yes/no') {
					return (
						<YesNoQuestion
							index={question.originalIndex}
							question={question}
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>
					);
				}

				if (question.type === 'free text') {
					return (
						<FreeTextQuestion
							index={question.originalIndex}
							question={question}
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>
					);
				}

				if (question.type === 'asset capture') {
					return (
						<FreeTextQuestion
							index={question.originalIndex}
							question={question}
							featurePayload={featurePayload}
							setFeaturePayload={setFeaturePayload}
						/>
					);
				}

				return null;
			})}
		</>
	);
};

export default Questions;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canEdit = exports.canDelete = exports.canCreate = void 0;
const __1 = require("../..");
const common_1 = require("../common");
const canCreate = (user) => {
    if (__1.Utils.Users.isTeamAdmin(user)) {
        return true;
    }
    return false;
};
exports.canCreate = canCreate;
const canDelete = (totalPGs, numberToDelete, user) => {
    if (!user) {
        return {
            result: false,
            reason: (0, common_1.buildReason)('delete', 'permission group', 'you must be logged in'),
        };
    }
    if (!__1.Utils.Users.isTeamAdmin(user)) {
        return {
            result: false,
            reason: (0, common_1.buildReason)('delete', 'permission group', 'you must be an admin'),
        };
    }
    if (totalPGs === 1) {
        return {
            result: false,
            reason: "You can't delete the last permission group",
        };
    }
    if (totalPGs === numberToDelete) {
        return {
            result: false,
            reason: "You can't delete all permission groups",
        };
    }
    return { result: true, reason: '' };
};
exports.canDelete = canDelete;
const canEdit = (user) => {
    if (!user) {
        return {
            result: false,
            reason: (0, common_1.buildReason)('edit', 'permission group', 'you must be logged in'),
        };
    }
    if (!__1.Utils.Users.isTeamAdmin(user)) {
        return {
            result: false,
            reason: (0, common_1.buildReason)('edit', 'permission group', 'you must be an admin'),
        };
    }
    return { result: true, reason: '' };
};
exports.canEdit = canEdit;

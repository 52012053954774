"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canImport = void 0;
/**
 * Checks if the user is enabled to import residents.
 * The user must not have an AppFolio integration or RentManager integrations.
 *
 * @param appfolioIntegration AppFolio Integration for the user's account
 * @param rentmanagerIntegrations RentManager Integrations for the user's account
 * @param loading Optional loading state, if provided the function will return false until it's no longer loading
 * @returns `true` if the user can import residents, `false` otherwise
 */
const canImport = (appfolioIntegration, rentmanagerIntegrations, loading) => {
    if (loading)
        return false;
    if (appfolioIntegration)
        return false;
    if (rentmanagerIntegrations === null || rentmanagerIntegrations === void 0 ? void 0 : rentmanagerIntegrations.length)
        return false;
    return true;
};
exports.canImport = canImport;

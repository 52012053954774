import { Divider, Paper, Typography } from '@mui/material';

import { formatDateAndTime } from 'utils/helpers';
import { MappingType } from '../types';

interface Props {
	vendor: string;
	objectType: MappingType;
	date?: string;
	count: number;
	frequency?: number;
}

const SyncDetails = ({
	objectType,
	date,
	count,
	vendor,
	frequency = 24,
}: Props) => {
	if (objectType === 'Work Orders' || objectType === 'Service Issues') {
		return (
			<Paper elevation={0} sx={{ border: '1px solid #0000001F', padding: 2 }}>
				<Typography color="#232E3A">
					{objectType} are instantly synced and created in {vendor} as items are
					flagged in RentCheck.
				</Typography>
			</Paper>
		);
	}

	return (
		<Paper elevation={0} sx={{ border: '1px solid #0000001F', padding: 2 }}>
			<Typography variant="subtitle2">Last Sync:</Typography>
			<Typography color="#00000099" mt={1}>
				{date ? formatDateAndTime(date) : 'Never'}
			</Typography>

			<Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />

			<Typography variant="subtitle2">{objectType} synced:</Typography>
			<Typography color="#00000099" mt={1}>
				{count}
			</Typography>

			<Divider sx={{ marginTop: 1.5, marginBottom: 1.5 }} />

			<div style={{ lineHeight: 0 }}>
				<Typography variant="caption" color="#00000099">
					Sync runs every {frequency} hours.
				</Typography>
			</div>
		</Paper>
	);
};

export default SyncDetails;

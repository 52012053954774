import { Parser } from '@json2csv/plainjs';
import { DialogContent, Typography } from '@mui/material';
import { CenteredColumn, CenteredRow, LottieWrapper } from 'components';
import { useContext, useEffect } from 'react';
import styled from 'styled-components';

import { ImportOperation } from '@rentcheck/types';
import { StorageApi } from 'api';
import _ from 'lodash';
import { useDispatch } from 'react-redux';
import { ImportsActions, SnackbarActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import { FlatFileImportContext } from '../../../context';

export default () => {
	const dispatch = useDispatch<Dispatch>();
	const context = useContext(FlatFileImportContext);

	const subscription = useTypedSelector((state) => state.subscription);

	const createCSVFileAndUpload = async () => {
		if (!subscription) {
			throw new Error('Subscription not found');
		}

		const records = context.processedRecords;
		const data: Record<string, string | number | boolean>[] = _.map(
			records,
			(r) => _.mapValues(r.values, (v) => v.value)
		);

		const opts = {};
		const parser = new Parser(opts);
		const csv = parser.parse(data);

		const csvUri = await StorageApi.uploadImportCSV(csv, subscription.id);
		const downloadUrl = await StorageApi.getDownloadUrl(csvUri);

		return downloadUrl;
	};

	useEffect(() => {
		if (context.step !== 2) {
			return;
		}

		if (context.processedRecords.length === 0) {
			return;
		}

		createCSVFileAndUpload()
			.then((csvUrl) =>
				dispatch(
					ImportsActions.create({
						model: context.model,
						operation: context.operation as ImportOperation,
						source: context.pms,
						source_file_path: csvUrl,
					})
				)
			)
			.then(() => {
				context.setStep(3);
			})
			.then(() => {
				Analytics.trackEvent('flatfile_upload_complete', {
					subscription_id: subscription?.id,
					upload_type: context.model,
					operation: context.operation,
					source: context.pms,
				});
			})
			.catch((e) => dispatch(SnackbarActions.showError(e)));
	}, [context.step, context.processedRecords, context.model, subscription]);

	if (context.step !== 2) {
		return null;
	}

	return (
		<DialogContent>
			<AnimationContainer>
				<CenteredRow>
					<CenteredColumn
						style={{
							backgroundColor: '#F6F5FF',
							padding: '40px 60px',
							borderRadius: 8,
						}}>
						<LottieWrapper variant="dog-computing" width={160} height={110} />
						<Typography variant="subtitle1" textAlign="center">
							One moment please, we're
							<br />
							uploading your data...
						</Typography>
					</CenteredColumn>
				</CenteredRow>
			</AnimationContainer>
		</DialogContent>
	);
};

const AnimationContainer = styled.div`
	display: flex;
	height: 100%;

	align-items: center;
	justify-content: center;
`;

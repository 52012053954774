import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import { useEffect, useRef, useState } from 'react';

const animationVariants = [
	'sync-on',
	'dog-fetching',
	'dog-sleeping',
	'sparkles-white',
	'sparkles-blue',
	'dog-computing',
	'import-confetti',
] as const;

export type AnimationVariant = (typeof animationVariants)[number];

const baseBucketUrl =
	'https://rentcheck-web-public-assets.s3.us-east-2.amazonaws.com/animations';

const animationDataUrls: Record<AnimationVariant, string> = {
	'sync-on': baseBucketUrl + '/appfolio-sync-on.json',
	'dog-fetching': baseBucketUrl + '/dog-fetching.json',
	'dog-sleeping': baseBucketUrl + '/dog-sleeping.json',
	'sparkles-white': baseBucketUrl + '/sparkles-white.json',
	'sparkles-blue': baseBucketUrl + '/sparkles-blue.json',
	'dog-computing': baseBucketUrl + '/dog-computing.json',
	'import-confetti': baseBucketUrl + '/import-confetti.json',
};

const savedAnimationData: Record<string, any> = {};

export interface Props {
	variant: AnimationVariant;
	width: number;
	height: number;
	loop?: boolean;
	speed?: number;
}

export default ({
	variant,
	width,
	height,
	loop = true,
	speed = 1.0,
}: Props) => {
	const lottieRef = useRef<LottieRefCurrentProps>(null);

	const [animationData, setAnimationData] = useState<{} | undefined>(undefined);

	useEffect(() => {
		if (savedAnimationData[variant]) {
			setAnimationData(savedAnimationData[variant]);
			return;
		}

		// First set the animation data to undefined
		// in case we're re-using the component
		setAnimationData(undefined);

		// Download the animation data and save it to the cache
		fetch(animationDataUrls[variant])
			.then(async (response) => {
				savedAnimationData[variant] = await response.json();
				setAnimationData(savedAnimationData[variant]);
			})
			.catch(() => {});
	}, [variant]);

	useEffect(() => {
		lottieRef.current?.setSpeed(speed);
	}, [speed]);

	if (!animationData) {
		return <div style={{ width, height }} />;
	}

	return (
		<Lottie
			loop={loop}
			lottieRef={lottieRef}
			style={{ width, height }}
			animationData={animationData}
		/>
	);
};

import { ApiSubscription, ApiUser } from '@rentcheck/types';
import firebase from 'firebase/app';
import { useIntercom } from 'react-use-intercom';
import { isEmbeddedInMobileApp } from '../utils/helpers';

type OptionsType = Record<
	string,
	string | number | Date | undefined | string[]
>;

const sanitizeEventName = (name: string) =>
	name.toLowerCase().replace(/[^a-zA-Z0-9]/g, '_');

export const useInitializeProfile = () => {
	const { boot } = useIntercom();

	return (profile: ApiUser, subscription: ApiSubscription | null) => {
		const company = subscription?.rentcheck_contact?.company_id
			? {
					id: subscription?.rentcheck_contact?.company_id,
					name: subscription?.rentcheck_contact?.company,
					companyId: subscription?.rentcheck_contact?.company_id,
				}
			: undefined;

		boot({
			email: profile.email,
			name: profile.name,
			company,
			customAttributes: {
				app_id: process.env.REACT_APP_INTERCOM_APP_ID,
				user_id: profile.id,
				user_type: profile.type,
				company_name: subscription?.rentcheck_contact.company ?? 'not_set',
				total_properties: profile.total_properties,
				sub_id: subscription?.id ?? 'not_set',
				chargebee_sub_id: subscription?.plan.sub_id ?? 'not_set',
				chargebee_plan_id: subscription?.plan.plan_id ?? 'not_set',
				chargebee_plan_name: subscription?.plan.plan_name ?? 'not_set',
				chargebee_subscription_status: subscription?.status ?? 'not_set',
			},
		});

		firebase.analytics().setUserId(profile.id);
		firebase.analytics().setUserProperties(
			{
				user_type: profile.type,
				company_name: subscription?.rentcheck_contact.company ?? 'not_set',
				chargebee_sub_id: subscription?.plan.sub_id ?? 'not_set',
				chargebee_plan_id: subscription?.plan.plan_id ?? 'not_set',
				chargebee_plan_name: subscription?.plan.plan_name ?? 'not_set',
				chargebee_subscription_status: subscription?.status ?? 'not_set',
				chargebee_plan_quantity:
					subscription?.plan.plan_qty.toString() ?? 'not_set',
				chargebee_addon_ids:
					subscription?.addons?.map((addon) => addon.id).join(', ') ??
					'not_set',
			},
			{ global: true }
		);
	};
};

export const useLogOut = () => {
	return () => {
		firebase.analytics().setUserId('');
		firebase.analytics().setUserProperties(
			{
				user_type: '',
				company_name: '',
				chargebee_sub_id: '',
				chargebee_plan_id: '',
				chargebee_plan_quantity: '',
				chargebee_addon_ids: '',
			},
			{ global: true }
		);
	};
};

export const useKeepButtonHidden = () => {
	const { update } = useIntercom();
	return () => {
		update({
			hideDefaultLauncher: true,
		});
	};
};

export const useTrackEvent = () => {
	const { trackEvent } = useIntercom();

	return (name: string, options?: OptionsType) => {
		try {
			const sanitizedName = sanitizeEventName(name);
			const payload = {
				...(options ?? {}),
				platform: isEmbeddedInMobileApp() ? 'mobile' : 'web',
			};

			trackEvent(sanitizedName, payload);
			firebase.analytics().logEvent(sanitizedName, payload);
		} catch (e) {
			console.log('[trackEvent/error]', e);
		}
	};
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canImport = exports.canTransferAsAdmin = exports.canDelete = exports.canRestore = exports.canAccess = void 0;
const __1 = require("../..");
/**
 * Checks if user is an internal admin or its id/organization ids are within the
 * `property.authorized_user_ids`.
 *
 * @param property property to be evaluated.
 * @param user user that's calling the function.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canAccess = (property, user) => {
    var _a, _b;
    const userOrgIds = (_b = (_a = user.organizations) === null || _a === void 0 ? void 0 : _a.map((org) => org.id)) !== null && _b !== void 0 ? _b : [];
    const userOrOrgsHaveAccessToInspection = userOrgIds.some((orgId) => property.authorized_user_ids.includes(orgId));
    return (__1.Utils.Users.isInternalAdmin(user) ||
        property.authorized_user_ids.includes(user.id) ||
        userOrOrgsHaveAccessToInspection);
};
exports.canAccess = canAccess;
/**
 * Evaluates wether the property can be restored or not. User must be an internal admin.
 *
 * If property has no `organization_id` or its `organization_id` is already contained
 * within the `property.authorized_user_ids`, it will also fail.
 *
 * @param property property to be evaluated.
 * @param user user that's calling the function. Must be an internal admin.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canRestore = (property, user) => {
    if (!property.organization_id ||
        property.authorized_user_ids.includes(property.organization_id)) {
        return false;
    }
    return !!__1.Utils.Users.isInternalAdmin(user);
};
exports.canRestore = canRestore;
/**
 * Checks if the user can delete a property. Validations are the same as
 * `Rules.Properties.canAccess`.
 *
 * @param user user that's calling the function. Must be an internal admin.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canDelete = (property, user) => {
    return (0, exports.canAccess)(property, user);
};
exports.canDelete = canDelete;
/**
 * Simply checks if the user is an internal admin.
 *
 * @param user user that's calling the function. Must be an internal admin.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canTransferAsAdmin = (user) => {
    return __1.Utils.Users.isInternalAdmin(user);
};
exports.canTransferAsAdmin = canTransferAsAdmin;
/**
 * Checks if the user is enabled to import properties.
 * The user must not have an AppFolio integration or RentManager integrations.
 *
 * @param appfolioIntegration AppFolio Integration for the user's account
 * @param rentmanagerIntegrations RentManager Integrations for the user's account
 * @param loading Optional loading state, if provided the function will return false until it's no longer loading
 * @returns `true` if the user can import units, `false` otherwise
 */
const canImport = (appfolioIntegration, rentmanagerIntegrations, loading) => {
    if (loading)
        return false;
    if (appfolioIntegration)
        return false;
    if (rentmanagerIntegrations === null || rentmanagerIntegrations === void 0 ? void 0 : rentmanagerIntegrations.length)
        return false;
    return true;
};
exports.canImport = canImport;

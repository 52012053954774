"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canEnable = exports.canDelete = exports.canAccess = void 0;
const __1 = require("../..");
const getTeamIds = (teams) => {
    var _a;
    return (_a = teams === null || teams === void 0 ? void 0 : teams.map((u) => (typeof u === 'string' ? u : u.id))) !== null && _a !== void 0 ? _a : [];
};
/**
 * There are three possibilities for a user to have access to someone
 * - they share at least one team;
 * - the user it's trying to access itself;
 * - the caller is a RentCheck internal admin.
 *
 * @param userToFetch user to be accessed.
 * @param caller user trying to access.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canAccess = (userToFetch, caller) => {
    /**
     * Checks if the user that called the function and the user to be retrieved
     * share at least one team.
     */
    const utfTeamIds = getTeamIds(userToFetch.organizations);
    const callerTeamIds = getTeamIds(caller.organizations);
    const doUsersShareTeams = utfTeamIds.some((utfTeamId) => callerTeamIds.find((callerTeamId) => callerTeamId === utfTeamId));
    return (doUsersShareTeams ||
        userToFetch.id === caller.id ||
        __1.Utils.Users.isInternalAdmin(caller));
};
exports.canAccess = canAccess;
/**
 * Checks if the user to be deleted is the same as the user that's calling the
 * endpoint or if the caller is an internal admin.
 *
 * @param userToDelete user to be deleted.
 * @param caller user that called the endpoint.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canDelete = (userToDelete, caller) => {
    return userToDelete.id === caller.id || __1.Utils.Users.isInternalAdmin(caller);
};
exports.canDelete = canDelete;
/**
 * Enables user back again. Only admins can perform this.
 *
 * @param userToDelete user to be deleted.
 * @param caller user that called the endpoint. Must be an internal admin.
 * @returns boolean wether the operation can be fulfilled or not.
 */
const canEnable = (userToEnable, caller) => {
    return __1.Utils.Users.isInternalAdmin(caller);
};
exports.canEnable = canEnable;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canCreateRentManagerWorkOrders = exports.canCreateAppFolioWorkOrders = void 0;
const constants_1 = require("../../constants");
const subscriptions_1 = require("../../utils/subscriptions");
/**
 * A user can create AppFolio work orders if they are on the Pro plan or have the AppFolio Work Orders addon.
 * Additionaly, the property must be synced with AppFolio.
 *
 * @param property The property for which the inspection was performed
 * @param subscription The user's subscription
 * @returns true if the user can create AppFolio work orders, false otherwise
 */
const canCreateAppFolioWorkOrders = (property, subscription) => {
    var _a;
    if (!subscription) {
        return false;
    }
    if (((_a = property.sync_data) === null || _a === void 0 ? void 0 : _a.vendor) !== 'appfolio') {
        return false;
    }
    if ((0, subscriptions_1.isOnProPlan)(subscription)) {
        return true;
    }
    if ((0, subscriptions_1.hasAddons)(subscription, constants_1.Addons.AppFolio.WorkOrders)) {
        return true;
    }
    return false;
};
exports.canCreateAppFolioWorkOrders = canCreateAppFolioWorkOrders;
/**
 * A user can create Rent Manager work orders if they are on the Pro plan or have the Rent Manager Work Orders addon.
 * Additionaly, the property must be synced with Rent Manager.
 *
 * @param property The property for which the inspection was performed
 * @param subscription The user's subscription
 * @returns true if the user can create Rent Manager work orders, false otherwise
 */
const canCreateRentManagerWorkOrders = (property, subscription) => {
    var _a;
    if (!subscription) {
        return false;
    }
    if (((_a = property.sync_data) === null || _a === void 0 ? void 0 : _a.vendor) !== 'rentmanager') {
        return false;
    }
    if ((0, subscriptions_1.isOnProPlan)(subscription)) {
        return true;
    }
    if ((0, subscriptions_1.hasAddons)(subscription, constants_1.Addons.RentManager.WorkOrders)) {
        return true;
    }
    return false;
};
exports.canCreateRentManagerWorkOrders = canCreateRentManagerWorkOrders;

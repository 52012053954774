"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isTeamAdmin = exports.isInternalAdmin = exports.canPerformInspection = exports.isSystem = exports.isPropertyManager = exports.isRenter = void 0;
const checkValue = (userOrType, values) => {
    if (!userOrType)
        return false;
    if (typeof userOrType === 'string') {
        return values.includes(userOrType);
    }
    if ('type' in userOrType) {
        return values.includes(userOrType.type);
    }
    if ('user_type' in userOrType) {
        return values.includes(userOrType.user_type);
    }
    return false;
};
function isRenter(userOrType) {
    return checkValue(userOrType, ['Renter']);
}
exports.isRenter = isRenter;
function isPropertyManager(userOrType) {
    return checkValue(userOrType, ['Property Manager', 'Landlord']);
}
exports.isPropertyManager = isPropertyManager;
function isSystem(userOrType) {
    return checkValue(userOrType, ['System']);
}
exports.isSystem = isSystem;
const canPerformInspection = (inspection) => {
    return ['self-perform', 'invitee-write'].includes(inspection.role);
};
exports.canPerformInspection = canPerformInspection;
/**
 * For a user to be an internal admin, the flag `user.internal_admin` must be set
 * as `true`.
 *
 * @param user
 * @returns boolean
 */
const isInternalAdmin = (user) => {
    return !!user.internal_admin;
};
exports.isInternalAdmin = isInternalAdmin;
/**
 * Determines wether or not a user is an admin for one of their teams
 * @param user the user object
 * @returns true if the user is an admin on at least one team, false otherwise
 */
const isTeamAdmin = (user) => {
    if (!user) {
        return false;
    }
    if ('teams' in user) {
        return user.teams.some((team) => team.role === 'admin');
    }
    if ('organizations' in user) {
        return user.organizations.some((organization) => organization.role === 'admin');
    }
    return false;
};
exports.isTeamAdmin = isTeamAdmin;

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { integrations } from 'assets';
import { CenteredRow, Spacer } from 'components';
import { colors } from 'theme';

const HeaderIcons = () => {
	return (
		<CenteredRow>
			<img
				alt=""
				style={{ width: 76, height: 76, objectFit: 'contain' }}
				src={integrations.latchel}
			/>
			<Spacer width={3} />
			<FontAwesomeIcon icon={regular('ellipsis-h')} color={colors.primary} />
			<Spacer width={3} />
			<img
				alt=""
				style={{ width: 70, height: 70, objectFit: 'contain' }}
				src={integrations.rentCheck}
			/>
		</CenteredRow>
	);
};

export default HeaderIcons;

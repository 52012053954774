import { Feature } from '@rentcheck/types';
import firebase from 'config/firebase';
import { FeatureWithMetadata } from 'store/reducers/features';

import _ from 'lodash';
import {
	objectFromDocumentSnapshot,
	objectsFromQuerySnapshot,
} from './helpers';
import * as ImageMetadataApi from './image-metadata';

export const getById = async (id: string): Promise<Feature | undefined> =>
	firebase
		.firestore()
		.collection('inspection_feature')
		.doc(id)
		.get()
		.then((result) => objectFromDocumentSnapshot<Feature>(result));

export const getForInspectionId = async (inspectionId: string) => {
	const features = await firebase
		.firestore()
		.collection('inspection_feature')
		.where('inspection_id', '==', inspectionId)
		.get()
		.then((querySnapshot) => objectsFromQuerySnapshot<Feature>(querySnapshot));

	const featuresWithMetadata: FeatureWithMetadata[] =
		await addMetadataToFeatures(features);

	return featuresWithMetadata;
};

export const addMetadataToFeatures = async (
	features: Feature[]
): Promise<FeatureWithMetadata[]> => {
	if (!features.length) {
		return [];
	}

	const inspectionId = features[0].inspection_id;
	const imagesMetadata = await ImageMetadataApi.getForInspection(inspectionId);
	const groupedMetadata = _.groupBy(imagesMetadata, 'inspection_feature.id');

	return features.map((f) => ({
		...f,
		image_metadata: groupedMetadata[f.id] ?? [],
	}));
};

import { Button, TextField, ToggleButton, Typography } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { UserType, userTypes } from '@rentcheck/types';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import {
	CenteredColumn,
	PhoneNumberInputFilled,
	Row,
	Spacer,
} from 'components';
import { SnackbarActions } from 'store/actions';
import { isPhoneValid } from 'utils/phoneNumber';

import { Steps } from './index';

type Errors = 'properties' | 'company' | '';

interface Props {
	userType: UserType;
	setUserType: (type: UserType) => void;
	totalProperties: number;
	setTotalProperties: (properties: number) => void;
	companyName: string;
	setCompanyName: (name: string) => void;
	phoneNumber: string;
	setPhoneNumber: (number: string) => void;
	defaultPage: string;
	setStep: (step: Steps) => void;
}

const InitialStep = ({
	userType,
	setUserType,
	phoneNumber,
	setPhoneNumber,
	totalProperties,
	setTotalProperties,
	companyName,
	setCompanyName,
	setStep,
	defaultPage,
}: Props) => {
	const dispatch = useDispatch();

	const filteredUserTypes = userTypes.filter(
		(ut) => Utils.Users.isRenter(ut) || Utils.Users.isPropertyManager(ut)
	);

	const [error, setError] = useState<Errors>('');

	useEffect(() => {
		setError('');
	}, [userType]);

	const getStarted = () => {
		if (Utils.Users.isRenter(userType)) {
			setError('');
			setStep('additional');
			return;
		}

		if (!totalProperties) {
			setError('properties');
			return;
		}

		if (userType === 'Landlord') {
			setError('');
			setStep('additional');
			return;
		}

		if (!companyName.length) {
			setError('company');
			return;
		}

		if (phoneNumber && !isPhoneValid(phoneNumber)) {
			const message = 'Please enter a valid phone number';
			dispatch(SnackbarActions.showError(message));
			return;
		}

		setError('');
		setStep('additional');
	};

	return (
		<CenteredColumn>
			<Typography variant="h6">Tell us about yourself</Typography>
			<Spacer height={1} />
			<Typography variant="h6" fontWeight={400}>
				I am a...
			</Typography>

			<Spacer height={6} />

			{filteredUserTypes.map((ut) => (
				<ToggleButton
					fullWidth
					value={ut}
					selected={userType === ut}
					onChange={() => setUserType(ut)}
					sx={{ marginBottom: 2 }}>
					{ut}
				</ToggleButton>
			))}

			<Spacer height={2} />

			<Form
				onSubmit={(e) => {
					e.preventDefault();
					getStarted();
				}}>
				{(userType === 'Landlord' || userType === 'Property Manager') && (
					<>
						<TextField
							fullWidth
							id="total_properties"
							variant="filled"
							type="number"
							label="How many units do you own or manage?"
							onChange={(e) => setTotalProperties(parseInt(e.target.value))}
							error={error === 'properties'}
							helperText={
								error === 'properties'
									? 'Please enter the number of units you own or manage'
									: ' '
							}
						/>
					</>
				)}

				{userType === 'Property Manager' && (
					<>
						<TextField
							fullWidth
							id="company"
							variant="filled"
							type="text"
							label="Company Name"
							onChange={(e) => setCompanyName(e.target.value)}
							error={error === 'company'}
							helperText={
								error === 'company' ? 'Please enter your company name' : ' '
							}
						/>

						<PhoneNumberInputFilled
							country="us"
							label="Phone Number"
							value={phoneNumber}
							onChange={(value: string) => setPhoneNumber(value)}
						/>
					</>
				)}

				<Spacer height={10} />

				<Button fullWidth type="submit" onClick={getStarted}>
					Sign up for free
				</Button>
				<Spacer height={6} />

				<Row>
					Already have an account?
					<Spacer width={1} />
					<Link to={`/signin/${defaultPage}`}>Log in</Link>
				</Row>
			</Form>
		</CenteredColumn>
	);
};

const Form = styled.form`
	display: flex;
	flex-direction: column;
	width: 100%;
	align-items: center;
`;

export default InitialStep;

import { useContext, useEffect } from 'react';

import { Flatfile } from '@flatfile/api';
import { Sheet, Space, useFlatfile, Workbook } from '@flatfile/react';
import { ImportModel } from '@rentcheck/types';
import {
	FlatFileImportContext,
	FlatFileImportContextType,
} from '../../../context';
import { pmsNameMap } from '../../../utils';
import { DataSubmittedEvent } from './types';

export interface Props {
	sheetConfig: Flatfile.SheetConfig;
	onDataSubmitted: (event: DataSubmittedEvent) => void;
}

export default ({ sheetConfig, onDataSubmitted }: Props) => {
	const context = useContext(FlatFileImportContext);
	const flatfile = useFlatfile();

	useEffect(() => {
		if (!flatfile.open) {
			flatfile.openPortal();
		}
	}, [flatfile]);

	return (
		<Space
			config={{
				name: 'RentCheck',
				metadata: {
					sidebarConfig: {
						showSidebar: false,
					},
				},
			}}>
			<Workbook
				config={{
					name: getWorkbookName(context),
				}}
				onSubmit={({ sheet }) => onDataSubmitted(sheet)}>
				<Sheet config={sheetConfig} />
			</Workbook>
		</Space>
	);
};

const getWorkbookName = (context: FlatFileImportContextType) => {
	const modelToTitleMap: Record<ImportModel, string> = {
		unit: 'Units',
		resident: 'Residents',
	};

	let baseTitle = '';

	if (context.operation === 'delete') {
		baseTitle = `Delete ${modelToTitleMap[context.model]}`;
	}

	if (context.operation === 'create') {
		baseTitle = `Create ${modelToTitleMap[context.model]}`;
	}

	if (context.operation === 'create/update') {
		baseTitle = `Create or Update ${modelToTitleMap[context.model]}`;
	}

	const pmsName = pmsNameMap[context.pms];

	if (pmsName) {
		baseTitle = `${baseTitle} (${pmsName})`;
	}

	return baseTitle;
};

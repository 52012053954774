var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../../request';
import { millisToString } from '../../utils/filters';
const defaultSort = {
    sortBy: 'address',
    sortType: 'asc',
};
const getAll = (pageNumber = 0, pageSize = 20, filters, sorting = defaultSort, config) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    return request({
        method: 'get',
        version: 'v2',
        url: '/work_orders',
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            // filters
            search: filters.search,
            inspection_template: filters.inspectionTemplate,
            status: filters.status,
            property_id: filters.propertyId,
            start_created_date: millisToString((_a = filters.dateRange) === null || _a === void 0 ? void 0 : _a.start),
            end_created_date: millisToString((_b = filters.dateRange) === null || _b === void 0 ? void 0 : _b.end),
            // sorting
            sort_by: sorting.sortBy,
            sort_type: sorting.sortType,
        },
        appConfig: config,
    }).then((value) => {
        const data = value.data.data;
        const totalResults = value.data.total_results;
        return { data, totalResults };
    });
});
const getById = (id, config) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/work_orders/${id}`,
        appConfig: config,
    }).then((value) => value.data.data);
});
export default { getAll, getById };

import {
	Divider,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { PropertiesActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import themeTypography from 'theme/typography';
import { formatCurrency } from 'utils/helpers';
import { paywallLimit } from '../../../../constants';

export default () => {
	const dispatch = useDispatch();

	const profile = useTypedSelector((state) => state.activeProfile);
	const units = useTypedSelector((state) => state.properties.units);
	const subscription = useTypedSelector((state) => state.subscription);

	useEffect(() => {
		dispatch(PropertiesActions.units.getAll());
	}, [profile]);

	if (subscription) {
		return null;
	}

	const headerStyle = {
		...themeTypography.MuiTypography.styleOverrides.overline,
		color: 'black !important',
		backgroundColor: 'white !important',
		fontWeight: '500 !important',
	};

	const planStyle = { borderBottom: 'none' };

	return (
		<>
			<Paper
				elevation={0}
				sx={{ border: '1px solid #0000001F', padding: '8px 0' }}>
				<Typography variant="overline" sx={{ margin: '0 16px 16px 16px' }}>
					Summary
				</Typography>
				<Divider sx={{ marginTop: 1, marginBottom: 2 }} />
				<Typography variant="subtitle1" sx={{ margin: '8px 16px' }}>
					Active units: {units.total}/{paywallLimit}
				</Typography>
			</Paper>

			<TableContainer
				component={Paper}
				elevation={0}
				sx={{ border: '1px solid #0000001F', marginTop: 3 }}>
				<Table>
					<TableHead>
						<TableRow>
							<TableCell sx={headerStyle}>Items (1)</TableCell>
							<TableCell sx={headerStyle} align="right">
								Units
							</TableCell>
							<TableCell sx={headerStyle} align="right">
								Unit Price
							</TableCell>
							<TableCell sx={headerStyle} align="right">
								Amount
							</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						<TableRow>
							<TableCell colSpan={4} sx={{ borderBottom: 'none' }}>
								<Typography variant="subtitle2">Plan</Typography>
							</TableCell>
						</TableRow>
						<TableRow>
							<TableCell sx={planStyle}>
								Free Account -{' '}
								{Utils.Helpers.numberAndPluralizedWord(paywallLimit, 'unit')}
							</TableCell>
							<TableCell align="right" sx={planStyle}>
								{units.total}
							</TableCell>
							<TableCell align="right" sx={planStyle}>
								{formatCurrency(0, true)}
							</TableCell>
							<TableCell align="right" sx={planStyle}>
								{formatCurrency(0, true)}
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
};

import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'First Name',
			type: 'string',
			label: 'First Name',
		},
		{
			key: 'Last Name',
			type: 'string',
			label: 'Last Name',
		},
		{
			key: 'Email',
			type: 'string',
			label: 'Email',
		},
		{
			key: 'Phone Number',
			type: 'string',
			label: 'Phone Number',
		},
		{
			key: 'Street 1',
			type: 'string',
			label: 'Street 1',
		},
		{
			key: 'Street 2',
			type: 'string',
			label: 'Street 2',
		},
		{
			key: 'City',
			type: 'string',
			label: 'City',
		},
		{
			key: 'State',
			type: 'string',
			label: 'State',
		},
		{
			key: 'Postal Code',
			type: 'string',
			label: 'Postal Code',
		},
		{
			key: 'Lease Start Date',
			type: 'string',
			label: 'Lease Start Date',
		},
		{
			key: 'Lease End Date',
			type: 'string',
			label: 'Lease End Date',
		},
		{
			key: 'Move In Date',
			type: 'string',
			label: 'Move In Date',
		},
		{
			key: 'Move Out Date',
			type: 'string',
			label: 'Move Out Date',
		},
		{
			key: 'Security Deposit',
			type: 'string',
			label: 'Security Deposit',
		},
		{
			key: 'Monthly Rent',
			type: 'string',
			label: 'Monthly Rent',
		},
		{
			key: 'Unit',
			type: 'string',
			label: 'Unit',
		},
	],

	actions: undefined,
};

import { Checkbox, Typography } from '@mui/material';
import { Rules } from '@rentcheck/biz';
import { ApiInspection, APIProperty } from '@rentcheck/types';
import { Row } from 'components';
import { CreateWorkOrderMetadata } from 'store/reducers/modal-flows';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { simplePropertyType } from 'utils/helpers';

interface Props {
	inspection: ApiInspection;
	property: APIProperty;
	value?: CreateWorkOrderMetadata['provider'];
	setValue: (value?: CreateWorkOrderMetadata['provider']) => void;
}

export default ({ property, value, setValue }: Props) => {
	const subscription = useTypedSelector((state) => state.subscription);

	if (
		!Rules.WorkOrders.canCreateRentManagerWorkOrders(property, subscription)
	) {
		return null;
	}

	if (property?.sync_data?.vendor !== 'rentmanager') {
		return (
			<Typography sx={{ marginTop: 3 }}>
				*This {simplePropertyType(property)} is not synced with Rent Manager.
			</Typography>
		);
	}

	return (
		<Row style={{ marginTop: 24, marginLeft: -12 }}>
			<Checkbox
				checked={value === 'Rent Manager'}
				onChange={() => setValue(value ? undefined : 'Rent Manager')}
			/>
			<Typography>Create Rent Manager Service Issue</Typography>
		</Row>
	);
};

import { Card, CardContent, Typography } from '@mui/material';
import { AccountSettings } from '@rentcheck/types';
import { Column, LoadingButton } from 'components';

import { Spacer } from 'components';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { SnackbarActions } from 'store/actions';

import { AccountSettingsApi } from '@rentcheck/api-frontend';
import CustomSkills from './custom-skills';

export default () => {
	const dispatch = useDispatch();

	const [loading, setLoading] = useState(false);
	const [accountSettings, setAccountSettings] = useState<AccountSettings>();

	const handleSearchAccountSettings = async () => {
		setLoading(true);

		try {
			const accountSettings = await AccountSettingsApi.get();

			if (!accountSettings) {
				throw Error('Account Settings not found');
			}

			setAccountSettings(accountSettings);
		} catch (e) {
			dispatch(SnackbarActions.showError((e as Error).message));
		}

		setLoading(false);
	};

	return (
		<Card>
			<CardContent>
				<Column style={{ width: '50%' }}>
					<Typography variant="h5">Customize Maintenance Skills</Typography>
					<Spacer height={2} />

					<Typography variant="body1">
						You need to impersonate the target user in order to customize their
						maintenance skills.
					</Typography>
					<Spacer height={2} />
					<LoadingButton
						sx={{ height: 56 }}
						loading={loading}
						onClick={handleSearchAccountSettings}>
						Get account settings
					</LoadingButton>

					<CustomSkills value={accountSettings} setValue={setAccountSettings} />
				</Column>
			</CardContent>
		</Card>
	);
};

import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Unit Address',
			type: 'string',
			label: 'Unit Address',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit Address Cont.',
			type: 'string',
			label: 'Unit Address Cont.',
		},
		{
			key: 'City',
			type: 'string',
			label: 'City',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'State',
			type: 'string',
			label: 'State',
		},
		{
			key: 'Zip',
			type: 'string',
			label: 'Zip',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'First Name',
			type: 'string',
			label: 'First Name',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Last Name',
			type: 'string',
			label: 'Last Name',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Lease Status',
			type: 'string',
			label: 'Lease Status',
		},
		{
			key: 'Email',
			type: 'string',
			label: 'Email',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Mobile Phone #',
			type: 'string',
			label: 'Mobile Phone #',
		},
		{
			key: 'Start Date',
			type: 'string',
			label: 'Start Date',
		},
		{
			key: 'End Date',
			type: 'string',
			label: 'End Date',
		},
		{
			key: 'Monthly Rent',
			type: 'string',
			label: 'Monthly Rent',
		},
		{
			key: 'Target Deposit',
			type: 'string',
			label: 'Target Deposit',
		},
		{
			key: 'Move-in Date',
			type: 'string',
			label: 'Move-in Date',
		},
		{
			key: 'Vacated Date',
			type: 'string',
			label: 'Vacated Date',
		},
	],

	actions: undefined,
};

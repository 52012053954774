import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Column } from 'components';
import { useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import FilterChip from './common/filter-chip';
import FilterPopover, { FilterPopoverRef } from './common/filter-popover';
import FilterPopoverContent from './common/filter-popover-content';
import Skeleton from './common/skeleton';

const AddFilter = () => {
	const dispatch = useDispatch();

	const ref = useRef<FilterPopoverRef>(null);

	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);

	if (!dashboardData) {
		return <Skeleton />;
	}

	const canAddTeamFilter = !dashboardFilters.team;
	const canAddBuildingFilter =
		dashboardFilters.propertyType === 'Unit' && !dashboardFilters.building;

	if (!canAddTeamFilter && !canAddBuildingFilter) {
		return null;
	}

	const handleAddTeam = () => {
		ref.current?.close();
		dispatch({ type: 'SET_DASHBOARD_FILTER_TEAM', value: [] });
	};

	const handleAddBuilding = () => {
		ref.current?.close();
		dispatch({ type: 'SET_DASHBOARD_FILTER_BUILDING', value: [] });
	};

	return (
		<FilterPopover
			ref={ref}
			anchorChild={
				<FilterChip title="Add filter" icon={regular('plus')} variant="light" />
			}
			contentChildren={
				<Column>
					<FilterPopoverContent.Title title="Filter by" />

					{canAddTeamFilter && (
						<FilterPopoverContent.Row title="Team" onClick={handleAddTeam} />
					)}

					{canAddBuildingFilter && (
						<FilterPopoverContent.Row
							title="Building"
							onClick={handleAddBuilding}
						/>
					)}
				</Column>
			}
		/>
	);
};

export default AddFilter;

import { ImportSource } from '@rentcheck/types';

export const pmsNameMap: Record<ImportSource, string> = {
	rentcheck: '',
	appfolio: 'AppFolio',
	buildium: 'Buildium',
	rentmanager: 'Rent Manager',
	rentvine: 'Rentvine',
	propertyware: 'Propertyware',
};

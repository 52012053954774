import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Team ID',
			type: 'string',
			label: 'Team ID',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit Address',
			type: 'string',
			label: 'Unit Address',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit Address Cont.',
			type: 'string',
			label: 'Unit Address Cont.',
		},
		{
			key: 'Unit City',
			type: 'string',
			label: 'Unit City',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit State',
			type: 'string',
			label: 'Unit State',
		},
		{
			key: 'Unit Zip',
			type: 'string',
			label: 'Unit Zip',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Type',
			type: 'string',
			label: 'Type',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Bedrooms',
			type: 'number',
			label: 'Bedrooms',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Bathrooms',
			type: 'number',
			label: 'Bathrooms',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Additional Rooms/Areas',
			type: 'string',
			label: 'Additional Rooms/Areas',
		},
	],

	actions: undefined,
};

import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Unit number',
			type: 'string',
			label: 'Unit Number',
		},
		{
			key: 'First name',
			type: 'string',
			label: 'First Name',
		},
		{
			key: 'Last name',
			type: 'string',
			label: 'Last Name',
		},
		{
			key: 'Lease start date',
			type: 'string',
			label: 'Lease Start Date',
		},
		{
			key: 'Lease end date',
			type: 'string',
			label: 'Lease End Date',
		},
		{
			key: 'Rent',
			type: 'string',
			label: 'Rent',
		},
		{
			key: 'Street Address line 1',
			type: 'string',
			label: 'Street Address Line 1',
		},
		{
			key: 'Street Address line 2',
			type: 'string',
			label: 'Street Address Line 2',
		},
		{
			key: 'Street Address line 3',
			type: 'string',
			label: 'Street Address Line 3',
		},
		{
			key: 'City/Locality',
			type: 'string',
			label: 'City Locality',
		},
		{
			key: 'State/Province/Territory',
			type: 'string',
			label: 'State Province Territory',
		},
		{
			key: 'Postal code',
			type: 'string',
			label: 'Postal Code',
		},
		{
			key: 'Login email',
			type: 'string',
			label: 'Login Email',
		},
		{
			key: 'Home phone',
			type: 'string',
			label: 'Home Phone',
		},
		{
			key: 'Work phone',
			type: 'string',
			label: 'Work Phone',
		},
		{
			key: 'Mobile',
			type: 'string',
			label: 'Mobile',
		},
	],

	actions: undefined,
};

import APIConfig from '../config';
import { request } from '../request';
const postMetadataFeedback = (imageMetadataId, isCorrect) => {
    return request({
        method: 'post',
        url: `/foxy-ai/feedback/${imageMetadataId}`,
        params: {
            user_id: APIConfig.getUserId(),
        },
        data: {
            is_image_correct: isCorrect,
        },
    });
};
export default { postMetadataFeedback };

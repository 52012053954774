import { light, solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Column } from 'components';
import _ from 'lodash';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { ApiTeam } from '@rentcheck/types';
import { useStoreTeams } from 'hooks/teams';
import FilterChip from './common/filter-chip';
import FilterPopover from './common/filter-popover';
import FilterPopoverContent from './common/filter-popover-content';

const TeamFilter = () => {
	const dispatch = useDispatch();

	const dashboardData = useTypedSelector((state) => state.dashboard.data);
	const dashboardFilters = useTypedSelector((state) => state.dashboard.filters);
	const { teams } = useStoreTeams();

	const [searchTerm, setSearchTerm] = useState('');

	if (!dashboardData) {
		return null;
	}

	if (!dashboardFilters.team) {
		return null;
	}

	const handleClick = (value: ApiTeam) => {
		dispatch({
			type: 'SET_DASHBOARD_FILTER_TEAM',
			value: _.xor(dashboardFilters.team, [value.id]),
		});
	};

	const handleRemove = () => {
		dispatch({
			type: 'SET_DASHBOARD_FILTER_TEAM',
			value: null,
		});
	};

	const currentValue = dashboardFilters.team;
	const title =
		currentValue.length === 0
			? 'None'
			: currentValue.length === 1
				? teams.find((o) => o.id === currentValue[0])?.internal_label
				: `${currentValue.length} Selected`;

	const lowercaseSearchTerm = searchTerm.toLowerCase();
	const filteredOrganizations = teams.filter((o) =>
		o.internal_label.toLowerCase().includes(lowercaseSearchTerm)
	);

	return (
		<FilterPopover
			defaultOpen
			anchorChild={
				<FilterChip
					title={`Teams: ${title}`}
					icon={light('users')}
					variant="primary"
					endIcon={solid('close')}
					endIconOnClick={handleRemove}
				/>
			}
			contentChildren={
				<Column>
					<FilterPopoverContent.Title title="Teams" />

					<FilterPopoverContent.SearchBar
						placeholder="Search Teams"
						value={searchTerm}
						setValue={setSearchTerm}
					/>

					{filteredOrganizations.map((o) => (
						<FilterPopoverContent.SelectableRow
							key={o.id}
							title={o.internal_label}
							variant="checkbox"
							onClick={() => handleClick(o)}
							selected={currentValue.includes(o.id) ?? false}
						/>
					))}
				</Column>
			}
		/>
	);
};

export default TeamFilter;

import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Street',
			type: 'string',
			label: 'Street',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'City',
			type: 'string',
			label: 'City',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'State',
			type: 'string',
			label: 'State',
		},
		{
			key: 'Zip Code',
			type: 'string',
			label: 'Zip Code',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'First Name',
			type: 'string',
			label: 'First Name',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Last Name',
			type: 'string',
			label: 'Last Name',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Email',
			type: 'string',
			label: 'Email',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Phone',
			type: 'string',
			label: 'Phone',
		},
		{
			key: 'Start Date',
			type: 'string',
			label: 'Start Date',
		},
		{
			key: 'End Date',
			type: 'string',
			label: 'End Date',
		},
		{
			key: 'Base Rent',
			type: 'number',
			label: 'Base Rent',
		},
		{
			key: 'Security',
			type: 'number',
			label: 'Security',
		},
		{
			key: 'Move in Date',
			type: 'string',
			label: 'Move in Date',
		},
		{
			key: 'Move out Date',
			type: 'string',
			label: 'Move out Date',
		},
	],

	actions: undefined,
};

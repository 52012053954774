import { colors } from 'theme';
import { imageUrlFromGCPUri } from 'utils/helpers';

interface Props {
	imageUri: string;
	imageSize: number;
	mb: number;
}

export default ({ imageUri, imageSize, mb }: Props) => {
	return (
		<img
			alt=""
			src={imageUrlFromGCPUri(imageUri, imageSize, imageSize)}
			style={{
				width: '100%',
				minHeight: 150,
				display: 'block',
				objectFit: 'cover',
				backgroundColor: colors.lightGray,
				marginBottom: mb,
				transition: 'opacity 0.3s',
			}}
		/>
	);
};

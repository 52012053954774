import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Team ID',
			type: 'string',
			label: 'Team ID',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Sub type',
			type: 'string',
			label: 'Sub Type',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'City/Locality',
			type: 'string',
			label: 'City',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'State/Province/Territory',
			type: 'string',
			label: 'State',
		},
		{
			key: 'Postal code',
			type: 'string',
			label: 'Postal Code',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit number',
			type: 'string',
			label: 'Unit Number',
		},
		{
			key: 'Unit address line 1',
			type: 'string',
			label: 'Unit Address Line 1',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit address line 2',
			type: 'string',
			label: 'Unit Address Line 2',
		},
		{
			key: 'Unit address line 3',
			type: 'string',
			label: 'Unit Address Line 3',
		},
		{
			key: 'Bedrooms',
			type: 'string',
			label: 'Bedrooms',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Bathrooms',
			type: 'string',
			label: 'Bathrooms',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Additional Rooms/Areas',
			type: 'string',
			label: 'Additional Rooms/Areas',
		},
	],

	actions: undefined,
};

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import { DialogTitle } from 'components';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { useIsMobileBreakpoint, useRemoteSettings } from 'utils/hooks';
import BillingOption from './billing-option';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();
	const isMobile = useIsMobileBreakpoint();
	const { showNewMessage } = useIntercom();

	const { settings } = useRemoteSettings();

	const handleClose = () => {
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleContactSupport = (message?: string) => {
		showNewMessage(message);
	};

	return (
		<Dialog open={open} onClose={handleClose} fullScreen={isMobile}>
			<DialogTitle
				title="Plans"
				icon={solid('sparkles')}
				onClose={handleClose}
				bottomSubtitle={
					<>
						Choose a plan below, or{' '}
						<a
							href="https://www.getrentcheck.com/pricing"
							target="_blank"
							style={{ color: 'inherit', textDecoration: 'underline' }}
							rel="noreferrer">
							learn more about RentCheck pricing.
						</a>
					</>
				}
			/>

			<DialogContent>
				<Grid container columns={{ xs: 1, sm: 2 }} spacing={2}>
					{settings.subscription_plans.map((sp) => {
						return (
							<Grid item xs={1}>
								<BillingOption key={sp.id} billingOption={sp} />
							</Grid>
						);
					})}
				</Grid>
			</DialogContent>
			<DialogActions>
				<div />
				<Button
					startIcon={<FontAwesomeIcon icon={solid('message-lines')} />}
					onClick={() => handleContactSupport()}>
					Need help?
				</Button>
			</DialogActions>
		</Dialog>
	);
};

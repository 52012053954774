import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Team ID',
			type: 'string',
			label: 'Team ID',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Address',
			type: 'string',
			label: 'Address',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'City',
			type: 'string',
			label: 'City',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'State',
			type: 'string',
			label: 'State',
		},
		{
			key: 'Zip Code',
			type: 'string',
			label: 'Zip Code',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Unit Type',
			type: 'string',
			label: 'Unit Type',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Bedrooms',
			type: 'number',
			label: 'Bedrooms',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Bathrooms',
			type: 'number',
			label: 'Bathrooms',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Additional Rooms/Areas',
			type: 'string',
			label: 'Additional Rooms/Areas',
		},
	],

	actions: undefined,
};

import * as Sentry from '@sentry/react';

import { ApiUser, UpdateUserByIdParams } from '@rentcheck/types';

import { APIConfig, MeApi } from '@rentcheck/api-frontend';
import { Dispatch, GetFirebase, GetState } from 'types';

type ReduxFn = (a: Dispatch, b: GetState, c: GetFirebase) => any;

export type ActiveProfileActionTypes =
	| 'SET_ACTIVE_PROFILE'
	| 'LOGIN_ERROR'
	| 'LOGIN_SUCCESS'
	| 'LOGIN_DETECTED'
	| 'SIGNOUT_SUCCESS'
	| 'UPDATE_USER';

export const actionTypes: { [index: string]: ActiveProfileActionTypes } = {
	SET_ACTIVE_PROFILE: 'SET_ACTIVE_PROFILE',
	LOGIN_ERROR: 'LOGIN_ERROR',
	LOGIN_SUCCESS: 'LOGIN_SUCCESS',
	LOGIN_DETECTED: 'LOGIN_DETECTED',
	SIGNOUT_SUCCESS: 'SIGNOUT_SUCCESS',
	UPDATE_USER: 'UPDATE_USER',
};

export const setImpersonatedUser = (
	profile: ApiUser,
	impersonatedUser: ApiUser
) => {
	const fn: ReduxFn = async (dispatch, _getState) => {
		try {
			const state = _getState();
			const authedUserID = state.authenticatedUser.id;
			if (authedUserID !== profile.id) {
				throw new Error('Principal User doesnt match Authed user');
			}

			dispatch({ type: 'IMPERSONATE' });

			/**
			 * Set the user id in the APIConfig so that all requests are made as the impersonated user
			 */
			APIConfig.setUserId(impersonatedUser.id);

			/**
			 * Set the active profile in the store to the impersonated user
			 */
			return dispatch({
				type: 'SET_ACTIVE_PROFILE',
				profile: impersonatedUser,
			} as any);
		} catch (err) {
			Sentry.captureException(err);
		}
	};
	return fn;
};

export const clearImpersonatedUser = () => {
	const fn: ReduxFn = async (dispatch, getState) => {
		const authenticatedUser = getState().authenticatedUser;

		if (!authenticatedUser) {
			return;
		}

		/**
		 * Clear the user id in the APIConfig so that all requests are made as the authenticated user
		 */
		APIConfig.setUserId(undefined);

		/**
		 * Clear subscription of impersonated user otherwise it will temporarily carry over
		 * to the authenticated user
		 */
		dispatch({ type: 'CLEAR_SUBSCRIPTION' });

		/**
		 * Set the active profile in the store to the authenticated user
		 */
		dispatch({ type: 'SET_ACTIVE_PROFILE', profile: authenticatedUser });
	};

	return fn;
};

export const update = (payload: UpdateUserByIdParams['data']) => {
	return async (dispatch: Dispatch): Promise<ApiUser | undefined> => {
		return MeApi.update(payload).then((user) => {
			if (!user) return undefined;

			dispatch({
				type: 'UPDATE_USER',
				profile: user,
			});

			return user;
		});
	};
};

export const refreshUser = () => {
	return async (dispatch: Dispatch, getState: GetState): Promise<void> => {
		const userId = getState().activeProfile.id;

		if (!userId) return;

		return MeApi.get().then((profile) => {
			if (!profile) return;

			dispatch({
				type: 'UPDATE_USER',
				profile,
			});
		});
	};
};

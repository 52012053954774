import { LoadingButton, LoadingButtonProps } from 'components/button';
import { useTypedSelector } from 'store/reducers/common';

export const EditBillingPlanButton = ({
	children,
	...props
}: LoadingButtonProps) => {
	const profile = useTypedSelector((state) => state.activeProfile);

	const disabled = !profile?.permissions?.allow_billing_plan_editing;

	const tooltip = disabled
		? "Heads up! You don't have permissions to modify this subscription."
		: '';

	return (
		<LoadingButton tooltip={tooltip} disabled={disabled} {...props}>
			{children}
		</LoadingButton>
	);
};

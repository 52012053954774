import { Flatfile } from '@flatfile/api';

export const sheetConfig: Flatfile.SheetConfig = {
	name: 'Your File',
	slug: 'your_file',

	fields: [
		{
			key: 'Name',
			type: 'string',
			label: 'Primary Tenant Name',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Email',
			type: 'string',
			label: 'Primary Tenant Email',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Phone',
			type: 'string',
			label: 'Primary Tenant Phone',
		},
		{
			key: 'Address',
			type: 'string',
			label: 'Address',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Address 2',
			type: 'string',
			label: 'Address 2',
		},
		{
			key: 'City',
			type: 'string',
			label: 'City',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'Postal Code',
			type: 'string',
			label: 'Postal Code',
			constraints: [{ type: 'required' }],
		},
		{
			key: 'State',
			type: 'string',
			label: 'State',
		},
		{
			key: 'Active',
			type: 'string',
			label: 'Active',
		},
		{
			key: 'Start Date',
			type: 'string',
			label: 'Start Date',
		},
		{
			key: 'End Date',
			type: 'string',
			label: 'End Date',
		},
		{
			key: 'Actual Rent',
			type: 'string',
			label: 'Actual Rent',
		},
		{
			key: 'Security Deposit',
			type: 'string',
			label: 'Security Deposit',
		},
		{
			key: 'Move In Date',
			type: 'string',
			label: 'Move In Date',
		},
		{
			key: 'Move Out Date',
			type: 'string',
			label: 'Move Out Date',
		},
	],

	actions: undefined,
};

import { useEffect } from 'react';

import { Flatfile } from '@flatfile/api';
import { Sheet, Space, useFlatfile, Workbook } from '@flatfile/react';
import { DataSubmittedEvent } from 'screens/account/imports/flows/dialogs/common/base-dialog/steps/embedded-flatfile/types';

export interface Props {
	sheetConfig: Flatfile.SheetConfig;
	onDataSubmitted: (event: DataSubmittedEvent) => void;
}

export default ({ sheetConfig, onDataSubmitted }: Props) => {
	const flatfile = useFlatfile();

	useEffect(() => {
		if (!flatfile.open) {
			flatfile.openPortal();
		}
	}, [flatfile]);

	return (
		<Space
			config={{
				name: 'RentCheck',
				metadata: {
					sidebarConfig: {
						showSidebar: false,
					},
				},
			}}>
			<Workbook
				config={{
					name: 'Customize Maintenance Skills',
				}}
				onSubmit={({ sheet }) => onDataSubmitted(sheet)}>
				<Sheet config={sheetConfig} />
			</Workbook>
		</Space>
	);
};

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge, Button } from '@mui/material';
import { useHistory } from 'react-router-dom';

import { Rules, Utils } from '@rentcheck/biz';
import { ApiInspectionWithTemplate } from '@rentcheck/types';

import _ from 'lodash';
import { useTypedSelector } from 'store/reducers/rootReducer';
import {
	isEmbeddedInMobileApp,
	maintenanceFlagsFromFeatures,
	numberAndPluralizeWord,
} from 'utils/helpers';

interface Props {
	inspection: ApiInspectionWithTemplate;
}

const FlaggedButton = ({ inspection }: Props) => {
	const history = useHistory();

	const features = useTypedSelector((state) =>
		_.values(state.features[inspection.id] ?? {})
	);

	const maintenanceFlags = maintenanceFlagsFromFeatures(features);

	if (!Rules.Inspections.canShowFlaggedItems(inspection, maintenanceFlags)) {
		return null;
	}

	if (isEmbeddedInMobileApp()) {
		return null;
	}

	const handleClick = () => {
		history.push(`/inspections/${inspection.id}/flagged-items`);
	};

	const showBadge = maintenanceFlags.some((mf) =>
		Utils.Users.isRenter(mf.created_by.type)
	);

	return (
		<Badge
			color="error"
			badgeContent=""
			invisible={!showBadge}
			sx={{
				'.MuiBadge-badge': {
					height: 12,
					minWidth: 12,
					padding: '0 1px',
					fontSize: 10,
				},
			}}>
			<Button
				onClick={handleClick}
				variant="outlined"
				startIcon={<FontAwesomeIcon icon={solid('flag')} />}
				sx={{ ml: 1 }}>
				View {numberAndPluralizeWord(maintenanceFlags.length, 'flag')}
			</Button>
		</Badge>
	);
};

export default FlaggedButton;

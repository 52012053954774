"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.canEditPlan = void 0;
const canEditPlan = ({ user, subscription, permissionGroups, }) => {
    if (!user) {
        return false;
    }
    /**
     * Free users should be allowed to edit the plan
     */
    if (!subscription) {
        return true;
    }
    /**
     * Paid users should have a permission group with
     * allow_billing_plan_editing set to true
     * in order to be able to edit the billing plan
     */
    return permissionGroups.some((p) => p.allow_billing_plan_editing);
};
exports.canEditPlan = canEditPlan;

const baseUrl =
	'https://rentcheck-web-public-assets.s3.us-east-2.amazonaws.com';

const emptyStatesBaseUrl = baseUrl + '/empty_states';
const successBaseUrl = baseUrl + '/success';
const authenticationBaseUrl = baseUrl + '/login';
const landingBaseUrl = baseUrl + '/landing';
const integrationsBaseUrl = baseUrl + '/integrations';
const errorsBaseUrl = baseUrl + '/error';
const inspectionTemplatesBaseUrl = baseUrl + '/inspection_templates';
const importsBaseUrl = baseUrl + '/imports';

export const emptyStates = {
	buildings: emptyStatesBaseUrl + '/buildings.svg',
	communities: emptyStatesBaseUrl + '/communities.svg',
	inspections: emptyStatesBaseUrl + '/inspections.svg',
	inspectionsWithFilters: emptyStatesBaseUrl + '/inspections-with-filters.svg',
	maintenance: emptyStatesBaseUrl + '/maintenance.svg',
	propertyDetailsInspections:
		emptyStatesBaseUrl + '/property-details-inspections.svg',
	residents: emptyStatesBaseUrl + '/residents.svg',
	scheduledEvents: emptyStatesBaseUrl + '/scheduled-events.svg',
	units: emptyStatesBaseUrl + '/units.svg',
	workOrders: emptyStatesBaseUrl + '/work-order.svg',
	sectionLogic: emptyStatesBaseUrl + '/section-logic.svg',
};

export const success = {
	appFolioInstall: successBaseUrl + '/appfolio-install.svg',
	appFolioUpgrade: successBaseUrl + '/appfolio-upgrade.svg',
	billingMobile: successBaseUrl + '/billing-mobile.svg',
	billingWeb: successBaseUrl + '/billing-web.svg',
	residentAdded: successBaseUrl + '/resident-added.svg',
	residentRemoved: successBaseUrl + '/resident-removed.svg',
	unitAdded: successBaseUrl + '/unit-added.svg',
	workOrder: successBaseUrl + '/work-order.svg',
	upgradePlan: successBaseUrl + '/upgrade-plan.svg',
};

export const authentication = {
	bgLeft: authenticationBaseUrl + '/left.svg',
	bgRight: authenticationBaseUrl + '/right.svg',
	bgMobile: authenticationBaseUrl + '/mobile.svg',
};

export const landing = {
	apm: {
		logoWhite: landingBaseUrl + '/apm/apm-logo-white.svg',
		logo: landingBaseUrl + '/apm/apm-logo.svg',
	},
	appFolio: {
		logoWhite: landingBaseUrl + '/appfolio/appfolio-logo-white.svg',
		logo: landingBaseUrl + '/appfolio/appfolio-logo.svg',
	},
	bgGradient: landingBaseUrl + '/background-gradient.png',
	binoculars: landingBaseUrl + '/binoculars.svg',
	customerQuote: landingBaseUrl + '/customer-quote.png',
	rentCheckLogoWhite: landingBaseUrl + '/rentcheck-logo-white.svg',
	rentCheckLogo: landingBaseUrl + '/rentcheck-logo.svg',
	rentCheckLogoNotext: landingBaseUrl + '/rentcheck-logo-no-text.svg',
};

export const integrations = {
	connect365: integrationsBaseUrl + '/365-connect.svg',
	appFolio: integrationsBaseUrl + '/appfolio.svg',
	latchel: integrationsBaseUrl + '/latchel.svg',
	rentCheck: integrationsBaseUrl + '/rentcheck-rest-api.svg',
	rentManager: integrationsBaseUrl + '/rentmanager.svg',
	survey: integrationsBaseUrl + '/survey.svg',
	yardiBlue: integrationsBaseUrl + '/yardi-blue.svg',
	yardi: integrationsBaseUrl + '/yardi.svg',
	zapier: integrationsBaseUrl + '/zapier.svg',
};

export const errors = {
	inspectionNotFound: errorsBaseUrl + '/inspection-not-found.svg',
};

export const inspectionTemplates = {
	fromScratch: inspectionTemplatesBaseUrl + '/from-scratch.svg',
	fromTemplate: inspectionTemplatesBaseUrl + '/from-template.svg',
	publish: inspectionTemplatesBaseUrl + '/publish.svg',
	dogPainting: inspectionTemplatesBaseUrl + '/dog-painting.svg',
	pugEmptyState: inspectionTemplatesBaseUrl + '/pug-empty-state.svg',
};

export const imports = {
	unitImport: importsBaseUrl + '/units.svg',
	unitsCreate: importsBaseUrl + '/units-create.svg',
	unitsDelete: importsBaseUrl + '/units-delete.svg',
	unitsPMSNone: importsBaseUrl + '/units-pms-none.svg',
	unitsPMSAppfolio: importsBaseUrl + '/units-pms-appfolio.svg',
	unitsPMSBuildium: importsBaseUrl + '/units-pms-buildium.svg',
	unitsPMSPropertyware: importsBaseUrl + '/units-pms-propertyware.svg',
	unitsPMSRentManager: importsBaseUrl + '/units-pms-rentmanager.svg',
	unitsPMSRentvine: importsBaseUrl + '/units-pms-rentvine.svg',

	residentImport: importsBaseUrl + '/residents.svg',
	residentsCreate: importsBaseUrl + '/residents-create.svg',
	residentsDelete: importsBaseUrl + '/residents-delete.svg',

	integrationExistsAppfolio:
		importsBaseUrl + '/integration-exists-appfolio.svg',
	integrationExistsRentmanager:
		importsBaseUrl + '/integration-exists-rentmanager.svg',
};

export default {
	authentication,
	landing,
	emptyStates,
	success,
	integrations,
	errors,
	inspectionTemplates,
	imports,
};

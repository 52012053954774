var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../request';
import { prepareFiltersForQueryParams } from '../utils/filters';
const getAll = (pageNumber = 0, pageSize = 20, filters = {}, sort = {
    sort_by: 'last_name',
    sort_type: 'ASC',
}) => __awaiter(void 0, void 0, void 0, function* () {
    const options = {
        sort,
        pagination: {
            page_size: pageSize,
            page_number: pageNumber,
        },
    };
    if (!filters.search) {
        filters.search = undefined;
    }
    return request({
        method: 'get',
        url: '/residents',
        version: 'v2',
        params: {
            page_number: pageNumber,
            page_size: pageSize,
            filters: prepareFiltersForQueryParams(filters),
            options,
        },
    }).then((data) => {
        return data.data;
    });
});
const get = (residentId) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/residents/${residentId}`,
        version: 'v2',
    }).then((res) => {
        return res.data.data;
    });
});
export default {
    getAll,
    get,
};

import { AutocompleteRenderGetTagProps, Chip } from '@mui/material';
import useResizeObserver from '@react-hook/resize-observer';
import { Row } from 'components/layout/Row';
import { useRef, useState } from 'react';
import { scrollToBottom } from 'utils/helpers';

interface Props<T> {
	value: T[];
	getTagProps: AutocompleteRenderGetTagProps;

	/**
	 * Used to determine the string value for a given option.
	 * It's used to fill the input (and the list box options if `renderOption` is not provided).
	 *
	 * @param {T} option
	 * @returns {string}
	 * @default (option) => option.label ?? option
	 */
	getOptionLabel?: (option: T) => string;
}

export default <T,>({ value, getTagProps, getOptionLabel }: Props<T>) => {
	const ref = useRef<HTMLDivElement>(null);

	const [containerHeight, setContainerHeight] = useState(0);

	useResizeObserver(ref, (entry) => {
		if (containerHeight === entry.contentRect.height) {
			return;
		}

		if (containerHeight < entry.contentRect.height) {
			scrollToBottom('tags-container');
		}

		setContainerHeight(entry.contentRect.height);
	});

	/**
	 * In this component we nest a Row inside a div to get 2 features
	 * out of the component:
	 * • we want the container to have a max height to make sure we don't
	 *   go out of bounds when rendering the tags
	 * • we want the inner Row to be able to change height so we can get
	 *   observer events for it and scroll to bottom appropriately
	 */
	return (
		<div
			id="tags-container"
			style={{
				marginBottom: 8,
				width: '120%',
				maxHeight: 120,
				overflowY: 'scroll',
				marginRight: -30,
				paddingRight: 4,
			}}>
			<Row
				ref={ref}
				style={{
					gap: 4,
					flexWrap: 'wrap',
				}}>
				{value.map((option, index) => (
					<Chip
						label={getOptionLabel?.(option) ?? (option as string)}
						{...getTagProps({ index })}
					/>
				))}
			</Row>
		</div>
	);
};

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.buildDigest = void 0;
const buildDigest = (data) => {
    return {
        id: data.id,
        name: data.name,
        user_count: data.users.length,
        allow_billing_plan_editing: !!data.allow_billing_plan_editing,
        allow_automation_editing: !!data.allow_automation_editing,
    };
};
exports.buildDigest = buildDigest;

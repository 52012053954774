import { createTransform } from 'redux-persist';

import { State as InspectionsState } from '../reducers/inspections';
import { initialState as inspectionsInitialState } from '../reducers/inspections/inspections';

import { Utils } from '@rentcheck/biz';
import { State as FileRequestsState } from '../reducers/file-requests';
import { State as ImportsState } from '../reducers/imports';

export const inspectionsTransform = createTransform(
	(inboundState) => {
		return inboundState as InspectionsState;
	},
	(outboundState: InspectionsState) => {
		/**
		 * At this point we only want to persist the filters, not
		 * the inspections themselves.
		 */
		return {
			inspections: inspectionsInitialState,
			inspection: null,
			filters: outboundState.filters,
		};
	},
	{ whitelist: ['inspections'] }
);

export const fileRequestsTransform = createTransform(
	(inboundState: FileRequestsState) => {
		return inboundState;
	},
	(outboundState: FileRequestsState) => {
		/**
		 * We only want to persist file requests that are in progress.
		 */
		return outboundState.filter(Utils.FileRequests.isInProgress);
	},
	{ whitelist: ['fileRequests'] }
);

export const importsTransform = createTransform(
	(inboundState: ImportsState) => {
		return inboundState;
	},
	(outboundState: ImportsState) => {
		/**
		 * We only want to persist imports that are in progress.
		 */
		return {
			imports: [],
			tasks: outboundState.tasks.filter(Utils.Imports.isInProgress),
		};
	},
	{ whitelist: ['imports'] }
);

import assets from 'assets';
import BaseDialog from '../common/base-dialog';
import blueprint from './blueprint';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;
}

export default ({ open, setOpen }: Props) => {
	return (
		<BaseDialog
			open={open}
			setOpen={setOpen}
			model="resident"
			selectOperationProps={[
				{
					title: 'Create & update residents',
					subtitle:
						'Add new residents or modify existing  resident information with a spreadsheet.',
					image: assets.imports.residentsCreate,
					operation: 'create/update',
					requiresPMSSelection: true,
					comingSoon: false,
					default: true,
				},
				{
					title: 'Delete residents',
					subtitle:
						'Delete multiple resident records at once using a spreadsheet. Deleting residents will not update their status but permanently delete them from RentCheck.',
					image: assets.imports.residentsDelete,
					operation: 'delete',
					requiresPMSSelection: false,
					comingSoon: true,
				},
			]}
			embeddedFlatfileProps={blueprint}
		/>
	);
};

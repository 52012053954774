import axios from 'axios';
import axiosRetry from 'axios-retry';
let baseUrl = undefined;
let authProvider = undefined;
let appId = undefined;
let appSecret = undefined;
let userId = undefined;
axiosRetry(axios, { retries: 3, retryDelay: axiosRetry.exponentialDelay });
const reset = () => {
    baseUrl = undefined;
    authProvider = undefined;
    appId = undefined;
    appSecret = undefined;
};
const setBaseUrl = (url) => {
    baseUrl = url;
};
const getBaseUrl = () => {
    if (!baseUrl) {
        throw new Error('init the baseUrl by calling `APIConfig.setBaseUrl`');
    }
    return baseUrl;
};
const setAuthProvider = (provider) => {
    authProvider = provider;
};
const getAuthProvider = () => {
    if (!authProvider) {
        throw new Error('init the baseUrl by calling `APIConfig.setAuthProvider`');
    }
    return authProvider;
};
const setAppId = (id) => {
    appId = id;
};
const getAppId = () => {
    if (!appId) {
        throw new Error('init the appId by calling `APIConfig.setAppId`');
    }
    return appId;
};
const setAppSecret = (secret) => {
    appSecret = secret;
};
const getAppSecret = () => {
    if (!appSecret) {
        throw new Error('init the appSecret by calling `APIConfig.setAppSecret`');
    }
    return appSecret;
};
const setUserId = (id) => {
    userId = id;
};
const getUserId = () => {
    return userId;
};
export default {
    reset,
    setBaseUrl,
    getBaseUrl,
    setAuthProvider,
    getAuthProvider,
    setAppId,
    getAppId,
    setAppSecret,
    getAppSecret,
    setUserId,
    getUserId,
};

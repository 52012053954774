import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import store, { persistor } from 'store';

import App from './App';

import 'config/firebase';
import 'config/global-imports';
import './index.css';

import reportWebVitals from './reportWebVitals';

/**
 * Expecting error here since firebaseAuthIsReady is attached by
 * react-redux-firebase and not part of the type
 */
// @ts-expect-error
store.firebaseAuthIsReady.then(() => {
	const root = ReactDOM.createRoot(
		document.getElementById('root') as HTMLElement
	);

	root.render(
		<React.StrictMode>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</React.StrictMode>
	);
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ValidAutomationAssignMethods = exports.ValidTriggerTypeTypes = exports.ValidCreationDateConditionTypes = exports.ValidCreationDateUnitTypes = exports.ValidSelectionTypes = void 0;
exports.ValidSelectionTypes = [
    'all_units',
    'specific_teams',
    'specific_units',
];
exports.ValidCreationDateUnitTypes = ['days', 'weeks'];
exports.ValidCreationDateConditionTypes = ['before', 'after'];
exports.ValidTriggerTypeTypes = [
    'tenant-move-in',
    'tenant-move-out',
];
exports.ValidAutomationAssignMethods = [
    'residents',
    'self-perform',
    'emails',
    'teammates',
];

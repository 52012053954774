"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isInProgress = void 0;
/**
 * Determines wether or not an import is in progress based on the status
 * @param object the import object
 * @returns true if the import is in progress, false otherwise
 */
const isInProgress = (object) => {
    const completedStatuses = ['completed', 'failed'];
    if (completedStatuses.includes(object.status)) {
        return false;
    }
    return true;
};
exports.isInProgress = isInProgress;

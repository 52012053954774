import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CircularProgress, Divider, Tooltip, Typography } from '@mui/material';
import { Utils } from '@rentcheck/biz';
import { colors } from '@rentcheck/theme';
import { Import } from '@rentcheck/types';
import { Column, Row, SpacedRow } from 'components';
import _ from 'lodash';
import styled from 'styled-components';

interface Props {
	object: Import;
}

const Title = ({ object }: Props) => {
	return (
		<Typography mb={1}>
			{_.capitalize(object.operation)} {_.capitalize(object.model)}s
		</Typography>
	);
};

const Subtitle = ({ object }: Props) => {
	if (Utils.Imports.isInProgress(object)) {
		return (
			<Typography variant="body2" noWrap>
				{_.capitalize(object.status)}
			</Typography>
		);
	}

	if (object.status === 'failed') {
		return (
			<Typography variant="body2" noWrap>
				Failed
			</Typography>
		);
	}

	if (object.status === 'completed') {
		const newRecordsCount = object.new_count;
		const updatedRecordsCount = object.updated_count;
		const deletedRecordsCount = object.deleted_count;
		const failedRecordsCount = object.error_count;

		return (
			<Column style={{ gap: 2 }}>
				{newRecordsCount > 0 && (
					<Typography variant="body2" noWrap>
						New Records: {newRecordsCount}
					</Typography>
				)}
				{updatedRecordsCount > 0 && (
					<Typography variant="body2" noWrap>
						Updated Records: {updatedRecordsCount}
					</Typography>
				)}
				{deletedRecordsCount > 0 && (
					<Typography variant="body2" noWrap>
						Deleted Records: {deletedRecordsCount}
					</Typography>
				)}
				{failedRecordsCount > 0 && (
					<Row>
						<Tooltip title="Download errors">
							<a
								href={object.errors_file_path}
								target="_blank"
								rel="noreferrer"
								style={{ textDecoration: 'underline #B80623' }}>
								<Typography variant="body2" noWrap color="#B80623">
									Errors: {failedRecordsCount}
								</Typography>
							</a>
						</Tooltip>
					</Row>
				)}
			</Column>
		);
	}

	return null;
};

export default ({ object }: Props) => {
	return (
		<Column>
			<SpacedRow style={{ padding: '12px 20px' }}>
				<Column
					style={{
						flex: 1,
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}>
					<Title object={object} />
					<Subtitle object={object} />
				</Column>
				<div style={{ marginLeft: 8 }}>
					{Utils.Imports.isInProgress(object) && <CircularProgress size={18} />}

					{object.status === 'completed' && (
						<IconContainer>
							<FontAwesomeIcon
								icon={regular('check')}
								color={colors.success.default}
							/>
						</IconContainer>
					)}

					{object.status === 'failed' && (
						<IconContainer>
							<FontAwesomeIcon
								icon={regular('times')}
								color={colors.error.default}
							/>
						</IconContainer>
					)}
				</div>
			</SpacedRow>
			<Divider />
		</Column>
	);
};

const IconContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 32px;
	height: 32px;
	margin-left: 8px;
`;

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import { useDispatch } from 'react-redux';

import _ from 'lodash';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

const handleDeeplink = _.debounce((dispatch: Dispatch) => {
	Analytics.trackEvent('clicked_deeplink_to_upgrade_plan');
	dispatch(ModalFlowActions.showUpgradeSubscriptionModal());
}, 1000);

export default () => {
	const dispatch = useDispatch<Dispatch>();
	const location = useLocation();

	useEffect(() => {
		if (location.pathname === '/account/billing/plans') {
			handleDeeplink(dispatch);
		}
	}, [location.pathname, dispatch]);

	const handleUpgrade = () => {
		Analytics.trackEvent('selected_button_to_upgrade_plan');
		dispatch(ModalFlowActions.showUpgradeSubscriptionModal());
	};

	return (
		<Button
			variant="outlined"
			onClick={handleUpgrade}
			startIcon={<FontAwesomeIcon icon={solid('sparkles')} />}>
			Explore Plans
		</Button>
	);
};

import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { BillingModalOption } from '@rentcheck/types';
import { ChargebeeApi } from 'api';
import { SubscriptionActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/rootReducer';
import { Dispatch } from 'types';
import { BillingModalPlanVariant, BillingOptionContext } from './context';

import Loading from './steps/loading';
import PlanConfirmation from './steps/plan-confirmation';
import PlanRequest from './steps/plan-request';
import PlanSelection from './steps/plan-selection';
import Success from './steps/success';
import TakingTooLong from './steps/taking-too-long';

interface Props {
	open: boolean;
}

export default ({ open }: Props) => {
	const dispatch = useDispatch<Dispatch>();

	const profile = useTypedSelector((state) => state.activeProfile);
	const subscription = useTypedSelector((state) => state.subscription);
	const canEditPlan = profile?.permissions?.allow_billing_plan_editing;

	const [success, setSuccess] = useState(false);
	const [checkoutClosed, setCheckoutClosed] = useState(false);
	const [profileUpdateError, setProfileUpdateError] = useState(false);

	const [selectedPlan, setSelectedPlan] =
		useState<BillingModalOption['plan']>();
	const [selectedPlanVariant, setSelectedPlanVariant] =
		useState<BillingModalPlanVariant>();

	useEffect(() => {
		if (!profile) return;

		setCheckoutClosed(false);
		setSuccess(false);
		setSelectedPlan(undefined);
		setSelectedPlanVariant(undefined);

		ChargebeeApi.initChargebeeModal(
			profile,
			() => setCheckoutClosed(true),
			() => setSuccess(true)
		);
	}, [profile, open]);

	useEffect(() => {
		if (!success || !checkoutClosed) return;

		dispatch(SubscriptionActions.waitForSubscriptionWebhook()).catch(() =>
			setProfileUpdateError(true)
		);
	}, [success, checkoutClosed]);

	const handleSetselectedPlan = (
		plan?: BillingModalOption['plan'],
		variant?: BillingModalPlanVariant
	) => {
		setSelectedPlan(plan);
		setSelectedPlanVariant(variant);
	};

	if (success && checkoutClosed) {
		if (subscription) {
			return <Success open={open} />;
		}

		if (profileUpdateError) {
			return <TakingTooLong open={open} />;
		}

		return <Loading open={open} />;
	}

	if (selectedPlan && selectedPlanVariant) {
		return (
			<BillingOptionContext.Provider value={handleSetselectedPlan}>
				{canEditPlan ? (
					<PlanConfirmation
						open={open}
						selectedPlan={selectedPlan}
						selectedPlanVariant={selectedPlanVariant}
						onUpgradeSuccess={() => {
							setSuccess(true);
							setCheckoutClosed(true);
						}}
					/>
				) : (
					<PlanRequest
						open={open}
						selectedPlan={selectedPlan}
						selectedPlanVariant={selectedPlanVariant}
					/>
				)}
			</BillingOptionContext.Provider>
		);
	}

	return (
		<BillingOptionContext.Provider value={handleSetselectedPlan}>
			<PlanSelection open={open} />
		</BillingOptionContext.Provider>
	);
};

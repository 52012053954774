import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Divider,
	Tooltip,
	Typography,
} from '@mui/material';
import { useEffect, useRef, useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { ApiTenant } from '@rentcheck/types';

import { DialogTitle } from 'components';
import { useResidentModalData } from 'hooks/modals';
import { ModalFlowActions } from 'store/actions';
import { Dispatch } from 'types';

import { formatDateAndTime } from '../../../utils/helpers';
import DeleteResident, { DeleteResidentRef } from './delete-resident';
import Leases, { LeasesRef } from './leases';
import ResidentCreate from './resident-create';
import ResidentInfo from './resident-info';

const SyncStatus = ({ resident }: { resident?: ApiTenant }) => {
	if (!resident) return null;

	let vendor: string | null = null;

	if (!resident.sync_data) {
		return null;
	}

	switch (resident.sync_data.vendor) {
		case 'appfolio':
			vendor = 'AppFolio';
			break;

		case 'rentmanager':
			vendor = 'Rent Manager';
			break;

		case 'yardi':
			vendor = 'Yardi';
			break;
	}

	return (
		<Tooltip
			title={`Last synced on ${formatDateAndTime(resident?.sync_data?.last_sync)}`}>
			<Typography variant="inherit">Synced with {vendor}</Typography>
		</Tooltip>
	);
};

interface Props {
	open: boolean;
}

const ResidentModal = ({ open }: Props) => {
	const dispatch: Dispatch = useDispatch();
	const modalData = useResidentModalData();
	const history = useHistory();

	const resident = modalData?.metadata.resident;

	const deleteResidentRef = useRef<DeleteResidentRef>(null);
	const leasesRef = useRef<LeasesRef>(null);

	const [showLease, setShowLease] = useState(false);

	useEffect(() => {
		if (showLease) {
			/**
			 * Delay the creation of the lease to allow the modal to fully open
			 * and the leases component to be mounted
			 */
			setTimeout(() => {
				leasesRef.current?.create();
			}, 300);
		}
	}, [showLease]);

	useEffect(() => {
		setShowLease(false);
	}, [open]);

	if (!resident && open) {
		return <ResidentCreate setShowLease={() => setShowLease(true)} />;
	}

	if (!resident) {
		return null;
	}

	const handleAddLease = () => {
		leasesRef.current?.create();
	};

	const handleClose = () => {
		if (history.location.pathname.startsWith('/residents/')) {
			history.push('/residents');
		}

		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleDelete = () => {
		deleteResidentRef.current?.delete();
	};

	return (
		<Dialog open={open} onClose={handleClose}>
			<DialogTitle
				title="Resident Details"
				icon={solid('user-circle')}
				onClose={handleClose}
				bottomSubtitle={<SyncStatus resident={resident} />}
			/>

			<DialogContent>
				<ResidentInfo
					resident={resident}
					onAddLease={handleAddLease}
					onRemoveResident={handleDelete}
				/>
				<Divider style={{ marginTop: 24, marginBottom: 24 }} />
				<Leases ref={leasesRef} resident={resident} />
			</DialogContent>

			<DialogActions>
				<DeleteResident ref={deleteResidentRef} resident={resident} />

				<Button
					id="button-confirm"
					variant="contained"
					onClick={handleAddLease}>
					Add Lease
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ResidentModal;

import {
	TemplateFeature,
	TemplateQuestion,
	TemplateQuestionAnswer,
	TemplateSection,
	assetCategories,
} from '@rentcheck/types';

export const areAnswersIncomplete = (
	answers: TemplateQuestionAnswer[]
): boolean => answers.some((answer) => !answer.title.trim());

export const isMissingAssetCategory = (question: TemplateQuestion) => {
	if (question.type !== 'asset capture') return false;

	if (!question.asset_category?.length) return true;

	return !assetCategories.includes(question.asset_category);
};

export const areQuestionsIncomplete = (
	questions: TemplateQuestion[]
): boolean =>
	questions.some(
		(question) =>
			!question.title.trim() ||
			isMissingAssetCategory(question) ||
			areAnswersIncomplete(question.answers)
	);

export const isFeatureIncomplete = (feature: TemplateFeature): boolean =>
	!feature.name.trim() ||
	!feature.description.trim() ||
	areQuestionsIncomplete(feature.questions);

export const containsIncompleteFeatures = (
	object: TemplateSection | TemplateSection[]
): boolean => {
	if (Array.isArray(object)) {
		return object.some((section) => containsIncompleteFeatures(section));
	}

	return object.features.some(isFeatureIncomplete);
};

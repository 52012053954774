var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import APIConfig from '../config';
import { request } from '../request';
import { buildFileRequestFilters } from '../utils/filters';
export const create = (documentType, documentId, exportType, filters, dontNotify) => __awaiter(void 0, void 0, void 0, function* () {
    const value = yield request({
        method: 'post',
        url: `/fileRequests`,
        data: {
            document_type: documentType,
            document_id: documentId,
            export_type: exportType,
            dont_notify: dontNotify,
            filters: buildFileRequestFilters(documentType, filters),
        },
        params: {
            user_id: APIConfig.getUserId(),
        },
    });
    return value.data.data;
});
export default { create };

import { Dialog, DialogActions } from '@mui/material';
import { useEffect, useState } from 'react';

import { FlatFileImportContext, FlatFileImportContextType } from '../context';
import CancelButton from './actions/cancel-button';
import NextButton from './actions/next-button';

import { ImportModel } from '@rentcheck/types';
import { useHistory } from 'react-router-dom';

import DialogTitle from './title';

import Complete from './steps/complete';
import EmbeddedFlatfile, {
	EmbeddedFlatfileProps,
} from './steps/embedded-flatfile';
import SelectOperation, {
	SelectOperationProps,
} from './steps/select-operation';
import UploadToRentCheck from './steps/upload-to-rentcheck';

interface Props {
	open: boolean;
	setOpen: (open: boolean) => void;

	model: ImportModel;

	selectOperationProps: SelectOperationProps[];
	embeddedFlatfileProps: EmbeddedFlatfileProps;
}

export default ({
	open,
	setOpen,
	model,
	selectOperationProps,
	embeddedFlatfileProps,
}: Props) => {
	const history = useHistory();

	const [step, setStep] = useState<FlatFileImportContextType['step']>(0);
	const [operation, setOperation] =
		useState<FlatFileImportContextType['operation']>();
	const [pms, setPms] = useState<FlatFileImportContextType['pms']>('rentcheck');
	const [processedRecords, setProcessedRecords] = useState<
		FlatFileImportContextType['processedRecords']
	>([]);

	useEffect(() => {
		if (open) {
			setStep(0);
			setOperation(selectOperationProps.find((o) => o.default)?.operation);
			setPms('rentcheck');
			setProcessedRecords([]);

			history.replace(`/account/imports/${model}`);
		}
	}, [open, history, model]);

	useEffect(() => {
		if (!open) {
			return;
		}

		if (step > 0) {
			history.replace(`/account/imports/${model}/${operation}`);
		}

		if (step === 0) {
			history.replace(`/account/imports/${model}`);
		}
	}, [operation, step, history, model, open]);

	const handleClose = () => {
		setOpen(false);
		history.replace('/account/imports');
	};

	return (
		<FlatFileImportContext.Provider
			value={{
				model,
				operation,
				setOperation,
				pms,
				setPms,
				step,
				setStep,
				processedRecords,
				setProcessedRecords,
			}}>
			<Dialog open={open} onClose={handleClose} fullScreen>
				<DialogTitle onClose={handleClose} />

				<SelectOperation selectOperationProps={selectOperationProps} />
				<EmbeddedFlatfile sheetConfig={embeddedFlatfileProps.sheetConfig} />
				<UploadToRentCheck />
				<Complete />

				<DialogActions>
					<CancelButton setOpen={setOpen} />
					<NextButton onClose={handleClose} />
				</DialogActions>
			</Dialog>
		</FlatFileImportContext.Provider>
	);
};

import { useIntercom } from 'react-use-intercom';

import { Constants } from '@rentcheck/biz';
import { Button, PlanGate } from 'components';

const Inactive = () => {
	const { showNewMessage } = useIntercom();

	const handleChatToInstall = () => {
		showNewMessage();
	};

	return (
		<PlanGate
			variant="professional"
			addonIds={Constants.Addons.RentManager.All}>
			<Button onClick={handleChatToInstall}>Install</Button>
		</PlanGate>
	);
};

export default Inactive;

import { firebaseReducer } from 'react-redux-firebase';
import { combineReducers } from 'redux';

import authenticatedUserReducer from './authenticatedUserReducer';
import featuresReducer from './features';
import inviteReducer from './inviteReducer';

import accountSettingsReducer from './account-settings';
import activeProfileReducer from './active-profile';
import dashboardReducer from './dashboard';
import fileRequestsReducer from './file-requests';
import importsReducer from './imports';
import inspectionTemplatesReducer from './inspection-templates';
import inspectionsReducer from './inspections';
import maintenanceReducer from './maintenance';
import modalFlowsReducer from './modal-flows';
import propertiesReducer from './properties';
import sessionOptionsReducer from './session-options';
import snackbarReducer from './snackbar';
import subscriptionReducer from './subscription';
import teamReducer from './team';
import teammatesReducer from './teammates';
import teamsReducer from './teams';
import tenantsReducer from './tenants';
import userSettingsReducer from './user-settings';

import { RootState as RootStateT, useTypedSelector } from './common';

const rootReducer = combineReducers({
	accountSettings: accountSettingsReducer,
	activeProfile: activeProfileReducer,
	authenticatedUser: authenticatedUserReducer,
	dashboard: dashboardReducer,
	features: featuresReducer,
	fileRequests: fileRequestsReducer,
	firebase: firebaseReducer,
	inspections: inspectionsReducer,
	inspectionTemplates: inspectionTemplatesReducer,
	properties: propertiesReducer,
	maintenance: maintenanceReducer,
	invites: inviteReducer,
	imports: importsReducer,
	modalFlows: modalFlowsReducer,
	team: teamReducer,
	teams: teamsReducer,
	teammates: teammatesReducer,
	sessionOptions: sessionOptionsReducer,
	snackbar: snackbarReducer,
	subscription: subscriptionReducer,
	tenants: tenantsReducer,
	userSettings: userSettingsReducer,
});

export type RootState = RootStateT;
export { useTypedSelector };

export default rootReducer;

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogContent, Typography } from '@mui/material';
import {
	CSSProperties,
	ReactNode,
	ReactNodeArray,
	useEffect,
	useRef,
	useState,
} from 'react';
import { useDispatch } from 'react-redux';

import { Utils } from '@rentcheck/biz';

import { ModalFlowActions } from 'store/actions';
import { useTypedSelector } from 'store/reducers/common';
import { Dispatch } from 'types';
import { Analytics } from 'utils';

import IconButton from '../icon-button';
import { CenteredColumn } from '../layout/Column';
import { StartFromEndRow } from '../layout/Row';
import Spacer from '../Spacer';
import { AddonId } from './addons';
import { PlanGateContext } from './context';
import { customizations } from './customizations';

export interface PlanGateProps {
	/**
	 * The variant of the plan gate
	 * This is one of the plans that the user can upgrade to
	 */
	variant: 'any' | 'essential' | 'professional';

	/**
	 * An addon list that enables the user to acess the children
	 * feature regardless of plan
	 */
	addonIds?: AddonId[];

	/**
	 * The children component that contains the feature
	 */
	children: ReactNode | ReactNodeArray;

	/**
	 * Optional style to be applied to the container
	 */
	style?: CSSProperties;
}

export default ({
	variant,
	addonIds = [],
	children,
	style = {},
}: PlanGateProps) => {
	const dispatch: Dispatch = useDispatch();

	const subscription = useTypedSelector((state) => state.subscription);

	const ref = useRef<HTMLDivElement>(null);

	const [open, setOpen] = useState(false);

	const customization = customizations[variant];

	useEffect(() => {
		const handleClickInside = (event: any) => {
			if (!ref.current || ref.current.contains(event.target)) {
				setOpen(true);
			}
		};

		document.addEventListener('mousedown', handleClickInside);

		return () => {
			document.removeEventListener('mousedown', handleClickInside);
		};
	}, [ref]);

	const handleClose = (e?: {}) => {
		if (!e) return;

		const event = e as { stopPropagation: () => void };
		event.stopPropagation();
		setOpen(false);
	};

	const handleLearnMore = (event?: React.MouseEvent<HTMLButtonElement>) => {
		handleClose(event);

		Analytics.trackEvent('selected_button_to_upgrade_plan');
		dispatch(ModalFlowActions.showUpgradeSubscriptionModal());
	};

	const canAccessGatedFeature = Utils.Subscriptions.hasfeatureAccess(
		subscription,
		variant,
		addonIds
	);

	if (canAccessGatedFeature) {
		return (
			<PlanGateContext.Provider value={{ accessAllowed: true }}>
				{children}
			</PlanGateContext.Provider>
		);
	}
	return (
		<PlanGateContext.Provider value={{ accessAllowed: false }}>
			<div ref={ref} style={{ display: 'flex', ...style }}>
				{children}
			</div>

			<Dialog open={open} onClose={handleClose} maxWidth="sm">
				<DialogContent
					style={{ background: customization.styles.gradient }}
					onClick={(e) => e.stopPropagation()}>
					<StartFromEndRow>
						<IconButton
							icon={solid('times')}
							onClick={handleClose}
							buttonSx={{ color: customization.styles.secondaryColor }}
						/>
					</StartFromEndRow>
					<Spacer height={5} />

					<CenteredColumn style={{ textAlign: 'center' }}>
						<FontAwesomeIcon
							icon={solid('sparkles')}
							color={customization.styles.primaryColor}
							size="2xl"
						/>
						<Spacer height={5} />

						<Typography variant="h4" color={customization.styles.primaryColor}>
							{customization.text}
						</Typography>
						<Spacer height={5} />

						<Typography
							variant="subtitle2"
							color={customization.styles.primaryColor}>
							Upgrade to access this feature.
						</Typography>
						<Spacer height={10} />

						<Button
							onClick={handleLearnMore}
							variant="outlined"
							sx={{
								borderColor: customization.styles.primaryColor,
								color: customization.styles.primaryColor,
							}}>
							Learn more
						</Button>
						<Spacer height={10} />
					</CenteredColumn>
				</DialogContent>
			</Dialog>
		</PlanGateContext.Provider>
	);
};

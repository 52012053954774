import { Grid, Typography } from '@mui/material';
import { useContext } from 'react';

import { CenteredColumn } from 'components';
import {
	FlatFileImportContext,
	FlatFileImportContextType,
} from '../../../context';
import ImportTypeCta from '../../../import-type-cta';
import { PMSOption, pmsOptions } from './constants';

export default () => {
	const context = useContext(FlatFileImportContext);

	const validOperationTypes: FlatFileImportContextType['operation'][] = [
		'create',
		'create/update',
	];

	if (!validOperationTypes.includes(context.operation)) {
		return null;
	}

	const handleClicked = (value: PMSOption) => {
		context.setPms(value.value);
	};

	return (
		<CenteredColumn style={{ width: '100%' }}>
			<Typography variant="h6" mt={4}>
				Do you use a Property Management System?
			</Typography>
			<Grid
				container
				columns={{ xs: 2, sm: 3, lg: 6 }}
				spacing={2}
				sx={{ marginTop: 1 }}>
				{pmsOptions.map((option) => (
					<ImportTypeCta
						key={option.value}
						title={option.name}
						image={option.image}
						selected={context.pms === option.value}
						onClick={() => handleClicked(option)}
					/>
				))}
			</Grid>
		</CenteredColumn>
	);
};

import { LoadingButton } from '@mui/lab';
import { Button, DialogActions, DialogContent } from '@mui/material';
import { WorkOrdersApi } from '@rentcheck/api-frontend';
import { useCreateWorkOrderModalData } from 'hooks/modals';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ModalFlowActions, SnackbarActions } from 'store/actions';
import { Dispatch } from 'types';

import {
	isEmbeddedInMobileApp,
	numberAndPluralizeWord,
	postMessageToNativeApp,
	workOrderDefaultDescription,
} from 'utils/helpers';
import { getModalConfig } from '../../common/modal-config';
import { handleNativeAppClose } from '../../common/native-app';
import { WorkOrderInfo } from '../common';
import WorkOrder from './work-order';

const Content = () => {
	const dispatch: Dispatch = useDispatch();

	const modalData = useCreateWorkOrderModalData('Rent Manager');
	const { flags, provider } = modalData?.metadata ?? {};

	const [loading, setLoading] = useState(false);
	const [workOrdersInfo, setWorkOrdersInfo] = useState<
		Record<string, WorkOrderInfo>
	>({});

	const modalConfig = getModalConfig(provider);

	useEffect(() => {
		const groupedFlags = _.groupBy([...flags, ...flags], 'skill');
		const skills = _.keys(groupedFlags);

		const newInfo: Record<string, WorkOrderInfo> = {};

		skills.forEach((s) => {
			const flags = groupedFlags[s];
			const payload: WorkOrderInfo = {
				okToEnter: 'No',
				priority: 'Normal',
				description: flags.map(workOrderDefaultDescription).join('\n'),
			};

			newInfo[s] = payload;
		});

		setWorkOrdersInfo(newInfo);
	}, [flags]);

	const handleClose = () => {
		handleNativeAppClose();
		dispatch(ModalFlowActions.closeCurrentModal());
	};

	const handleSetWorkOrderInfo = (skill: string, info: WorkOrderInfo) => {
		const newInfo = { ...workOrdersInfo };
		newInfo[skill] = info;

		setWorkOrdersInfo(newInfo);
	};

	const handleSuccess = (count: number) => {
		if (isEmbeddedInMobileApp()) {
			postMessageToNativeApp({
				type: 'created-work-order',
				message: `Success! ${numberAndPluralizeWord(
					count,
					modalConfig.mappedWorkOrderName
				)} sent to ${modalConfig.vendor}`,
			});

			handleNativeAppClose();
		}

		dispatch(ModalFlowActions.closeCurrentModal());
		dispatch(
			SnackbarActions.showSuccess(
				`${numberAndPluralizeWord(
					count,
					modalConfig.mappedWorkOrderName
				)} sent to ${modalConfig.vendor}`
			)
		);
	};

	const handleCreate = () => {
		if (!flags.length) {
			return;
		}

		setLoading(true);

		const skills = _.uniq(flags.map((f) => f.skill));

		Promise.all(
			skills.map((s) => {
				const workOrder = workOrdersInfo[s];
				const flagsForSkill = flags.filter((f) => f.skill === s);

				return WorkOrdersApi.create(
					modalConfig.getCreateParams(flagsForSkill, workOrder)
				);
			})
		)
			.then(() => handleSuccess(skills.length))
			.catch((e) => dispatch(SnackbarActions.showError(e)))
			.finally(() => setLoading(false));
	};

	const disabled = !!_.values(workOrdersInfo).find((wo) => !wo.description);
	const groupedFlags = _.groupBy(flags, 'skill');
	const skills = _.keys(groupedFlags);

	return (
		<>
			<DialogContent>
				{skills.map((s, idx) => (
					<WorkOrder
						skill={s}
						index={idx}
						total={skills.length}
						flags={groupedFlags[s]}
						isLast={idx === skills.length - 1}
						onChange={(v) => handleSetWorkOrderInfo(s, v)}
						key={s}
					/>
				))}
			</DialogContent>
			<DialogActions>
				<Button color="secondary" variant="outlined" onClick={handleClose}>
					Dismiss
				</Button>
				<LoadingButton
					disabled={disabled}
					variant="contained"
					loading={loading}
					onClick={handleCreate}>
					Create
				</LoadingButton>
			</DialogActions>
		</>
	);
};

export default Content;

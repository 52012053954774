import { WorkOrdersApi } from '@rentcheck/api-frontend';

export const pageSize = 20;

export const getWorkOrdersForPropertyId = async (
	offset: number = 0,
	propertyId: string
) => {
	const pageNumber = Math.floor(offset / pageSize);

	const filters = {
		search: '',
		inspectionTemplate: [],
		dateRange: undefined,
		status: [],
		propertyId,
	};

	const sorting = {
		sortBy: 'created_date',
		sortType: 'desc' as 'desc',
	};

	const { data, totalResults } = await WorkOrdersApi.getAll(
		pageNumber,
		pageSize,
		filters,
		sorting
	);

	return { data, totalResults };
};

import { regular } from '@fortawesome/fontawesome-svg-core/import.macro';
import { CircularProgress } from '@mui/material';
import { Column, Row } from 'components';
import IconButton, { IconButtonRef } from 'components/icon-button';
import { useIsTopModal } from 'hooks/modals';
import _ from 'lodash';
import React, {
	forwardRef,
	useCallback,
	useEffect,
	useImperativeHandle,
	useRef,
	useState,
} from 'react';
import { imageUrlFromGCPUri } from 'utils/helpers';
import { useKeyPress } from 'utils/hooks';

import { ViewerProps } from '../common';
import TopControls from '../top-controls';

const zoomStep = 0.5;
const minZoom = 1;
const maxZoom = 5;

type PositionT = { x: number; y: number; oldX: number; oldY: number };

const defaultScale = 1.0;
const defaultPosition: PositionT = { x: 0, y: 0, oldX: 0, oldY: 0 };
const initialImageSize = { width: 0, height: 0 };

export interface FeatureImageViewerRef {
	getScale: () => number;
	getPosition: () => PositionT;
}

export default forwardRef<FeatureImageViewerRef, ViewerProps>((props, ref) => {
	const {
		imageUri,
		feature,
		handleNext,
		handlePrevious,
		mode,
		initialScale,
		initialPosition,
	} = props;

	const isTopModal = useIsTopModal('Feature Images');

	const containerRef = useRef<HTMLDivElement>(null);
	const prevButtonRef = useRef<IconButtonRef>(null);
	const nextButtonRef = useRef<IconButtonRef>(null);

	const [scale, setScale] = useState(initialScale ?? defaultScale);
	const [position, setPosition] = useState(initialPosition ?? defaultPosition);
	const [isPanning, setIsPanning] = useState(false);
	const [imageSize, setImageSize] = useState(initialImageSize);

	useImperativeHandle(ref, () => ({
		getScale: () => scale,
		getPosition: () => position,
	}));

	useEffect(() => {
		const mouseup = () => {
			setIsPanning(false);
		};

		const mousemove = (event: MouseEvent) => {
			if (isPanning) {
				const maxDelta = getMaxDeltas();

				/**
				 * Calculate the maxDelta and clamp for each direction so the
				 * image can't be taken off bounds of panned outside the container
				 * if not needed
				 */
				const newX = _.clamp(
					position.x + event.clientX - position.oldX,
					-maxDelta.x / (2 * scale),
					maxDelta.x / (2 * scale)
				);

				const newY = _.clamp(
					position.y + event.clientY - position.oldY,
					-maxDelta.y / (2 * scale),
					maxDelta.y / (2 * scale)
				);

				setPosition({
					...position,
					x: newX,
					y: newY,
					oldX: event.clientX,
					oldY: event.clientY,
				});
			}
		};

		window.addEventListener('mouseup', mouseup);
		window.addEventListener('mousemove', mousemove);

		return () => {
			window.removeEventListener('mouseup', mouseup);
			window.removeEventListener('mousemove', mousemove);
		};
	});

	useEffect(() => {
		setScale(initialScale ?? defaultScale);
		setPosition(initialPosition ?? defaultPosition);
		setImageSize(initialImageSize);
	}, [imageUri]);

	useKeyPress((event: { keyCode: number }) => {
		if (!isTopModal) {
			return;
		}

		// LEFT Arrow
		if (event.keyCode === 37) {
			prevButtonRef.current?.click();
		}

		// RIGHT Arrow
		if (event.keyCode === 39) {
			nextButtonRef.current?.click();
		}
	});

	const getMaxDeltas = useCallback(
		(newScale?: number) => {
			const sc = newScale ?? scale;

			const contWidth = containerRef.current?.clientWidth ?? 0;
			const contHeight = containerRef.current?.clientHeight ?? 0;

			const maxDeltaX = Math.max(imageSize.width * sc - contWidth, 0);
			const maxDeltaY = Math.max(imageSize.height * sc - contHeight, 0);

			return { x: maxDeltaX, y: maxDeltaY };
		},
		[scale, imageSize]
	);

	const handleZoomIn = () => {
		const newScale = _.clamp(scale + zoomStep, minZoom, maxZoom);
		setScale(newScale);
	};

	const handleZoomOut = () => {
		const newScale = _.clamp(scale - zoomStep, minZoom, maxZoom);
		setScale(newScale);

		/**
		 * On zoom out we need to clamp the position again because the
		 * bounds have gotten smaller, to replicate this zoom in, then pan
		 * to as much as you can in any direction, then zoom out again.
		 */
		const maxDelta = getMaxDeltas(newScale);

		setPosition({
			...position,
			x: _.clamp(
				position.x,
				-maxDelta.x / (2 * newScale),
				maxDelta.x / (2 * newScale)
			),
			y: _.clamp(
				position.y,
				-maxDelta.y / (2 * newScale),
				maxDelta.y / (2 * newScale)
			),
		});
	};

	const handleMouseDown = (e: React.MouseEvent<HTMLImageElement>) => {
		e.preventDefault();

		setIsPanning(true);
		setPosition({ ...position, oldX: e.clientX, oldY: e.clientY });
	};

	const handleLoad = (e: any) => {
		setImageSize({
			width: e.target.clientWidth,
			height: e.target.clientHeight,
		});
	};

	return (
		<Column style={{ flex: 1, height: '100%' }}>
			<TopControls
				imageUri={imageUri}
				feature={feature}
				handleZoomIn={handleZoomIn}
				handleZoomOut={handleZoomOut}
				mode={mode}
			/>
			<Row style={{ flex: 1, width: '100%', height: 'calc(100% - 44px)' }}>
				<IconButton
					ref={prevButtonRef}
					icon={regular('arrow-left')}
					buttonSx={{ marginLeft: 0, marginRight: 1 }}
					onClick={handlePrevious}
					disabled={mode === 'edit'}
				/>
				<div
					ref={containerRef}
					style={{
						flex: 1,
						height: '100%',
						overflow: 'hidden',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}>
					<CircularProgress style={{ position: 'absolute' }} />
					<img
						alt=""
						key={imageUri}
						src={imageUrlFromGCPUri(imageUri)}
						onMouseDown={handleMouseDown}
						onLoad={handleLoad}
						style={{
							objectFit: 'contain',
							maxHeight: '100%',
							maxWidth: '100%',
							cursor: isPanning ? 'grabbing' : 'grab',
							transform: `scale(${scale}) translate(${position.x}px, ${position.y}px)`,
							transition: 'scale 0.15s',
						}}
					/>
				</div>
				<IconButton
					ref={nextButtonRef}
					icon={regular('arrow-right')}
					onClick={handleNext}
					disabled={mode === 'edit'}
				/>
			</Row>
		</Column>
	);
});

import {
	FirebaseCreatePayload,
	FirebaseUpdatePayload,
	InspectionTemplate,
} from '@rentcheck/types';
import firebase from 'config/firebase';

import { objectFromDocumentSnapshot } from './helpers';

export const create = async (
	payload: FirebaseCreatePayload<InspectionTemplate>
) => {
	const ref = firebase.firestore().collection('inspection_templates').doc();
	const id = ref.id;

	await ref.set({ ...payload, created_at: new Date(), id });

	return getById(id);
};

export const getById = async (id: string) =>
	firebase
		.firestore()
		.collection('inspection_templates')
		.doc(id)
		.get()
		.then((doc) => objectFromDocumentSnapshot<InspectionTemplate>(doc));

export const update = async (
	id: string,
	payload: FirebaseUpdatePayload<InspectionTemplate>
) => {
	const ref = firebase.firestore().collection('inspection_templates').doc(id);

	await ref.update(payload);

	return getById(id);
};

export const deleteTemplate = async (id: string) =>
	firebase
		.firestore()
		.collection('inspection_templates')
		.doc(id)
		.update({ team_ids: [] });

import { sheetConfig as appfolio } from './appfolio';
import { sheetConfig as buildium } from './buildium';
import { sheetConfig as propertyware } from './propertyware';
import { sheetConfig as rentmanager } from './rent-manager';
import { sheetConfig as rentcheck } from './rentcheck';
import { sheetConfig as rentvine } from './rentvine';

import { EmbeddedFlatfileProps } from '../../common/base-dialog/steps/embedded-flatfile';

const blueprint: EmbeddedFlatfileProps = {
	sheetConfig: {
		appfolio,
		buildium,
		propertyware,
		rentmanager,
		rentvine,
		rentcheck,
	},
};

export default blueprint;

import APIConfig from '../config';
import { request } from '../request';
const createRevShareProspect = (prospectEmail, name, units, plan_id, addons) => request({
    method: 'post',
    url: `/sales/rev-share-prospects`,
    params: {
        user_id: APIConfig.getUserId(),
    },
    data: {
        prospect_email: prospectEmail,
        user_name: name,
        total_properties: units,
        chargebee_plan_id: plan_id,
        chargebee_addon_ids: addons.map((a) => a.id),
    },
    omitAuthorization: true,
}).then((value) => value.data.data);
export default { createRevShareProspect };

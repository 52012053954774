import { Paper, Skeleton, Typography } from '@mui/material';
import { Column, SpacedRow, Spacer } from 'components';

interface Props {
	title: string;
	subtitle?: string;
	children: React.ReactNode | React.ReactNodeArray;
	columns?: 1 | 2;
	loading?: boolean;
}
const Container = ({
	title,
	subtitle,
	children,
	loading,
	columns = 2,
}: Props) => {
	return (
		<Paper
			elevation={0}
			style={{ position: 'relative' }}
			sx={{
				marginRight: 2,
				flex: 1,
				minWidth: 282,
				height: 118,
				padding: 2.5,
				border: '1px solid #CCD6E1',
				':last-child': {
					marginRight: 0,
				},
			}}>
			{columns === 1 && (
				<Column
					style={{
						height: '100%',
						width: '80%',
						justifyContent: 'space-between',
					}}>
					<Typography variant="subtitle1">{title}</Typography>
					<Typography color="#445567">{subtitle}</Typography>
					{children}
				</Column>
			)}
			{columns === 2 && (
				<SpacedRow style={{ height: '100%' }}>
					<Column style={{ height: '100%', justifyContent: 'space-between' }}>
						<Typography variant="subtitle1">{title}</Typography>
						<Typography color="#445567">{subtitle}</Typography>
					</Column>
					<Spacer width={4} />
					<Column
						style={{
							height: '100%',
							justifyContent: 'space-between',
							alignItems: 'flex-end',
						}}>
						{children}
					</Column>
				</SpacedRow>
			)}

			{loading && (
				<Skeleton
					variant="rectangular"
					style={{
						position: 'absolute',
						top: 0,
						right: 0,
						width: '100%',
						backgroundColor: '#fffa',
					}}
					sx={{ borderRadius: 1 }}
					height={116}
				/>
			)}
		</Paper>
	);
};

export default Container;

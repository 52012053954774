"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.isPublished = exports.hasCustomTemplates = exports.sortFn = exports.parseSelectedSectionName = exports.parseTemplateRoomName = void 0;
const dates_1 = require("../dates");
const SectionsUtils = __importStar(require("../sections"));
/**
 * Converts the template room name from plural to singular, this is to
 * account for legacy functionality where the template section name is
 * plural and the feature section name is singular.
 * @param name the section name
 * @returns the singular version of the template section name
 */
const parseTemplateRoomName = (name) => {
    if (name === 'Floors') {
        return 'Floor';
    }
    if (name === 'Full Bathrooms') {
        return 'Full Bathroom';
    }
    if (name === 'Half Bathrooms') {
        return 'Half Bathroom';
    }
    if (name === 'Bedrooms') {
        return 'Bedroom';
    }
    return name;
};
exports.parseTemplateRoomName = parseTemplateRoomName;
/**
 * Parses the section name to remove the ordinal and return the template section name.
 *
 * @param name the section name including ordinal.
 * Examples: 1st Floor, 2nd Floor, 3rd Floor, 4th Floor, 5th Floor,
 * First Full Bathroom, Second Full Bathroom, Third Full Bathroom, Fourth Full Bathroom, Fifth Full Bathroom,
 * First Half Bathroom, Second Half Bathroom, Third Half Bathroom, Fourth Half Bathroom, Fifth Half Bathroom,
 * First Bedroom, Second Bedroom, Third Bedroom, Fourth Bedroom, Fifth Bedroom
 *
 * @returns The template section name without the ordinal.
 * Examples: 1st Floor -> Floors, First Full Bathroom -> Full Bathrooms, etc.
 */
const parseSelectedSectionName = (name) => {
    if (SectionsUtils.isFloor(name)) {
        return 'Floors';
    }
    if (SectionsUtils.isFullBathroom(name)) {
        return 'Full Bathrooms';
    }
    if (SectionsUtils.isHalfBathroom(name)) {
        return 'Half Bathrooms';
    }
    if (SectionsUtils.isBedroom(name)) {
        return 'Bedrooms';
    }
    return name;
};
exports.parseSelectedSectionName = parseSelectedSectionName;
/**
 * Function used to determine the order of 2 inspection templates.
 * Follows this criteria:
 *  1. RentCheck templates should always be at the bottom
 *  2. Custom templates should be sorted alphabetically
 *  3. RentCheck templates should be sorted by created date
 *
 * @param a an inspection template
 * @param b a different inspection template
 * @returns It is expected to return a negative value if the first argument
 *  is less than the second argument, zero if they're equal, and a positive
 *  value otherwise
 */
const sortFn = (a, b) => {
    /**
     * If both templates are rentcheck templates then the order is pre-determined
     * in the requirements for REN-3522. In this case we sort by created date as this
     * order is already taken into account in the script that creates the documents
     */
    if (a.is_rc_template && b.is_rc_template) {
        const aDate = (0, dates_1.buildDateFromApiOrFirebase)(a.created_at);
        const bDate = (0, dates_1.buildDateFromApiOrFirebase)(b.created_at);
        return bDate.getTime() - aDate.getTime();
    }
    /**
     * If both templates are custom templates then the order is alphabetical
     */
    if (!a.is_rc_template && !b.is_rc_template) {
        return a.name.localeCompare(b.name);
    }
    /**
     * All RentCheck Templates should sort at the bottom, leaving custom templates on
     * top
     */
    if (a.is_rc_template) {
        return 1;
    }
    return -1;
};
exports.sortFn = sortFn;
const hasCustomTemplates = (templates) => {
    return templates.some((t) => !t.is_rc_template);
};
exports.hasCustomTemplates = hasCustomTemplates;
const isPublished = (template) => {
    return template.is_rc_template || template.is_published;
};
exports.isPublished = isPublished;

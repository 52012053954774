var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { request } from '../request';
import { millisToString } from '../utils/filters';
const get = (filters, config) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b;
    return request({
        method: 'get',
        url: '/dashboard',
        params: {
            // filters
            team_id: filters.team,
            property_id: filters.building,
            property_type: filters.propertyType,
            date_range_start: millisToString((_a = filters.dateRange) === null || _a === void 0 ? void 0 : _a.start),
            date_range_end: millisToString((_b = filters.dateRange) === null || _b === void 0 ? void 0 : _b.end),
        },
        appConfig: config,
    }).then((response) => {
        return response.data.data;
    });
});
const todoItemResources = [
    'inspectionsAwaitingReview',
    'overdueInspections',
    'almostDueInspections',
    'undeliveredEmails',
    'revisionRequestedInspections',
    'awaitingSignatureInspections',
    'propertiesAtRisk',
    'unassignedInspections',
];
const getTodoItem = (resource, sortBy, sortType, page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/dashboard/${resource}`,
        params: {
            page_size: pageSize,
            page_number: page,
            sort_by: sortBy,
            sort_type: sortType,
        },
        appConfig: config,
    }).then((response) => {
        return response.data.data.data;
    });
});
const getAwaitingReview = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('inspectionsAwaitingReview', 'due_date', 'DESC', page, pageSize, config);
});
const getAlmostDue = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('almostDueInspections', 'due_date', 'DESC', page, pageSize, config);
});
const getUnassigned = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('unassignedInspections', 'due_date', 'DESC', page, pageSize, config);
});
const getAwaitingSignature = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('awaitingSignatureInspections', 'due_date', 'DESC', page, pageSize, config);
});
const getOverdue = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('overdueInspections', 'due_date', 'DESC', page, pageSize, config);
});
const getUndeliveredEmails = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('undeliveredEmails', 'due_date', 'DESC', page, pageSize, config);
});
const getRevisionRequested = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('revisionRequestedInspections', 'due_date', 'DESC', page, pageSize, config);
});
const getAtRisk = (page, pageSize, config) => __awaiter(void 0, void 0, void 0, function* () {
    return getTodoItem('propertiesAtRisk', 'address', 'ASC', page, pageSize, config);
});
export default {
    get,
    getAwaitingReview,
    getAwaitingSignature,
    getAlmostDue,
    getUnassigned,
    getOverdue,
    getRevisionRequested,
    getUndeliveredEmails,
    getAtRisk,
};

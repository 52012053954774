var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import APIConfig from '../config';
import { request } from '../request';
import { prepareFiltersForQueryParams } from '../utils/filters';
export const getAll = (includeFullTemplate, pagination = { page_number: 0, page_size: 20 }, filters, sort = { sort_by: 'due_date', sort_type: 'DESC' }, config) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: '/inspections',
        version: 'v2',
        params: Object.assign(Object.assign({ include_full_template_info: includeFullTemplate, filters: prepareFiltersForQueryParams(filters) }, pagination), sort),
        appConfig: config,
    }).then((value) => {
        const data = value.data.data.filter((i) => !!i.inspection_template);
        const totalResults = value.data.total_results;
        return { data, totalResults };
    });
});
export const getById = (id, config) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/inspections/${id}`,
        appConfig: config,
    }).then((value) => value.data.data);
});
export const getMaintenanceSkills = (inspectionId) => __awaiter(void 0, void 0, void 0, function* () {
    return request({
        method: 'get',
        url: `/inspections/${inspectionId}/skills`,
        params: {
            user_id: APIConfig.getUserId(),
        },
    }).then((value) => value.data.data);
});

import { Row } from 'components';
import { useCallback, useEffect } from 'react';
import { useTypedSelector } from 'store/reducers/rootReducer';

import { Divider, Typography } from '@mui/material';
import { FileRequestApi } from '@rentcheck/api-frontend';
import { Utils } from '@rentcheck/biz';
import { useDispatch } from 'react-redux';
import { InspectionsActions, MaintenanceActions } from 'store/actions';
import {
	FileRequest,
	State as FileRequestsState,
} from 'store/reducers/file-requests';
import { Dispatch } from 'types';
import { Analytics } from 'utils';
import FileRequestRow from './row';

export default () => {
	const dispatch: Dispatch = useDispatch();

	const fileRequests = useTypedSelector((state) => state.fileRequests);

	const handleDownload = (fileRequest: FileRequest) => {
		window.open(fileRequest.location);

		if (fileRequest.document_type === 'INSPECTION_REPORT') {
			Analytics.trackEvent('downloaded_inspection_report', {
				inspection_id: fileRequest.document_id,
			});
		}

		if (fileRequest.document_type === 'MAINTENANCE_REPORT') {
			Analytics.trackEvent('downloaded_maintenance_report', {
				report_id: fileRequest.document_id,
			});
		}
	};

	const updateFileRequestsStatus = useCallback(
		(fileRequests: FileRequestsState, dispatch: Dispatch) => {
			const fileRequestsInProgress = fileRequests.filter(
				Utils.FileRequests.isInProgress
			);

			Promise.all(
				fileRequestsInProgress.map((fr) =>
					FileRequestApi.getById(fr.id)
						.then((updatedFileRequest) => {
							/**
							 * Update the persisted file request with the new status,
							 * error message, and file location if available.
							 */
							const payload: Partial<FileRequest> = {
								id: fr.id,
								status: updatedFileRequest.status,
							};

							if (updatedFileRequest.status === 'ERROR') {
								payload.error = updatedFileRequest.error_message;
							}

							if (updatedFileRequest.file_location) {
								payload.location = updatedFileRequest.file_location;
							}

							dispatch({ type: 'UPDATE_FILE_REQUEST', value: payload });

							/**
							 * If the file request is done and we have a file location update the
							 * object that triggered this.
							 * Additionally if the file request is of type download go ahead and
							 * download the file for the user.
							 */
							if (
								updatedFileRequest.status === 'DONE' &&
								updatedFileRequest.file_location
							) {
								if (updatedFileRequest.document_type === 'INSPECTION_REPORT') {
									dispatch(InspectionsActions.refreshInspection());
								}

								if (updatedFileRequest.document_type === 'MAINTENANCE_REPORT') {
									dispatch(MaintenanceActions.reports.refreshReport());
								}

								if (fr.type === 'download') {
									handleDownload({
										...fr,
										location: updatedFileRequest.file_location,
									});
								}
							}
						})
						.catch(() => {})
				)
			);
		},
		[]
	);

	useEffect(() => {
		const interval = setInterval(() => {
			updateFileRequestsStatus(fileRequests, dispatch);
		}, 5000);

		return () => clearInterval(interval);
	}, [dispatch, updateFileRequestsStatus, fileRequests]);

	if (!fileRequests.length) return null;

	return (
		<>
			<Row style={{ padding: '8px 20px' }}>
				<Typography variant="overline">Reports</Typography>
			</Row>
			<Divider />

			{fileRequests.map((fr) => (
				<FileRequestRow
					key={fr.id}
					fileRequest={fr}
					onDownload={handleDownload}
				/>
			))}
		</>
	);
};

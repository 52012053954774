import { EventRegister } from 'utils';

type OptionsType = Record<
	string,
	string | number | Date | undefined | string[]
>;

export const trackEvent = (name: string, options?: OptionsType) => {
	EventRegister.emit('analytics.trackEvent', { name, options });
};

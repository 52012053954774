import { ImportSource } from '@rentcheck/types';
import assets from 'assets';

export type PMSOption = {
	name: string;
	value: ImportSource;
	image: string;
	existsAsIntegration?: boolean;
	existsAsIntegrationImage?: string;
};

export const pmsOptions: PMSOption[] = [
	{
		name: 'None of these',
		value: 'rentcheck',
		image: assets.imports.unitsPMSNone,
	},
	{
		name: 'AppFolio',
		value: 'appfolio',
		image: assets.imports.unitsPMSAppfolio,
		existsAsIntegration: true,
		existsAsIntegrationImage: assets.imports.integrationExistsAppfolio,
	},
	{
		name: 'Buildium',
		value: 'buildium',
		image: assets.imports.unitsPMSBuildium,
	},
	{
		name: 'Propertyware',
		value: 'propertyware',
		image: assets.imports.unitsPMSPropertyware,
	},
	{
		name: 'Rent Manager',
		value: 'rentmanager',
		image: assets.imports.unitsPMSRentManager,
		existsAsIntegration: true,
		existsAsIntegrationImage: assets.imports.integrationExistsRentmanager,
	},
	{
		name: 'Rentvine',
		value: 'rentvine',
		image: assets.imports.unitsPMSRentvine,
	},
];
